import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Filter } from '@app/models/filter';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() filters: Filter[] = [];
  @Input() update!: () => void;

  constructor() {}

  ngOnInit(): void {}

  set(event: any, filter: Filter) {
    filter.selectedIds = event;
    this.update();
  }
}
