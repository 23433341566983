import { CaseManagerSummary } from './case-manager';
import { InterventionNote } from './intervention-note';
import { InterventionReferOn } from './intervention-refer-on';
import { ReferralCardiac } from './intervention-referral-cardiac';
import { InterventionSignpost } from './intervention-signpost';
import { ServiceUser } from './service-user';
import { Call } from './call';
import { InterventionResource } from './intervention-resource';
import { ReferralRespiratory } from './intervention-referral-respiratory';
import { Goal } from './goal';
import { InterventionDischarge } from './intervention-discharge';
import { InterventionOnboarding } from './intervention-onboarding';
import { ReferralIcu } from './intervention-referral-icu';
import { ReferralLongCovid } from './intervention-referral-long-covid';

export enum InterventionType {
  ReferralCardiac = 'ReferralCardiac',
  ReferralRespiratory = 'ReferralRespiratory',
  ReferralLongCovid = 'ReferralLongCovid',
  ReferralIcu = 'ReferralIcu',
  Note = 'Note',
  ReferOn = 'ReferOn',
  Signposting = 'Signposting',
  Resource = 'Resource',
  Call = 'Call',
  Discharge = 'Discharge',
  Onboarding = 'Onboarding',
}
export class Intervention<
  T extends
    | ReferralCardiac
    | ReferralRespiratory
    | ReferralIcu
    | ReferralLongCovid
    | InterventionReferOn
    | InterventionNote
    | InterventionSignpost
    | InterventionResource
    | InterventionDischarge
    | InterventionOnboarding
    | Call
> {
  id?: number;
  serviceUserId?: number;
  serviceUser: ServiceUser;
  type: InterventionType;
  author: CaseManagerSummary;
  created?: string;
  updated?: string;
  data: T;
  goals: Goal[];
  isCollapsed: boolean;

  constructor(data?: any) {
    this.created = data && data.created ? data.created : new Date();
    this.updated = data && data.updated ? data.updated : new Date();
    this.author = data && data.author ? data.author : new CaseManagerSummary();
    this.isCollapsed = true;

    this.serviceUser =
      data && data.serviceUser ? data.serviceUser : new ServiceUser({});

    this.goals = data && data.goals ? data.goals : undefined;

    if (data && data.data && data.type) {
      this.data = data.data;
      this.type = data.type;
    } else if (data) {
      switch (data.type) {
        case InterventionType.ReferralCardiac:
          this.type = InterventionType.ReferralCardiac;
          this.data = new ReferralCardiac() as T;
          break;
        case InterventionType.ReferralRespiratory:
          this.type = InterventionType.ReferralRespiratory;
          this.data = new ReferralRespiratory() as T;
          break;
        case InterventionType.ReferralLongCovid:
          this.type = InterventionType.ReferralLongCovid;
          this.data = new ReferralLongCovid() as T;
          break;
        case InterventionType.ReferralIcu:
          this.type = InterventionType.ReferralLongCovid;
          this.data = new ReferralLongCovid() as T;
          break;
        case InterventionType.Onboarding:
          this.type = InterventionType.Onboarding;
          this.data = new InterventionOnboarding() as T;
          break;
        case InterventionType.ReferOn:
          this.type = InterventionType.ReferOn;
          this.data = new InterventionReferOn() as T;
          break;
        case InterventionType.Signposting:
          this.type = InterventionType.Signposting;
          this.data = new InterventionSignpost() as T;
          break;
        case InterventionType.Resource:
          this.type = InterventionType.Resource;
          this.data = new InterventionSignpost() as T;
          break;
        case InterventionType.Call:
          this.type = InterventionType.Call;
          this.data = new Call() as T;
          break;
        default:
          this.type = InterventionType.Note;
          this.data = new InterventionNote() as T;
          break;
      }
    } else {
      this.type = InterventionType.Note;
      this.data = new InterventionNote() as T;
    }
  }
}
