import {
  QuestionDropdown,
  QuestionTextbox,
  Question,
  QuestionDate,
  QuestionDateOfBirth,
  QuestionRadio,
  QuestionCheckbox,
} from '@app/models/form';

import {
  titleOptions,
  genderOptions,
  maritalStatusOptions,
  livesWithOptions,
} from '@app/helpers/form-input-data';
import { patterns } from '@app/helpers/validators';
import { Validators } from '@angular/forms';

export const formServiceUser: Question<any>[] = [
  new QuestionDate({
    key: 'referralDate',
    label: 'Referral Date',
    path: 'data.referralDate',
  }),
  new QuestionCheckbox({
    key: 'referralDateNa',
    label: 'Referral Date not available',
    path: 'data.ReferralDateNa',
  }),
  new QuestionDropdown({
    key: 'title',
    path: 'serviceUser.personalDetails.title',
    label: 'Title',
    required: true,
    options: titleOptions,
  }),
  new QuestionTextbox({
    key: 'firstName',
    path: 'serviceUser.personalDetails.firstName',
    label: 'First name',
    validators: [Validators.maxLength(40)],
    required: true,
  }),
  new QuestionTextbox({
    key: 'lastName',
    path: 'serviceUser.personalDetails.lastName',
    validators: [Validators.maxLength(40)],
    label: 'Last name',
    required: true,
  }),
  new QuestionTextbox({
    key: 'address',
    path: 'serviceUser.personalDetails.address.address1',
    validators: [Validators.maxLength(40)],
    label: 'Address',
    required: true,
  }),
  new QuestionTextbox({
    key: 'street',
    path: 'serviceUser.personalDetails.address.address2',
    validators: [Validators.maxLength(40)],
    label: 'Street',
  }),
  new QuestionTextbox({
    key: 'city',
    path: 'serviceUser.personalDetails.address.city',
    validators: [Validators.maxLength(40)],
    label: 'Town/City',
    required: true,
  }),
  new QuestionTextbox({
    key: 'postcode',
    path: 'serviceUser.personalDetails.address.postcode',
    validators: [Validators.maxLength(10)],
    label: 'Postcode',
    required: true,
  }),
  new QuestionTextbox({
    key: 'phone',
    path: 'serviceUser.personalDetails.phone',
    label: 'Telephone contact 1',
    required: true,
    validators: [
      Validators.pattern(patterns.phone),
      Validators.minLength(7),
      Validators.maxLength(15),
    ],
  }),
  new QuestionTextbox({
    key: 'phone2',
    path: 'serviceUser.personalDetails.mobile',
    label: 'Telephone contact 2',
    validators: [
      Validators.pattern(patterns.phone),
      Validators.minLength(7),
      Validators.maxLength(15),
    ],
  }),
  new QuestionDateOfBirth({
    key: 'dob',
    path: 'serviceUser.personalDetails.dob',
    label: 'DateOfBirth',
    required: true,
  }),
  new QuestionDropdown({
    key: 'gender',
    path: 'serviceUser.personalDetails.gender',
    label: 'Gender',
    required: true,
    options: genderOptions,
  }),
  new QuestionDropdown({
    key: 'maritalStatus',
    path: 'serviceUser.personalDetails.maritalStatus',
    label: 'Marital Status',
    options: maritalStatusOptions,
  }),
  new QuestionDropdown({
    key: 'livesWith',
    path: 'serviceUser.personalDetails.livesWith',
    label: 'Lives with',
    options: livesWithOptions,
  }),
  new QuestionTextbox({
    key: 'email',
    path: 'serviceUser.personalDetails.email',
    label: 'Email',
    required: false,
    validators: [Validators.pattern(patterns.email), Validators.maxLength(100)],
  }),
];
