import { Address } from './address';

export class PersonMultiAddress {
  title: string;
  titleOther?: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  maritalStatus?: string;
  livesWith: string;
  address: Address[];
  phone?: number;
  mobile?: number;
  email?: string;
  employmentStatus?: string;
  sexuality?: string;
  ethnicity?: string;
  ethnicityOther?: string;
  gdprConsentGiven?: boolean;

  constructor(data?: PersonMultiAddress) {
    this.title = data && data.title ? data.title : '';
    this.firstName = data && data.firstName ? data.firstName : '';
    this.lastName = data && data.lastName ? data.lastName : '';
    this.dob = data && data.dob ? data.dob : '';
    this.gender = data && data.gender ? data.gender : '';
    this.livesWith = data && data.livesWith ? data.livesWith : '';
    this.address = data && data.address ? data.address : [new Address()];
    this.phone = data && data.phone ? data.phone : undefined;
    this.mobile = data && data.mobile ? data.mobile : undefined;
    this.email = data && data.email ? data.email : '';
    this.employmentStatus = data && data.employmentStatus ? data.employmentStatus : '';
    this.sexuality = data && data.sexuality ? data.sexuality : '';
    this.ethnicity = data && data.ethnicity ? data.ethnicity : '';
    this.ethnicityOther = data && data.ethnicityOther ? data.ethnicityOther : '';
    this.gdprConsentGiven =
      data && data.gdprConsentGiven ? data.gdprConsentGiven : false;
  }
}

export class Person {
  title: string;
  titleOther?: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  maritalStatus?: string;
  livesWith: string;
  address: Address;
  phone?: number;
  mobile?: number;
  email?: string;
  emailSecondary?: string;
  gdprConsentGiven?: boolean;

  constructor(data?: Person) {
    this.title = data && data.title ? data.title : '';
    this.firstName = data && data.firstName ? data.firstName : '';
    this.lastName = data && data.lastName ? data.lastName : '';
    this.dob = data && data.dob ? data.dob : '';
    this.gender = data && data.gender ? data.gender : '';
    this.livesWith = data && data.livesWith ? data.livesWith : '';
    this.address = data && data.address ? data.address : new Address();
    this.phone = data && data.phone ? data.phone : undefined;
    this.mobile = data && data.mobile ? data.mobile : undefined;
    this.email = data && data.email ? data.email : '';
    this.emailSecondary = data && data.emailSecondary ? data.emailSecondary : '';
    this.gdprConsentGiven =
      data && data.gdprConsentGiven ? data.gdprConsentGiven : false;
  }
}
