import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiSelectComponent,
    },
  ],
})
export class MultiSelectComponent implements ControlValueAccessor {
  @Input() options!: any[];
  @Input() cols = 3;

  selected: any[] = [];
  onChange = (selected: any[]) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor() {}

  select(option: any) {
    this.markAsTouched();
    if (this.selected.includes(option)) {
      this.selected = this.selected.filter((o) => o !== option);
    } else {
      this.selected.push(option);
    }
    this.onChange(this.selected);
  }

  writeValue(selected: []) {
    this.selected = selected;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
