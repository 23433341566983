<div class="container container-sm mt-5">
  <h1>CHSS Referrals</h1>
  <p>
    This is the referral page for the Adviceline's Clinical Case Management
    service.
  </p>
  <p>
    Before you refer you will need to ensure that the appropriate information
    governance arrangements are in place. Please select the relevant Health
    Board to check what service is currently available for your region and
    specialty:
  </p>
  <ng-select
    [items]="boardOptions"
    [(ngModel)]="boardSelected"
    placeholder="Choose your board"
    class="mb-4"
  >
  </ng-select>
  <ng-container *ngIf="boardSelected">
    <p>To refer your patient please select the relevant form below:</p>
    <div class="mb-4">
      <button
        class="btn btn-primary me-2"
        routerLink="/referrals/cardiac"
        *ngIf="boardHasReferral('cardiac')"
      >
        Cardiac
      </button>
      <button
        class="btn btn-primary me-2"
        routerLink="/referrals/covid"
        *ngIf="boardHasReferral('covid')"
      >
        Long Covid
      </button>
      <button
        class="btn btn-primary me-2"
        routerLink="/referrals/respiratory"
        *ngIf="boardHasReferral('respiratory')"
      >
        Respiratory
      </button>
      <button
        class="btn btn-primary me-2"
        routerLink="/referrals/icu"
        *ngIf="boardHasReferral('icu')"
      >
        ICU
      </button>
    </div>
  </ng-container>
  <p>
    For all other regions where there are currently no information governance
    agreements in place and you are interested in developing a route of referral
    to our service, please contact your line manager who can arrange a meeting
    with CHSS to discuss. Contact can be made with Joanne Graham, CHSS Head of
    Service Delivery: joanne.graham@chss.org.uk or Michelle Bremner, CHSS Head
    of Development: michelle.bremner@chss.org.uk
  </p>
</div>
