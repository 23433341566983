<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{ title }}</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    (click)="bsModalRef.hide()"
    aria-label="Close"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p *ngIf="referOnData.copy" [innerHtml]="referOnData.copy"></p>

  <form [formGroup]="form" class="form">
    <div class="position-relative">
      <div class="input-group mb-3">
        <input
          #date="bsDatepicker"
          type="text"
          formControlName="date"
          placeholder="Please select"
          class="form-control"
          [bsConfig]="{
            containerClass: 'theme-default',
            dateInputFormat: 'DD/MM/YYYY'
          }"
          bsDatepicker
        />
        <button class="btn btn-sm btn-secondary" (click)="date.toggle()">
          <fa-icon
            [icon]="['fal', 'calendar-alt']"
            class="text-white mx-1"
          ></fa-icon>
        </button>
      </div>
      <fa-icon
        *ngIf="f.date?.touched && !f.date?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      >
      </fa-icon>
    </div>
    <div class="input-group mb-3">
      <textarea
        id="note"
        formControlName="note"
        placeholder="Optional note"
        class="form-control"
        rows="5"
      ></textarea>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div *ngIf="loading" class="spinner-border text-primary me-2" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <button class="btn btn-primary" (click)="submit()" [disabled]="!form.valid">
    Add to user timeline
  </button>
</div>
