export interface Filter {
  name: string;
  label: string;
  type: 'single' | 'multi';
  placeholder: string;
  options: FilterOption[];
  selectedIds: any[];
}

export interface FilterOption {
  id: number | string;
  name: string;
}

export const seedFilters = [
  {
    label: 'Case Manager',
    name: 'caseManager',
    type: 'multi',
    placeholder: 'select',
    options: [
      {
        id: 1,
        name: 'ben beaumont',
      },
      {
        id: 2,
        name: 'craig jefferson',
      },
    ],
    selectedIds: [],
  } as Filter,
  {
    label: 'Status',
    name: 'status',
    type: 'multi',
    placeholder: 'select',
    options: [
      {
        id: 1,
        name: 'triage',
      },
      {
        id: 2,
        name: 'active',
      },
    ],
    selectedIds: [],
  } as Filter,
  {
    label: 'Region',
    name: 'region',
    type: 'single',
    placeholder: 'select',
    options: [],
    selectedIds: [],
  } as Filter,
];
