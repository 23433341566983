import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { PaginatedResponse } from '@app/models/paginatedResponse';
import { ServiceUserSummary } from '@models/service-user';
import { ServiceUserService } from '@services/service-user.service';
import { UserService } from '@services/user/user.service';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Filter, FilterOption, seedFilters } from '@app/models/filter';
import { CaseManagerService } from '@app/services/case-manager.service';
import { forkJoin, Subject } from 'rxjs';
import { AcfOptionsService } from '@app/services/acf-options.service';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { referrerRegionOptions } from '@app/helpers/form-input-data';

@Component({
  selector: 'app-service-users',
  templateUrl: './service-users.component.html',
  styleUrls: ['./service-users.component.scss'],
})
export class ServiceUsersComponent implements OnInit, AfterViewInit {
  sortedData = new MatTableDataSource([] as ServiceUserSummary[]);
  casesPaged!: PaginatedResponse<ServiceUserSummary>;
  currentPage: number = 1;
  pageSize: number = 20;

  serviceUsers: any;
  filters: Filter[] = seedFilters;
  searchTerm = new Subject<string>();
  searchTermValue = '';

  loading = {
    page: true,
    filter: false,
  };

  displayedColumns: string[] = [
    'firstName',
    'region',
    'admissionDate',
    'interventionCount',
    'lastInterventionDate',
    'nextCall',
    'caseManager',
    'status',
  ];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    public userService: UserService,
    public router: Router,
    private serviceUserService: ServiceUserService,
    private caseManagerService: CaseManagerService,
    private optionsService: AcfOptionsService,
    public authService: AuthenticationService
  ) {
    this.searchTerm
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value: string) => {
        this.loading.filter = true;
        this.searchTermValue = value;
        this.getServiceUsers();
      });
  }

  ngOnInit(): void {
    const pageNumber = 1;
    this.loading.page = true;

    forkJoin({
      caseManagers: this.caseManagerService.getAll(),
      options: this.optionsService.get(),
    }).subscribe(({ caseManagers, options }) => {
      this.setFilterValues('caseManager', caseManagers as FilterOption[]);

      this.setFilterValues(
        'status',
        Object.keys(options.serviceUser.status.options).map((option) => {
          return {
            id: option,
            name: option,
          };
        }) as FilterOption[]
      );

      this.setFilterValues(
        'region',
        referrerRegionOptions.map((option) => {
          return {
            id: option,
            name: option,
          };
        }) as FilterOption[]
      );

      this.filtersRestore();
      this.getServiceUsers();
    });
  }

  setFilterValues(filterName: string, options: FilterOption[]) {
    const filter = this.filters.find((filter) => filter.name === filterName);

    if (filter) {
      filter.options = options;
    }
  }

  setFilterValue(filterName: string, value: string | number) {
    const filter = this.filters.find((filter) => filter.name === filterName);

    if (filter) {
      filter.selectedIds = [value];
    }

    this.filtersStore();
  }

  ngAfterViewInit() {
    this.sortedData.sort = this.sort;
    this.sortedData.paginator = this.paginator;
  }

  getServiceUsers = (
    pageSize: number = this.pageSize,
    currentPage: number = this.currentPage
  ): void => {
    this.loading.page = true;
    this.serviceUserService
      .getAll(pageSize, currentPage, this.filters, this.searchTermValue)
      .subscribe((response) => {
        this.loading.page = false;
        this.loading.filter = false;
        this.casesPaged = response;
        this.sortedData = new MatTableDataSource(
          this.casesPaged.result as ServiceUserSummary[]
        );
      });
  };

  filtersReset() {
    this.filters.map((f) => {
      f.selectedIds = [];
    });
    this.getServiceUsers();
    this.filtersStore();
  }

  filtersMyCaseLoad() {
    this.setFilterValue('status', 'active');
    this.setFilterValue('caseManager', this.authService.currentUserValue.id);
    this.getServiceUsers();
  }

  search(s: string) {}

  loadServiceUser(id: number) {
    this.router.navigate(['/service-users', id]);
  }

  filtersStore() {
    localStorage.setItem('filters', JSON.stringify(this.filters));
  }

  filtersRestore() {
    const selectedFilters = JSON.parse(localStorage.getItem('filters') || '[]');
    this.filters.map((filter) => {
      const foundFilter = selectedFilters.find(
        (f: { name: string }) => f.name === filter.name
      );

      if (foundFilter) {
        filter.selectedIds = foundFilter.selectedIds;
      }
    });
  }
}
