<!-- <app-header></app-header> -->
<div *ngIf="loading.page" class="spinner-border text-primary m-5" role="status">
  <span class="visually-hidden">Loading...</span>
</div>

<ng-container *ngIf="!loading.page">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 overflow-auto">
        <div
          [style.height.px]="innerHeight - 68"
          class="px-4"
          style="padding-top: 30px; padding-bottom: 90px"
        >
          <h3 class="p-2 pb-4">
            New Call ({{ calls.length }}) -
            {{ serviceUser.personalDetails.firstName }}
            {{ serviceUser.personalDetails.lastName }}
          </h3>

          <form [formGroup]="callForm" class="form mb-5" *ngIf="call">
            <tabset #staticTabs>
              <tab heading="Call">
                <div class="card w-100 mb-4" style="border-top: none">
                  <div class="card-body p-4">
                    <div class="row mb-3">
                      <div class="col-6">
                        <label for="startTime" class="form-label w-100">
                          Start time
                          <span
                            class="text-primary"
                            *ngIf="isRequiredField('startTime')"
                            >*</span
                          >
                          <span
                            class="float-end text-danger"
                            *ngIf="
                              !f.startTime?.valid &&
                              (f.startTime?.dirty || f.startTime?.touched)
                            "
                          >
                            <span [hidden]="!f.startTime.errors?.required">
                              Required
                            </span>
                          </span>
                        </label>
                        <app-time-select
                          [modalTitle]="'Start time'"
                          [buttonTitle]="'Start call'"
                          formControlName="startTime"
                          [editDate]="true"
                        ></app-time-select>
                      </div>
                      <div class="col-6">
                        <label for="endTime" class="form-label w-100">
                          End time
                          <span
                            class="text-primary"
                            *ngIf="isRequiredField('endTime')"
                            >*</span
                          >
                          <span
                            class="float-end text-danger"
                            *ngIf="
                              !f.endTime?.valid &&
                              (f.endTime?.dirty || f.endTime?.touched)
                            "
                          >
                            <span [hidden]="!f.endTime.errors?.required">
                              Required
                            </span>
                          </span>
                        </label>
                        <app-time-select
                          [modalTitle]="'End time'"
                          [buttonTitle]="'End call'"
                          formControlName="endTime"
                          [editDate]="true"
                        >
                        </app-time-select>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label for="generalCallNotes" class="form-label w-100">
                        General call notes
                        <span
                          class="text-primary"
                          *ngIf="isRequiredField('generalCallNotes')"
                          >*</span
                        >
                        <span
                          class="float-end text-danger"
                          *ngIf="
                            !f.generalCallNotes?.valid &&
                            (f.generalCallNotes?.dirty ||
                              f.generalCallNotes?.touched)
                          "
                        >
                          <span [hidden]="!f.generalCallNotes.errors?.required">
                            General call notes are required
                          </span>
                        </span>
                      </label>
                      <div class="position-relative">
                        <textarea
                          formControlName="generalCallNotes"
                          id="generalCallNotes"
                          placeholder=""
                          class="form-control"
                          rows="7"
                          [class.is-invalid]="
                            f.generalCallNotes?.touched &&
                            f.generalCallNotes?.errors
                          "
                        ></textarea>
                      </div>
                    </div>

                    <app-topics
                      [topics]="call.data.topics"
                      (topicsChange)="handleTopicsChanged()"
                    ></app-topics>
                  </div>
                </div>
              </tab>
              <tab heading="End call">
                <div class="card w-100 mb-4" style="border-top: none">
                  <div class="card-body p-3">
                    <div class="mb-3">
                      <label for="postCallNotes" class="form-label w-100">
                        Plan
                        <span
                          class="text-primary"
                          *ngIf="isRequiredField('postCallNotes')"
                          >*</span
                        >
                        <span
                          class="float-end text-danger"
                          *ngIf="
                            !f.postCallNotes?.valid &&
                            (f.postCallNotes?.dirty || f.postCallNotes?.touched)
                          "
                        >
                          <span [hidden]="!f.postCallNotes.errors?.required">
                            Plan notes are required
                          </span>
                        </span>
                      </label>
                      <div class="position-relative">
                        <textarea
                          formControlName="postCallNotes"
                          id="postCallNotes"
                          placeholder=""
                          class="form-control"
                          rows="7"
                          [class.is-invalid]="
                            f.postCallNotes?.touched && f.postCallNotes?.errors
                          "
                        ></textarea>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-12 col-md-6">
                        <label for="callerMood" class="form-label w-100">
                          Caller mood
                          <span
                            class="text-primary"
                            *ngIf="isRequiredField('callerMood')"
                            >*</span
                          >
                          <span
                            class="float-end text-danger"
                            *ngIf="
                              !f.callerMood?.valid &&
                              (f.callerMood?.dirty || f.callerMood?.touched)
                            "
                          >
                            <span [hidden]="!f.callerMood.errors?.required">
                              Caller mood is required
                            </span>
                          </span>
                        </label>
                        <app-mood-select
                          formControlName="callerMood"
                        ></app-mood-select>
                      </div>
                      <div class="col-12 col-md-6">
                        <label for="callerMood" class="form-label w-100">
                          Next call
                        </label>
                        <div class="input-group mb-3" style="max-width: 240px">
                          <input
                            #datePicker="bsDatepicker"
                            type="text"
                            id=""
                            formControlName="nextCall"
                            placeholder="Please select"
                            class="form-control"
                            [bsConfig]="{
                              containerClass: 'theme-default',
                              dateInputFormat: 'DD/MM/YYYY'
                            }"
                            bsDatepicker
                          />
                          <button
                            class="btn btn-sm btn-secondary"
                            (click)="datePicker.toggle()"
                          >
                            <fa-icon
                              [icon]="['fal', 'calendar-alt']"
                              class="text-white mx-1"
                            ></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
            </tabset>
          </form>

          <app-goals
            *ngIf="serviceUser.id"
            [userId]="serviceUser.id"
            [(goals)]="call.data.goals"
            (goalsChange)="handleGoalsChanged()"
          >
          </app-goals>
          <div class="pb-4"></div>
        </div>
      </div>

      <div class="col-6 bg-light overflow-auto">
        <div
          [style.height.px]="innerHeight - 68"
          class="px-4"
          style="padding-top: 30px; _padding-bottom: 68px"
        >
          <div
            *ngIf="!serviceUser"
            class="spinner-border text-primary"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>

          <ng-container *ngIf="serviceUser">
            <h3 class="pb-4 d-inline-block">Call History</h3>

            <ng-container>
              <app-summary-call
                [intervention]="call"
                title="Current Call"
              ></app-summary-call>
              <hr class="my-5" />
            </ng-container>

            <div
              *ngFor="
                let c of calls;
                let first = first;
                let last = last;
                let i = index
              "
              class="mb-4"
            >
              <ng-container *ngIf="!last">
                <app-summary-call
                  [intervention]="c"
                  [title]="'Call ' + (calls.length - i - 1)"
                ></app-summary-call>
              </ng-container>
            </div>
          </ng-container>
          <div class="pb-4"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="w-100 bg-light"
    style="
      position: fixed;
      bottom: 0;
      border-top: 1px solid #dee2e6;
      z-index: 2;
    "
  >
    <div class="container-fluid">
      <div class="row py-3">
        <div class="col-6">
          <button class="btn btn-secondary ms-4" (click)="cancel()">
            Back
          </button>

          <button
            class="btn btn-primary float-end me-4"
            (click)="submit()"
            [disabled]="!callForm.valid"
          >
            Save Call
          </button>
          <button
            class="btn btn-secondary float-end me-4"
            (click)="didNotPickup()"
          >
            Did not pickup
          </button>

          <div
            *ngIf="loading.saving"
            class="spinner-border text-primary float-end me-3"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div class="col-6"></div>
      </div>
    </div>
  </div>
</ng-container>
