export const referrerRegionsWithOptions = [
  {
    name: 'NHS Lothian',
    label:
      'NHS Lothian - Long Covid, Respiratory & ICU referrals',
    referrals: ['covid', 'respiratory', 'icu'],
  },
  {
    name: 'NHS Ayrshire & Arran',
    label:
      'NHS Ayrshire & Arran (Ward 3B University Hospital Crosshouse) - Respiratory referrals',
    referrals: ['respiratory'],
  },
  {
    name: 'NHS GG&C',
    label:
      'NHS GG&C - Long Covid & Respiratory referrals',
    referrals: ['covid', 'respiratory'],
  },
  {
    name: 'NHS Fife',
    label: 'NHS Fife - Long Covid & Respiratory referrals',
    referrals: ['covid', 'respiratory'],
  },
  {
    name: 'NHS Highland',
    label: 'NHS Highland - Cardiac, Long Covid & Respiratory referrals',
    referrals: ['covid', 'cardiac', 'respiratory'],
  },
];

export const currentSupportOptions = [
  'NHS Community Resp Team',
  'NHS Pulmonary Rehab',
  'Critical Care Recovery',
  'NHS Cardiac Rehab',
  'GP/PN',
  'AHP',
  'Community Mental Health',
  'Other',
  'Not applicable',
];

export const optionsYesNo = ['Yes', 'No'];
export const optionsYesNoNotAppropriate = ['Yes', 'No', 'Not Appropriate'];

export const contact5WorkingDaysNo = [
  'Team capacity',
  'System error',
  'Referral incomplete',
  'Public holidays',
  'Other',
];

export const titleOptions = [
  'Mr',
  'Mrs',
  'Miss',
  'Ms',
  'Dr',
  'Rev',
  'Prof',
  'Sir',
  'Other',
];
export const genderOptions = [
  'Male',
  'Female',
  'Transgender',
  'Gender neutral',
  'Prefer not to say',
];
export const maritalStatusOptions = [
  'Married',
  'Civil Partnership',
  'Partner',
  'Widowed',
  'Single',
  'Prefer not to say',
];
export const livesWithOptions = [
  'Husband',
  'Wife',
  'Partner',
  'Alone',
  'Friend',
  'Carer',
  'Other',
];

export const employmentStatusOptions = [
  'Asylum seeker/Refugee',
  'Carer',
  'Employed',
  'Homemaker',
  'Retired',
  'Retired due to ill health',
  'Self employed',
  'Student',
  'Unemployed',
  'Unemployed and looking for work',
  'Volunteer',
  'Prefer not to say',
];
export const sexualityOptions = [
  'Heterosexual',
  'Gay woman / lesbian',
  'Gay man',
  'Bisexual',
  'Other',
  'Prefer not to say',
];
export const ethnicityOptions = [
  'Scottish',
  'Other British',
  'Irish',
  'Gypsy/Traveller',
  'Polish',
  'Pakistani, Pakistani Scottish or Pakistani British',
  'Indian, Indian Scottish or Indian British',
  'Bangladeshi, Bangladeshi Scottish or Bangladeshi British',
  'Chinese, Chinese Scottish or Chinese British',
  'African, African Scottish or African British',
  'Caribbean, Caribbean Scottish or Caribbean British',
  'Black, Black Scottish or Black British',
  'Arab, Arab Scottish or Arab British',
  'Other...',
  'Prefer not to say',
];

export const primaryConditionOptions = [
  'MI',
  'Revascularisation',
  'OHCA',
  'Heart Failure',
  'Atrial Fibrillation',
  'Angina',
  'Other',
];

export const otherRelevantHealthConditionsOptions = [
  'Respiratory - COPD',
  'Asthma',
  'Bronchiectasis',
  'IPF',
  'Stroke',
  'TIA',
  'Diabetes',
  'Arthritis',
  'Other',
];
export const riskFactorsOptions = [
  'Family History',
  'Diabetes',
  'Weight',
  'Exercise',
  'Smoking',
  'Diet',
  'Hyperlipidaemia',
  'Hypertension',
  'Alcohol',
  'Depression',
];
export const impairmentsOptions = [
  'Sight',
  'Hearing',
  'Speech',
  'Mobility',
  'Cognitive',
  'Other',
  'N/A',
];
export const referrerRegionOptions = [
  'NHS Lothian',
  'NHS GG&C',
  'NHS Borders',
  'NHS D&G',
  'NHS Tayside',
  'NHS Forth Valley',
  'NHS A&A',
  'NHS Western Isles',
  'NHS Grampian',
  'NHS Highland',
  'NHS Orkney',
  'NHS Shetland',
  'NHS Lanarkshire',
  'NHS Fife',
];

export const referrerHospitalLothianOptions = [
  'Edinburgh Royal Infirmary',
  'Western General Hospital',
  'St Johns Hospital',
];

export const referrerRelationshipOptions = [
  'Specialist Nurse',
  'Physiotherapist',
  'Other AHP',
  'GP/Doctor',
  'Community Nurse',
  'Other',
];
export const facilitationOptions = [
  'MI Heart Manual (digital)',
  'Revasc Heart Manual (digital)',
  'MI Heart Manual (hard copy)',
  'Revasc Heart Manual (hard copy)',
  'CHSS Publications',
  'Other',
  'N/A',
];

//######## LONG COVID ########
export const primaryConditionCovidOptions = ['Long Covid', 'Other'];

export const longCovidSymptomOptions = [
  'Breathlessness',
  'Fatigue',
  'Depression',
  'Anxiety',
  'Cognitive/Brain fog',
  'Support ADLs',
  'Other',
];

export const otherRelevantHealthConditionsCovidOptions = [
  'Cardiac - MI',
  'OHCA',
  'Heart Failure',
  'Atrial Fibrillation',
  'Angina',
  'respiratory - COPD',
  'bronchiectasis',
  'IPF',
  'asthma',
  'Stroke',
  'TIA',
  'Diabetes',
  'Arthritis',
  'Other',
];

//######## Respiratory ########
export const primaryConditionRespiratoryOptions = [
  'COPD',
  'Bronchiectasis',
  'IPF',
  'Other',
];

export const respiratorySymptomOptions = [
  'Cardiac - MI',
  'OHCA',
  'Heart Failure',
  'Atrial Fibrillation',
  'Angina',
  'Stroke',
  'TIA',
  'Diabetes',
  'Arthritis',
  'Other',
];

export const bloodTestOptions = [
  'FBC',
  'U&Es',
  'LFTs',
  'CRP',
  'HbA1C',
  'Haematinics',
  'Calcium',
  'TFTs',
];

export const investigationOptions = ['BNP', 'CXR', 'ECG', 'Echo'];

export const currentSupportRespiratoryOptions = [
  'NHS CRT',
  'NHS PR',
  'NHS CR',
  'GP/PN AHP',
  'Mental Health Other',
  'Other',
];

//######### First contact ############
export const declinedReasons = [
  'Enough support',
  'Not needed at this time',
  'No answer over 3 attempts',
  'Prefer other support',
];

export const preferredCallFrequencyOptions = [
  'weekly',
  'fortnightly',
  'monthly',
  'other',
];

export const preferredCallDayOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
];

export const preferredCallTimeOptions = ['AM', 'PM', 'Other'];

//######### ICU ############
export const primaryConditionICUOptions = [
  'Covid-19',
  'Bronchiectasis',
  'COPD',
  'IPF',
  'Other',
];

export const otherRelevantConditionsICUOptions = [
  'Cardiac - MI',
  'OHCA',
  'Heart Failure',
  'Atrial Fibrillation',
  'Angina',
  'Respiratory- COPD',
  'Asthma',
  'Bronchiectasis',
  'IPF',
  'Stroke',
  'TIA',
  'Diabetes',
  'Arthritis',
  'Other',
];

export const facilitationIcuOptions = [
  'Tailored Talks',
  'CHSS Resources',
  'Other',
];
