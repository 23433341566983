import { Person } from './person';

export class Referrer {
  personalDetails: Person;
  relationship: string;
  relationshipOther?: string;
  healthBoard: string;

  constructor(data?: any) {
    this.personalDetails =
      data && data.personalDetails ? data.personalDetails : new Person();
    this.relationship = data && data.relationship ? data.relationship : '';
    this.relationshipOther =
      data && data.relationshipOther ? data.relationshipOther : '';
    this.healthBoard = data && data.healthBoard ? data.healthBoard : '';
  }
}
