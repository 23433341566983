import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';

import { User, UserRoles } from '@models/user';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(credentials: { username: string; password: string; tfa: string }): Observable<any> {
    return this.http
      .post<any>(environment.apiUrlAuth + '/token', { username: credentials.username, password: credentials.password + credentials.tfa })
      .pipe(
        map((payload) => {
          // login successful if there's a jwt token in the response
          if (payload && payload.data) {
            localStorage.setItem('currentUser', JSON.stringify(payload.data));

            this.currentUserSubject.next(payload.data);
          }

          return payload.data;
        })
      );
  }

  public setUserRoles(roles: UserRoles[]) {
    this.currentUserSubject.value.roles = roles as UserRoles[];
    localStorage.setItem(
      'currentUser',
      JSON.stringify(this.currentUserSubject.value)
    );
    this.currentUserSubject.next(this.currentUserSubject.value);
  }

  public getGetRoles(userId: number): Observable<User> {
    return this.http.get<User>(
      environment.wpApiUrl + '/users/' + userId + '?context=edit'
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(new User());
    this.router.navigate(['/logout']);
  }

  isLoggedIn() {
    return !_.isEmpty(this.currentUserSubject.value);
  }

  userHasAnyRole(roles: UserRoles[]) {
    for (let i = 0; i < roles.length; i++) {
      if (this.userHasRole(roles[i])) {
        return true;
      }
    }

    return false;
  }

  userHasRole(role: UserRoles) {
    if (!this.currentUserSubject.value.roles) return false;

    return this.currentUserSubject.value.roles.indexOf(role) >= 0;
  }

  userHasPermission(
    permission: 'edit' | 'view' | 'assign_case_manager'
  ): boolean {
    switch (permission) {
      case 'assign_case_manager':
        if (this.userHasRole(UserRoles.case_manager_manager)) return true;
        break;
      case 'edit':
        if (
          this.userHasRole(UserRoles.case_manager_manager) ||
          this.userHasRole(UserRoles.case_manager)
        )
          return true;
        break;
      case 'view':
        if (
          this.userHasRole(UserRoles.case_manager_manager) ||
          this.userHasRole(UserRoles.case_manager) ||
          this.userHasRole(UserRoles.case_viewer)
        )
          return true;
        break;
    }

    return false;
  }
}
