<div class="btn-group" [ngClass]="classes">
  <label
    *ngFor="let option of options"
    class="btn"
    [(ngModel)]="selected"
    (click)="update()"
    [ngClass]="option == selected ? 'btn-primary' : 'btn-outline-primary'"
    [btnRadio]="option"
    tabindex="0"
    role="button"
    >{{ option }}</label
  >
</div>
