import { Component, EventEmitter, OnInit } from '@angular/core';
import { Intervention, InterventionType } from '@app/models/intervention';
import { InterventionResource } from '@app/models/intervention-resource';
import { ServiceUser } from '@app/models/service-user';
import { Resource } from '@app/models/resource';
import { ResourceService } from '@app/services/resource.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  public onClose: EventEmitter<any> = new EventEmitter();
  intervention!: Intervention<InterventionResource>;
  user!: ServiceUser;
  bsConfig = Object.assign({}, { containerClass: 'theme-default' });

  title: string = '';

  selected: any = [];
  resources: Resource[] = [];
  loading = { page: true, create: false };
  error = {
    page: '',
    create: '',
  };

  constructor(
    private resourceService: ResourceService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.resourceService.getAll().subscribe(
      (resources: any[]) => {
        this.resources = resources;
        this.loading.page = false;
      },
      (error: any) => {
        this.error.page =
          'Unable to retrieve signposting list. Please try again and if the problem persists, contact IT.';
        this.loading.page = false;
      }
    );
  }

  update(selected: any) {
    this.selected = selected;
  }

  submit() {
    this.loading.create = true;
    this.intervention.data.ids = this.selected.map(
      (signpost: Resource) => signpost.id
    );
    this.intervention.serviceUser.id = this.user.id;

    this.resourceService.create(this.intervention).subscribe(
      (result: any) => {
        this.loading.create = false;
        this.onClose.emit(true);
        this.bsModalRef.hide();
      },
      (error: any) => {
        this.error.create =
          'Unable to create the resources intervention for this service user. Please try again.';
        this.loading.create = false;
      }
    );
  }
}
