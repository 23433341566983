<div class="bg d-flex align-items-center justify-content-center h-100 w-100">
  <div class="text-center p-5" style="width:100%; max-width:540px;">

    <img src="//www.chss.org.uk/wp-content/supportus-uploads/CHSS-logo-2018.png">

    <div *ngIf="error.message" class="alert alert-danger" role="alert" [innerHtml]="error.message"></div>

    <div class="panel bg-white text-left rounded p-5">

      <form #login="ngForm" (ngSubmit)="submit($event)" class="text-start">
        <div class="mb-3">
          <input type="text" class="form-control" id="username" name="username" placeholder="Username"
            [(ngModel)]="credentials.username" required>
        </div>
        <div class="mb-3">
          <input type="password" class="form-control" id="password" name="password" placeholder="Password"
            [(ngModel)]="credentials.password" required>
        </div>
        <div class="mb-3">
          <input type="text" class="form-control" id="tfa" name="tfa" placeholder="2FA Code"
            [(ngModel)]="credentials.tfa" required>
        </div>

        <button type="submit" [disabled]="loading.active" class="btn btn-primary">Login</button>

        <div *ngIf="loading.active" class="spinner-border text-primary float-end" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <small *ngIf="loading.active" style="margin-left: 10px">{{ loading.msg }}</small>

      </form>

    </div>

  </div>
</div>