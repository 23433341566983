export class Goal {
  id: number;
  source?: number;
  type: GoalType;
  description: string;
  notes: GoalNote[];
  status: GoalStatus;
  created: Date;
  updated: Date;
  displayNoteEditor: boolean;
  isCollapsed: boolean;
  loading: {
    goal: boolean;
    note: boolean;
  };

  constructor(data?: any) {
    this.id = data && data.id;
    this.source = data && data.source;
    this.type = data && data.type ? data.type : null;
    this.description = data && data.description ? data.description : '';
    this.notes = data && data.notes ? data.notes : [];
    this.status = data && data.status ? data.status : GoalStatus.Ongoing;
    this.created = data && data.created ? data.created : new Date();
    this.updated = data && data.updated ? data.updated : new Date();
    this.displayNoteEditor = false;
    this.isCollapsed = true;
    this.loading = {
      goal: false,
      note: false,
    };
  }
}

export class GoalNote {
  date: Date;
  author: GoalAuthor;
  text: string;
  call: number;

  constructor(data?: any) {
    this.date = data && data.date ? data.date : new Date();
    this.author = data && data.author ? data.author : new GoalAuthor();
    this.text = data && data.text ? data.text : '';
    this.call = data && data.call ? data.call : undefined;
  }
}

export class GoalAuthor {
  id: number;
  name: string;

  constructor(data?: any) {
    this.id = data && data.id;
    this.name = data && data.name ? data.name : '';
  }
}

export enum GoalType {
  medicationManagement = 'Medication Management',
  symptomsManagement = 'Symptoms management',
  physicalActivity = 'Physical activity',
  emotionalWellbeing = 'Emotional wellbeing',
  sleep = 'Sleep',
  lifestyle = 'Lifestyle',
  vocational = 'Vocational',
  social = 'Social',
  ADLs = 'ADLs',
  conditionEducation = 'Condition Education',
  other = 'Other',
}

export enum GoalStatus {
  Ongoing = 'Ongoing',
  PartiallyComplete = 'Partially Complete',
  Complete = 'Complete',
  Closed = 'Closed',
  UnachievableUnrealistic = 'Unachievable/unrealistic'
}
