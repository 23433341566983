import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { Call } from '@models/call';

@Injectable({
  providedIn: 'root',
})
export class CallService {
  constructor(private http: HttpClient) {}

  public get(id: number): Observable<Call[]> {
    return this.http.get<Call[]>(environment.apiUrl + '/interventions/' + id);
  }

  public create(call: any): Observable<Call> {
    return this.http.post<Call>(environment.apiUrl + '/interventions/', call);
  }

  public update(call: Call): Observable<Call> {
    return this.http.put<Call>(
      environment.apiUrl + '/interventions/' + call.id,
      call
    );
  }

  public delete(id: number): Observable<any> {
    return this.http.delete<any>(
      environment.apiUrl + '/interventions/' + IDBCursor
    );
  }
}
