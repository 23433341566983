export class InterventionOnboarding {
  contact5WorkingDays: string;
  contact5WorkingDaysNo: string;
  attemptNumber?: number;
  telephoneSupportExplained: boolean;
  gdprServiceUserConsent: boolean;
  adviceLineInformationProvided: boolean;
  serviceDeclined?: string;
  preferredCallFrequency?: string;
  preferredCallDays?: string;
  preferredCallTimes?: string;
  preferredCallTimesOther?: string;
  text: string;
  nextCall?: string;
  admissionLetterSent?: boolean;

  constructor(data?: {
    contact5WorkingDays?: string;
    contact5WorkingDaysNo?: string;
    attemptNumber?: number;
    telephoneSupportExplained?: boolean;
    gdprServiceUserConsent?: boolean;
    adviceLineInformationProvided?: boolean;
    serviceDeclined?: string;
    preferredCallFrequency?: string;
    preferredCallDays?: string;
    preferredCallTimes?: string;
    preferredCallTimesOther?: string;
    text?: string;
    nextCall?: string;
    admissionLetterSent?: boolean;
  }) {
    this.contact5WorkingDays =
      data && data.contact5WorkingDays ? data.contact5WorkingDays : '';
    this.contact5WorkingDaysNo =
      data && data.contact5WorkingDaysNo ? data.contact5WorkingDaysNo : '';
    this.attemptNumber =
      data && data.attemptNumber ? data.attemptNumber : undefined;
    this.telephoneSupportExplained =
      data && data.telephoneSupportExplained
        ? data.telephoneSupportExplained
        : false;
    this.gdprServiceUserConsent =
      data && data.gdprServiceUserConsent ? data.gdprServiceUserConsent : false;
    this.adviceLineInformationProvided =
      data && data.adviceLineInformationProvided
        ? data.adviceLineInformationProvided
        : false;
    this.serviceDeclined =
      data && data.serviceDeclined ? data.serviceDeclined : undefined;
    this.preferredCallFrequency =
      data && data.preferredCallFrequency
        ? data.preferredCallFrequency
        : undefined;
    this.preferredCallDays =
      data && data.preferredCallDays ? data.preferredCallDays : undefined;
    this.preferredCallTimes =
      data && data.preferredCallTimes ? data.preferredCallTimes : undefined;
    this.preferredCallTimesOther =
      data && data.preferredCallTimesOther
        ? data.preferredCallTimesOther
        : undefined;
    this.text = data && data.text ? data.text : '';
    this.nextCall = data && data.nextCall ? data.nextCall : '';
    this.admissionLetterSent =
      data && data.admissionLetterSent ? data.admissionLetterSent : undefined;
  }
}
