<div
  class="w-100 bg-light"
  style="position: fixed; bottom: 0; border-top: 1px solid #dee2e6; z-index: 2"
>
  <div
    class="d-flex justify-content-between container-sm w-100 py-4"
    style="max-width: 1024px"
  >
    <div>
      <button
        *ngIf="previousActive"
        (click)="handleNavigate('previous')"
        class="btn btn-primary"
        style="width: 110px"
      >
        <fa-icon
          [icon]="['far', 'chevron-left']"
          class="text-white me-2"
        ></fa-icon
        >Previous
      </button>
    </div>

    <div>
      <button
        *ngIf="nextActive"
        (click)="handleNavigate('next')"
        class="btn btn-primary float-end"
        style="width: 110px"
      >
        Next<fa-icon
          [icon]="['far', 'chevron-right']"
          class="text-white ms-2"
        ></fa-icon>
      </button>

      <ng-container *ngIf="submittable">
        <div
          *ngIf="loading"
          class="spinner-border text-success me-3"
          role="status"
          style="height: 2.1rem; width: 2.1rem"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          (click)="handleSubmit()"
          class="btn btn-success float-end"
          style="width: 110px"
          [disabled]="loading"
        >
          Submit
        </button>
      </ng-container>
    </div>
  </div>
</div>
