import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StagedForm, Stage } from '@app/models/form';
import { StageService } from '@app/services/stage.service';

@Component({
  selector: 'app-form-display',
  templateUrl: './form-display.component.html',
  styleUrls: ['./form-display.component.scss'],
})
export class FormDisplayComponent implements OnInit {
  @Input() formData!: StagedForm;
  @Input() showProgress: boolean = true;
  @Input() showNavigation: boolean = true;
  @Input() showStageDescriptions: boolean = true;
  @Input() showPageFrame: boolean = true;
  @Input() submitting: boolean = false;
  @Output() submit = new EventEmitter();

  currentStageIndex: number = 0;
  currentStage!: Stage;
  form!: FormGroup;

  constructor(public stageService: StageService) {}

  ngOnInit(): void {
    this.loadStage(0);
  }

  loadStage(index: number) {
    if (this.formData.stages[index]) {
      this.currentStageIndex = index;
      this.currentStage = this.formData.stages[index];
      if (!this.currentStage.formGroup) {
        this.currentStage.formGroup = this.stageService.toFormGroup(
          this.currentStage.questions
        );
      }

      this.form = this.currentStage.formGroup;
    }
  }

  navigate(direction: string) {
    let n = this.formData.stages.indexOf(this.currentStage);

    switch (direction) {
      case 'previous':
        n--;
        this.loadStage(n);
        break;
      case 'next':
        if (this.stageService.validate(this.currentStage)) {
          n++;
          this.loadStage(n);
          window.scrollTo(0, 0);
        }
        break;
    }
    this.stageService.toServerPayload(this.formData.stages);
  }

  handleSubmit() {
    if (this.stageService.validate(this.currentStage)) {
      this.submitting = true;
      this.submit.emit(this.stageService.toServerPayload(this.formData.stages));
    }
  }
}
