<form [formGroup]="form" class="form">
  <div
    [class.mb-3]="question.type !== 'html'"
    *ngFor="let question of questions"
  >
    <ng-container
      *ngIf="stageService.questionDisplayConditionsMet(question, form)"
    >
      <label
        [attr.for]="question.key"
        class="form-label w-100"
        *ngIf="question.type !== 'html' && question.type !== 'checkbox'"
      >
        {{ question.label }}
        <span class="text-primary" *ngIf="question.required">*</span>
        <span
          class="float-end text-danger"
          *ngIf="
            !this.form.controls[question.key].valid &&
            (this.form.controls[question.key].dirty ||
              this.form.controls[question.key].touched)
          "
        >
          <span [hidden]="!this.form.controls[question.key].errors?.required">
            {{ question.label }} is required.
          </span>
          <span [hidden]="!this.form.controls[question.key].errors?.pattern">
            Incorrect format.
          </span>
          <span [hidden]="!this.form.controls[question.key].errors?.minlength">
            Value is too short.
          </span>
          <span [hidden]="!this.form.controls[question.key].errors?.min">
            Value is below the minimum.
          </span>
          <span [hidden]="!this.form.controls[question.key].errors?.max">
            Value is above the maximum.
          </span>
        </span>
        <span class="float-end text-danger">
          <fa-icon
            *ngIf="
              form.controls[question.key].touched &&
              !form.controls[question.key].errors
            "
            [icon]="['far', 'check']"
            class="text-primary"
          ></fa-icon>
        </span>
      </label>

      <div class="position-relative" [ngSwitch]="question.type">
        <input
          *ngSwitchCase="'textbox'"
          [formControlName]="question.key"
          [id]="question.key"
          [type]="question.type"
          [disabled]="question.disabled"
          [placeholder]="question.placeholder"
          class="form-control"
          [class.is-invalid]="
            form.controls[question.key].touched &&
            form.controls[question.key].errors
          "
        />

        <ng-select
          *ngSwitchCase="'dropdown'"
          [id]="question.key"
          [formControlName]="question.key"
          [items]="question.options"
          [clearable]="true"
          [searchable]="false"
          bindLabel="key"
          bindValue="value"
          placeholder="Please select"
          [class.is-invalid]="
            form.controls[question.key].touched &&
            form.controls[question.key].errors
          "
        >
        </ng-select>

        <app-multi-select
          *ngSwitchCase="'multiSelect'"
          [formControlName]="question.key"
          [options]="question.options"
          [cols]="3"
        >
        </app-multi-select>

        <app-button-select
          *ngSwitchCase="'radio'"
          [formControlName]="question.key"
          [classes]="'w-50'"
          [options]="question.options"
        >
        </app-button-select>

        <app-dob *ngSwitchCase="'dateOfBirth'" [formControlName]="question.key">
        </app-dob>

        <app-goals *ngSwitchCase="'goal'" [formControlName]="question.key">
        </app-goals>

        <textarea
          *ngSwitchCase="'textarea'"
          [id]="question.key"
          [formControlName]="question.key"
          [placeholder]="
            question.placeholder ? question.placeholder : 'Please specify'
          "
          class="form-control"
          rows="5"
          [class.is-invalid]="
            form.controls[question.key].touched &&
            form.controls[question.key].errors
          "
        ></textarea>

        <div class="form-text" *ngSwitchCase="'checkbox'">
          <label class="custom-checkbox cursor">
            <input
              type="checkbox"
              [id]="question.key"
              [formControlName]="question.key"
            />
            <fa-icon
              [icon]="['fas', 'square']"
              class="me-2"
              *ngIf="!form.controls[question.key].value"
            >
            </fa-icon>
            <fa-icon
              [icon]="['fas', 'check-square']"
              class="text-primary me-2"
              *ngIf="form.controls[question.key].value"
            >
            </fa-icon>
            <span
              [class.text-danger]="
                question.required &&
                form.controls[question.key].value !== true &&
                form.controls[question.key].touched
              "
            >
              {{ question.label }}
              <span class="text-primary" *ngIf="question.required"
                >*</span
              ></span
            >
          </label>
        </div>

        <div
          class="form-text"
          *ngSwitchCase="'html'"
          [innerHTML]="question.label"
        ></div>

        <div class="input-group mb-3" *ngSwitchCase="'date'">
          <input
            #datePicker="bsDatepicker"
            type="text"
            [id]="question.key"
            [formControlName]="question.key"
            placeholder="Please select"
            class="w-50 form-control"
            [class.is-invalid]="
              form.controls[question.key].touched &&
              form.controls[question.key].errors
            "
            [bsConfig]="{
              containerClass: 'theme-default',
              dateInputFormat: 'DD/MM/YYYY'
            }"
            placement="top"
            bsDatepicker
          />
          <button
            class="btn btn-sm btn-secondary"
            (click)="datePicker.toggle()"
          >
            <fa-icon
              [icon]="['fal', 'calendar-alt']"
              class="text-white mx-1"
            ></fa-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</form>
<div class="clearfix">
  <button
    class="btn btn-primary float-end"
    (click)="submitForm($event)"
    [disabled]="loading"
  >
    Add to user timeline
  </button>
  <div *ngIf="error" class="text-danger">{{ error }}</div>
  <div *ngIf="loading" class="spinner-border text-primary me-2" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
