import { Component, OnInit } from '@angular/core';

import { respiratoryForm } from '@app/forms/stages-form-respiratory';

import { StagedForm } from '@app/models/form';
import { StageService } from '@app/services/stage.service';
import { InterventionService } from '@app/services/intervention.service';
import { Router } from '@angular/router';
import { ServiceUserStatus } from '@app/models/service-user';
import { AuthenticationService } from '@app/services/authentication/authentication.service';

@Component({
  selector: 'app-form-respiratory',
  templateUrl: './form-respiratory.component.html',
  styleUrls: ['./form-respiratory.component.scss'],
})
export class FormRespiratoryComponent implements OnInit {
  formData: StagedForm = respiratoryForm;

  constructor(
    public stageService: StageService,
    private interventionService: InterventionService,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {}

  handleSubmit(form: any) {
    form['type'] = 'ReferralRespiratory';
    form['serviceUser']['status'] = ServiceUserStatus.triage;

    form.serviceUser.personalDetails.address = [
      form.serviceUser.personalDetails.address,
    ];
    form.data.referrer.personalDetails.address = [
      form.data.referrer.personalDetails.address,
    ];

    this.interventionService.create(form).subscribe(
      (response) => {
        if (this.authService.isLoggedIn()) {
          this.router.navigate(['service-users']);
        } else {
          this.router.navigate(['referrals/thank-you']);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
