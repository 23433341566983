<app-header></app-header>
<section>
  <div class="container-fluid px-5">
    <h2>Access Denied</h2>
    <p>
      I'm sorry, you do not have the required access rights to use this
      application. For more information or to get access to the app, please
      contact the advice line team.
    </p>
  </div>
</section>
