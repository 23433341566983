<!-- {{ person | json }} -->

<form [formGroup]="personForm" class="form" *ngIf="person">
  <div class="mb-3">
    <label for="title" class="form-label w-100">
      Title <span class="text-primary" *ngIf="isRequiredField('title')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="!f.title?.valid && (f.title?.dirty || f.title?.touched)"
      >
        <span [hidden]="!f.title.errors?.required"> Title is required </span>
      </span>
    </label>
    <div class="position-relative">
      <ng-select
        id="title"
        formControlName="title"
        [items]="titleOptions"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'Please select'"
        [class.is-invalid]="f.title?.touched && f.title?.errors"
      >
      </ng-select>
      <fa-icon
        *ngIf="f.title?.touched && !f.title?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3" *ngIf="displayTitleOther">
    <label for="titleOther" class="form-label w-100">
      Title (Other)
      <span class="text-primary" *ngIf="isRequiredField('titleOther')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="
          !f.titleOther?.valid && (f.titleOther?.dirty || f.titleOther?.touched)
        "
      >
        <span [hidden]="!f.titleOther.errors?.required">
          Title is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <input
        id="titleOther"
        formControlName="titleOther"
        class="form-control"
        placeholder="Other (Please state)"
        [class.is-invalid]="f.titleOther?.touched && f.titleOther?.errors"
      />
      <fa-icon
        *ngIf="f.titleOther?.touched && !f.titleOther?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="firstName" class="form-label w-100">
      First name
      <span class="text-primary" *ngIf="isRequiredField('firstName')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="
          !f.firstName?.valid && (f.firstName?.dirty || f.firstName?.touched)
        "
      >
        <span [hidden]="!f.firstName.errors?.required">
          First name is required
        </span>
      </span>
    </label>

    <div class="position-relative">
      <input
        id="firstName"
        formControlName="firstName"
        class="form-control"
        placeholder=""
        [class.is-invalid]="f.firstName?.touched && f.firstName?.errors"
      />
      <fa-icon
        *ngIf="f.firstName?.touched && !f.firstName?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="lastName" class="form-label w-100">
      Last name
      <span class="text-primary" *ngIf="isRequiredField('lastName')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="!f.lastName?.valid && (f.lastName?.dirty || f.lastName?.touched)"
      >
        <span [hidden]="!f.lastName.errors?.required">
          Last name is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <input
        id="lastName"
        formControlName="lastName"
        class="form-control"
        placeholder=""
        [class.is-invalid]="f.lastName?.touched && f.lastName?.errors"
      />
      <fa-icon
        *ngIf="f.lastName?.touched && !f.lastName?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="addressNumber" class="form-label w-100">
      Address name/number
      <span class="text-primary" *ngIf="isRequiredField('addressNumber')"
        >*</span
      >
      <span
        class="float-end text-danger"
        *ngIf="
          !f.addressNumber?.valid &&
          (f.addressNumber?.dirty || f.addressNumber?.touched)
        "
      >
        <span [hidden]="!f.addressNumber.errors?.required">
          Address name/number is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <input
        id="addressNumber"
        formControlName="addressNumber"
        class="form-control"
        placeholder=""
        [class.is-invalid]="f.addressNumber?.touched && f.addressNumber?.errors"
      />
      <fa-icon
        *ngIf="f.addressNumber?.touched && !f.addressNumber?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="addressStreet" class="form-label w-100">
      Street
      <span class="text-primary" *ngIf="isRequiredField('addressStreet')"
        >*</span
      >
      <span
        class="float-end text-danger"
        *ngIf="
          !f.addressStreet?.valid &&
          (f.addressStreet?.dirty || f.addressStreet?.touched)
        "
      >
        <span [hidden]="!f.addressStreet.errors?.required">
          Street is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <input
        id="addressStreet"
        formControlName="addressStreet"
        class="form-control"
        placeholder=""
        [class.is-invalid]="f.addressStreet?.touched && f.addressStreet?.errors"
      />
      <fa-icon
        *ngIf="f.addressStreet?.touched && !f.addressStreet?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="addressTownCity" class="form-label w-100">
      Town/City
      <span class="text-primary" *ngIf="isRequiredField('addressTownCity')"
        >*</span
      >
      <span
        class="float-end text-danger"
        *ngIf="
          !f.addressTownCity?.valid &&
          (f.addressTownCity?.dirty || f.addressTownCity?.touched)
        "
      >
        <span [hidden]="!f.addressTownCity.errors?.required">
          Town/City is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <input
        id="addressTownCity"
        formControlName="addressTownCity"
        class="form-control"
        placeholder=""
        [class.is-invalid]="
          f.addressTownCity?.touched && f.addressTownCity?.errors
        "
      />
      <fa-icon
        *ngIf="f.addressTownCity?.touched && !f.addressTownCity?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="addressPostcode" class="form-label w-100">
      Postcode
      <span class="text-primary" *ngIf="isRequiredField('addressPostcode')"
        >*</span
      >
      <span
        class="float-end text-danger"
        *ngIf="
          !f.addressPostcode?.valid &&
          (f.addressPostcode?.dirty || f.addressPostcode?.touched)
        "
      >
        <span [hidden]="!f.addressPostcode.errors?.required">
          Postcode is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <input
        id="addressPostcode"
        formControlName="addressPostcode"
        class="form-control"
        placeholder=""
        [class.is-invalid]="
          f.addressPostcode?.touched && f.addressPostcode?.errors
        "
      />
      <fa-icon
        *ngIf="f.addressPostcode?.touched && !f.addressPostcode?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="telephone" class="form-label w-100">
      Telephone contact 1
      <span class="text-primary" *ngIf="isRequiredField('telephone')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="
          !f.telephone?.valid && (f.telephone?.dirty || f.telephone?.touched)
        "
      >
        <span [hidden]="!f.telephone.errors?.required">
          Telephone is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <input
        id="telephone"
        formControlName="telephone"
        class="form-control"
        placeholder=""
        [class.is-invalid]="f.telephone?.touched && f.telephone?.errors"
      />
      <fa-icon
        *ngIf="f.telephone?.touched && !f.telephone?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="mobile" class="form-label w-100">
      Telephone contact 2
      <span class="text-primary" *ngIf="isRequiredField('telephone2')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="
          !f.telephone2?.valid && (f.telephone2?.dirty || f.telephone2?.touched)
        "
      >
        <span [hidden]="!f.telephone2.errors?.required">
          Telephone contact 2 is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <input
        id="mobile"
        formControlName="telephone2"
        class="form-control"
        placeholder=""
        [class.is-invalid]="f.telephone2?.touched && f.telephone2?.errors"
      />
      <fa-icon
        *ngIf="f.telephone2?.touched && !f.telephone2?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="email" class="form-label w-100">
      Email <span class="text-primary" *ngIf="isRequiredField('email')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="!f.email?.valid && (f.email?.dirty || f.email?.touched)"
      >
        <span [hidden]="!f.email.errors?.required"> Email is required </span>
        <div [hidden]="!f.email.errors?.pattern">
          Please provide a valid email address
        </div>
      </span>
    </label>
    <div class="position-relative">
      <input
        id="email"
        formControlName="email"
        class="form-control"
        placeholder=""
        [class.is-invalid]="f.email?.touched && f.email?.errors"
      />
      <fa-icon
        *ngIf="f.email?.touched && !f.email?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3 w-100">
    <label for="dob" class="form-label w-100">
      Date of birth
      <span class="text-primary" *ngIf="isRequiredField('dob')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="!f.dob?.valid && (f.dob?.dirty || f.dob?.touched)"
      >
        <span [hidden]="!f.dob.errors?.required">
          Date of birth is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <div class="input-group mb-3">
        <input
          #dob="bsDatepicker"
          type="text"
          formControlName="dob"
          placeholder="Please select"
          class="form-control"
          [class.is-invalid]="f.dob.touched && f.dob.errors"
          [bsConfig]="{
            containerClass: 'theme-default',
            dateInputFormat: 'DD/MM/YYYY'
          }"
          bsDatepicker
        />
        <fa-icon
          *ngIf="f.dob?.touched && !f.dob?.errors"
          [icon]="['far', 'check']"
          class="text-primary"
        ></fa-icon>
        <button class="btn btn-sm btn-secondary" (click)="dob.toggle()">
          <fa-icon
            [icon]="['fal', 'calendar-alt']"
            class="text-white mx-1"
          ></fa-icon>
        </button>
      </div>
      <fa-icon
        *ngIf="f.dob?.touched && !f.dob?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="gender" class="form-label w-100">
      Gender
      <span class="text-primary" *ngIf="isRequiredField('gender')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="!f.gender?.valid && (f.gender?.dirty || f.gender?.touched)"
      >
        <span [hidden]="!f.gender.errors?.required"> Gender is required </span>
      </span>
    </label>
    <div class="position-relative">
      <ng-select
        id="gender"
        formControlName="gender"
        [items]="genderOptions"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'Please select'"
        [class.is-invalid]="f.gender?.touched && f.gender?.errors"
      >
      </ng-select>
      <fa-icon
        *ngIf="f.gender?.touched && !f.gender?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="maritalStatus" class="form-label w-100">
      Maritial status
      <span class="text-primary" *ngIf="isRequiredField('maritalStatus')"
        >*</span
      >
      <span
        class="float-end text-danger"
        *ngIf="
          !f.maritalStatus?.valid &&
          (f.maritalStatus?.dirty || f.maritalStatus?.touched)
        "
      >
        <span [hidden]="!f.maritalStatus.errors?.required">
          Maritial status is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <ng-select
        id="maritalStatus"
        formControlName="maritalStatus"
        [items]="maritalStatusOptions"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'Please select'"
        [class.is-invalid]="f.maritalStatus?.touched && f.maritalStatus?.errors"
      >
      </ng-select>
      <fa-icon
        *ngIf="f.maritalStatus?.touched && !f.maritalStatus?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <div class="mb-3">
    <label for="livesWith" class="form-label w-100">
      Lives with
      <span class="text-primary" *ngIf="isRequiredField('livesWith')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="
          !f.livesWith?.valid && (f.livesWith?.dirty || f.livesWith?.touched)
        "
      >
        <span [hidden]="!f.livesWith.errors?.required">
          Lives with is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <ng-select
        id="livesWith"
        formControlName="livesWith"
        [items]="livesWithOptions"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'Please select'"
        [class.is-invalid]="f.livesWith?.touched && f.livesWith?.errors"
      >
      </ng-select>
      <fa-icon
        *ngIf="f.livesWith?.touched && !f.livesWith?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>

  <hr />
  <h5>Diversity & Equality</h5>
  <p class="alert alert-info">
    It’s a legal requirement as part of the equalities act (2010) that
    organisations must be able to report on protected characteristics to ensure
    equality of access to services
  </p>
  <div class="mb-3">
    <label for="employmentStatus" class="form-label w-100">
      Employment status
      <span class="text-primary" *ngIf="isRequiredField('employmentStatus')"
        >*</span
      >
      <span
        class="float-end text-danger"
        *ngIf="
          !f.employmentStatus?.valid &&
          (f.employmentStatus?.dirty || f.employmentStatus?.touched)
        "
      >
        <span [hidden]="!f.employmentStatus.errors?.required">
          Employment status is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <ng-select
        id="employmentStatus"
        formControlName="employmentStatus"
        [items]="employmentStatusOptions"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'Please select'"
        [class.is-invalid]="
          f.employmentStatus?.touched && f.employmentStatus?.errors
        "
      >
      </ng-select>
      <fa-icon
        *ngIf="f.employmentStatus?.touched && !f.employmentStatus?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>
  <div class="mb-3">
    <label for="sexuality" class="form-label w-100">
      Sexuality
      <span class="text-primary" *ngIf="isRequiredField('sexuality')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="
          !f.sexuality?.valid && (f.sexuality?.dirty || f.sexuality?.touched)
        "
      >
        <span [hidden]="!f.sexuality.errors?.required">
          Sexuality is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <ng-select
        id="sexuality"
        formControlName="sexuality"
        [items]="sexualityOptions"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'Please select'"
        [class.is-invalid]="f.sexuality?.touched && f.sexuality?.errors"
      >
      </ng-select>
      <fa-icon
        *ngIf="f.sexuality?.touched && !f.sexuality?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>
  <div class="mb-3">
    <label for="ethnicity" class="form-label w-100">
      Ethnicity
      <span class="text-primary" *ngIf="isRequiredField('ethnicity')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="
          !f.ethnicity?.valid && (f.ethnicity?.dirty || f.ethnicity?.touched)
        "
      >
        <span [hidden]="!f.ethnicity.errors?.required">
          Ethnicity is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <ng-select
        id="ethnicity"
        formControlName="ethnicity"
        [items]="ethnicityOptions"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'Please select'"
        [class.is-invalid]="f.ethnicity?.touched && f.ethnicity?.errors"
      >
      </ng-select>
      <fa-icon
        *ngIf="f.ethnicity?.touched && !f.ethnicity?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>


  <div class="mb-3" *ngIf="f.ethnicity?.value == 'Other...'">
    <label for="ethnicityOther" class="form-label w-100">
      Other ethnicity
      <span class="text-primary" *ngIf="isRequiredField('ethnicityOther')">*</span>
      <span
        class="float-end text-danger"
        *ngIf="!f.ethnicityOther?.valid && (f.ethnicityOther?.dirty || f.ethnicityOther?.touched)"
      >
        <span [hidden]="!f.ethnicityOther.errors?.required">
          Other ethnicity is required
        </span>
      </span>
    </label>
    <div class="position-relative">
      <input
        id="ethnicityOther"
        formControlName="ethnicityOther"
        class="form-control"
        placeholder=""
        [class.is-invalid]="f.ethnicityOther?.touched && f.ethnicityOther?.errors"
      />
      <fa-icon
        *ngIf="f.ethnicityOther?.touched && !f.ethnicityOther?.errors"
        [icon]="['far', 'check']"
        class="text-primary"
      ></fa-icon>
    </div>
  </div>


  <div class="mt-4">
    <button
      [disabled]="!authService.userHasPermission('edit')"
      (click)="submit()"
      class="btn btn-primary"
      style="width: 110px"
    >
      Save
    </button>
    <div
      *ngIf="loading"
      class="spinner-border text-primary float-end mt-1"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</form>
