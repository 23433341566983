import { Referrer } from './referrer';
import { History } from './history';

export class ReferralRespiratory {
  referralDate: string;
  referralDateNa: boolean;
  currentSupport: string[];
  history: History;
  referrer: Referrer;
  additionalInformation: ReferralAdditionalInformation;

  constructor(data?: any) {
    this.referralDate =
      data && data.referralDate ? data.referralDate : undefined;
    this.referralDateNa =
      data && data.referralDateNa ? data.referralDateNa : false;

    this.currentSupport =
      data && data.currentSupport ? data.currentSupport : undefined;
    this.history = data && data.history ? data.history : new History();
    this.referrer = data && data.referrer ? data.referrer : new Referrer();
    this.additionalInformation =
      data && data.additionalInformation
        ? data.additionalInformation
        : new ReferralAdditionalInformation();
  }
}

export class ReferralAdditionalInformation {
  facilitation: string[];
  facilitationOther: string;
  facilitationTailoredTalks: string;
  currentSupport: string[];
  currentSupportOther: string;
  otherComments: string;
  clinicalReviewDate: string;
  dateOfDischarge: string;
  gdprConsentGiven: boolean;

  constructor(data?: any) {
    this.facilitation = data && data.facilitation ? data.facilitation : [];
    this.facilitationOther =
      data && data.facilitationTailoredTalks ? data.facilitationOther : '';
    this.facilitationTailoredTalks =
      data && data.facilitationTailoredTalks
        ? data.facilitationTailoredTalks
        : '';
    this.currentSupport =
      data && data.currentSupport ? data.currentSupport : [];
    this.currentSupportOther =
      data && data.currentSupportOther ? data.currentSupportOther : '';
    this.otherComments = data && data.otherComments ? data.otherComments : '';
    this.clinicalReviewDate =
      data && data.clinicalReviewDate ? data.clinicalReviewDate : '';
    this.dateOfDischarge =
      data && data.dateOfDischarge ? data.dateOfDischarge : '';
    this.gdprConsentGiven =
      data && data.gdprConsentGiven ? data.gdprConsentGiven : false;
  }
}
