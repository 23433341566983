import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Stage } from '@app/models/form';

@Component({
  selector: 'app-form-progress',
  templateUrl: './form-progress.component.html',
  styleUrls: ['./form-progress.component.scss'],
})
export class FormProgressComponent implements OnInit, OnChanges {
  @Input() stages: Stage[] = [];
  @Input() active: Stage = new Stage();
  @Output() update = new EventEmitter();

  stage: any = {};
  width: string = '';
  left: string = '';
  value: number = 0;

  // internal counters
  stageCount: number = 0;
  stageSplashCount: number = 0;
  previousSplashCount: number = 0;

  constructor() {}

  ngOnInit() {
    this.stages.forEach((s) => {
      if (s.type === 'splash') {
        this.stageSplashCount++;
      } else {
        this.stageCount++;
      }
    });

    this.calculate();

    this.active = this.stages[0];
    const unit = 100 / (this.stageCount * 2);
    this.width = unit * (this.stageCount * 2 - 2) + '%';
    this.left = unit + '%';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.active.isFirstChange()) {
      this.setStage(changes.active.currentValue);
    }
  }

  setStage(stage: Stage, manual: boolean = false) {
    if (manual) {
      if (this.stages.indexOf(this.active) > this.stages.indexOf(stage)) {
        this.active = stage;
        this.update.emit(stage);
      }
    } else {
      this.active = stage;
    }

    this.value =
      ((this.stages.indexOf(this.active) - this.previousSplashCount) /
        (this.stageCount - this.stageSplashCount)) *
      100;

    if (this.value < 0) {
      this.value = 0;
    }
  }

  calculate() {
    let previousSplash = 0;

    for (let s of this.stages) {
      if (s.type === 'splash') {
        previousSplash++;
      }
      if (s === this.active) {
        break;
      }
    }

    this.previousSplashCount = previousSplash;
  }
}
