import { Component, Input, OnInit } from '@angular/core';
import { Goal } from '@app/models/goal';
import { Intervention } from '@app/models/intervention';
import { ReferralIcu } from '@app/models/intervention-referral-icu';

@Component({
  selector: 'app-summary-referral-icu',
  templateUrl: './summary-referral-icu.component.html',
  styleUrls: ['./summary-referral-icu.component.scss'],
})
export class SummaryReferralIcuComponent implements OnInit {
  @Input() intervention!: Intervention<ReferralIcu>;
  @Input() goals: Goal[] = [];

  constructor() {}

  ngOnInit(): void {}
}
