<div class="card w-100 cursor">
  <div class="card-body">
    <h5>{{ title }}</h5>

    <ul class="fw-lighter mb-0">
      <li *ngFor="let signpost of intervention.data.data">
        {{ signpost.title }}
      </li>
    </ul>
    <p class="fw-lighter mb-0" *ngIf="intervention.data.text">
      {{ intervention.data.text }}
    </p>
  </div>
</div>
