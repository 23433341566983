<span
  *ngIf="time"
  class="btn alert-secondary rounded"
  style="width: 120px"
  (click)="openModal(template)"
  ><span class="h2">{{ time | date: "HH:mm" }}</span></span
>

<button
  *ngIf="!time"
  class="btn alert-primary"
  style="width: 120px; padding: 12px 20px"
  (click)="setTime()"
>
  {{ buttonTitle }}
</button>

<span class="d-inline-block ms-2 text-primary cursor" (click)="setTime(null)"
  >Clear</span
>

<!-- <span *ngIf="time" type="button" class="d-inline-block ms-2 text-primary" (click)="openModal(template)">Edit</span> -->

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ modalTitle }}</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="clearfix">
      <div>
        <timepicker
          [(ngModel)]="time"
          (ngModelChange)="update()"
          [showMeridian]="false"
          class="float-start d-inline-block"
        >
        </timepicker>
      </div>

      <div class="d-inline-block mt-4 cursor" (click)="setTime()">
        <div class="text-primary">
          <fa-icon
            [icon]="['fal', 'clock']"
            class="float-start ms-3"
            style="font-size: 1.4rem"
          ></fa-icon
          ><span class="d-inline-block ms-2 mt-1">Set to current time</span>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <bs-datepicker-inline
        *ngIf="editDate"
        [(bsValue)]="time"
        [bsConfig]="{
          containerClass: 'theme-default'
        }"
      ></bs-datepicker-inline>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary mr-auto" (click)="modalRef?.hide()">
      Update
    </button>
    <button
      type="button"
      class="btn btn-secondary pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      Cancel
    </button>
  </div>
</ng-template>
