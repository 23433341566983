import { Component, Input, OnInit } from '@angular/core';
import { Goal } from '@app/models/goal';
import { Intervention } from '@app/models/intervention';
import { ReferralRespiratory } from '@app/models/intervention-referral-respiratory';

@Component({
  selector: 'app-summary-referral-respiratory',
  templateUrl: './summary-referral-respiratory.component.html',
  styleUrls: ['./summary-referral-respiratory.component.scss'],
})
export class SummaryReferralRespiratoryComponent implements OnInit {
  @Input() intervention!: Intervention<ReferralRespiratory>;
  @Input() goals: Goal[] = [];

  constructor() {}

  ngOnInit(): void {}
}
