import { Component, EventEmitter, OnInit } from '@angular/core';
import { Intervention, InterventionType } from '@app/models/intervention';
import { InterventionSignpost } from '@app/models/intervention-signpost';
import { ServiceUser } from '@app/models/service-user';
import { Signpost } from '@app/models/signpost';
import { SignpostingService } from '@app/services/signposting.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-signposting',
  templateUrl: './signposting.component.html',
  styleUrls: ['./signposting.component.scss'],
})
export class SignpostingComponent implements OnInit {
  public onClose: EventEmitter<any> = new EventEmitter();
  intervention!: Intervention<InterventionSignpost>;
  user!: ServiceUser;
  bsConfig = Object.assign({}, { containerClass: 'theme-default' });

  title: string = '';

  selected: any = [];
  organisations: Signpost[] = [];
  loading = { page: true, create: false };
  error = {
    page: '',
    create: '',
  };

  constructor(
    private signpostingService: SignpostingService,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.signpostingService.getAll().subscribe(
      (organisations) => {
        this.organisations = organisations;
        this.loading.page = false;
      },
      (error) => {
        this.error.page =
          'Unable to retrieve signposting list. Please try again and if the problem persists, contact IT.';
        this.loading.page = false;
      }
    );
  }

  update(selected: any) {
    this.selected = selected;
  }

  submit() {
    this.loading.create = true;
    this.intervention.data.ids = this.selected.map(
      (signpost: Signpost) => signpost.id
    );
    this.intervention.serviceUser.id = this.user.id;

    this.signpostingService.create(this.intervention).subscribe(
      (result) => {
        this.loading.create = false;
        this.onClose.emit(true);
        this.bsModalRef.hide();
      },
      (error) => {
        this.error.create =
          'Unable to create the signposting intervention for this service user. Please try again.';
        this.loading.create = false;
      }
    );
  }
}
