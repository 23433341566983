import { ReferralCardiac } from './intervention-referral-cardiac';
import { CaseManagerSummary } from './case-manager';
import { History } from './history';
import { Intervention } from './intervention';
import { InterventionNote } from './intervention-note';
import { Person, PersonMultiAddress } from './person';
import { Referrer } from './referrer';
import { Goal } from './goal';

export enum ServiceUserStatus {
  triage = 'triage',
  active = 'active',
  paused = 'paused',
  declined = 'declined',
  evaluation = 'awaiting evaluation',
  discharged = 'discharged',
}

export enum HealthBoards {
  lothian = 'Lothian',
  ggc = 'GGC',
}

export class ServiceUserSummary {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  interventionCount: number;
  status: ServiceUserStatus;
  caseManager?: CaseManagerSummary;
  lastInterventionDate?: string;
  nextCall: string;
  nextCallClass: string;
  admissionDate?: string;
  admissionType?: string;
  region?: string;

  constructor(user?: any) {
    this.id = user && user.id;
    this.title = user && user.title ? user.title : '';
    this.firstName = user && user.firstName ? user.firstName : '';
    this.lastName = user && user.lastName ? user.lastName : '';
    this.interventionCount =
      user && user.interventionCount ? user.interventionCount : 0;
    this.status = user && user.status ? user.status : ServiceUserStatus.triage;
    this.caseManager =
      user && user.caseManager ? user.caseManager : new CaseManagerSummary();
    this.admissionDate =
      user && user.admissionDate ? user.admissionDate : undefined;
    this.admissionType =
      user && user.admissionType ? user.admissionType : undefined;
    this.lastInterventionDate =
      user && user.lastInterventionDate ? user.lastInterventionDate : undefined;
    this.nextCall =
      user && user.nextCall ? user.nextCall : undefined;
    this.nextCallClass =
      user && user.nextCallClass ? user.nextCallClass : undefined;
    this.region = user && user.region ? user.region : undefined;
  }
}
export class ServiceUser {
  id?: number;
  status: ServiceUserStatus;
  personalDetails: PersonMultiAddress;
  interventions?: Intervention<any>[];
  caseManager?: CaseManagerSummary;
  lastInterventionDate?: string;
  nextCall: string;
  nextCallClass: string;
  admissionDate?: string;
  region?: string;
  goals: Goal[];

  constructor(data?: any) {
    this.status = data && data.status ? data.status : ServiceUserStatus.triage;
    this.personalDetails =
      data && data.personalDetails ? data.personalDetails : new Person();
    this.interventions = data && data.interventions ? data.interventions : [];
    this.goals = data && data.goals ? data.goals : [];
    this.lastInterventionDate =
    data && data.lastInterventionDate ? data.lastInterventionDate : undefined;
    this.nextCall =
    data && data.nextCall ? data.nextCall : undefined;
    this.nextCallClass =
    data && data.nextCallClass ? data.nextCallClass : undefined;
  }
}
