<div class="key-pair">
  <span class="key">Name:</span>
  <span
    *ngIf="
      !referrer?.personalDetails?.title &&
      !referrer?.personalDetails?.firstName &&
      !referrer?.personalDetails?.lastName
    "
    >Unknown</span
  >
  {{ referrer?.personalDetails?.title }}
  {{ referrer?.personalDetails?.firstName | titlecase }}
  {{ referrer?.personalDetails?.lastName | titlecase }}
</div>

<div class="key-pair">
  <span class="key">Address:</span>
  <span
    *ngIf="
      !referrer?.personalDetails?.address?.address1 &&
      !referrer?.personalDetails?.address?.address2 &&
      !referrer?.personalDetails?.address?.city &&
      !referrer?.personalDetails?.address?.postcode
    "
    >Unknown</span
  >
  <ng-container *ngIf="referrer?.personalDetails?.address?.address1"
    >{{ referrer?.personalDetails?.address?.address1 }}<br />
  </ng-container>
  <ng-container *ngIf="referrer?.personalDetails?.address?.address2"
    >{{ referrer?.personalDetails?.address?.address2 }}<br />
  </ng-container>
  <ng-container *ngIf="referrer?.personalDetails?.address?.city"
    >{{ referrer?.personalDetails?.address?.city }}<br />
  </ng-container>
  <ng-container *ngIf="referrer?.personalDetails?.address?.postcode"
    >{{ referrer?.personalDetails?.address?.postcode }}<br />
  </ng-container>
</div>

<div class="key-pair" *ngIf="referrer?.personalDetails?.phone">
  <span class="key">Telephone 1:</span>

  {{ referrer?.personalDetails?.phone }}
</div>

<div class="key-pair" *ngIf="referrer?.personalDetails?.mobile">
  <span class="key">Telephone 2:</span>

  {{ referrer?.personalDetails?.mobile }}
</div>

<div class="key-pair" *ngIf="referrer?.personalDetails?.email">
  <span class="key">Email:</span>
  <span>{{ referrer?.personalDetails?.email }}</span>
  <br *ngIf="referrer?.personalDetails?.emailSecondary" />
  <span class="key" style="visibility:hidden" *ngIf="referrer?.personalDetails?.emailSecondary">Email:</span>
  <span *ngIf="referrer?.personalDetails?.emailSecondary">{{ referrer?.personalDetails?.emailSecondary }}</span>

</div>

<div class="key-pair">
  <span class="key">Relationship:</span>
  <ng-container
    >{{ referrer?.relationship ? referrer?.relationship : "Unknown" }}
  </ng-container>
</div>

<div class="key-pair" *ngIf="referrer?.relationshipOther">
  <span class="key">Relationship Other:</span>
  <ng-container>{{ referrer?.relationshipOther }} </ng-container>
</div>
