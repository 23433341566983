<h6>
  <span class="text-primary">Topics discussed</span>
  <span class="btn btn-primary btn-sm float-end" (click)="new()">
    + Add Topic
  </span>
</h6>

<div class="w-100 mt-4 clearfix">
  <div class="row gx-1" *ngFor="let topic of topics">
    <div class="col-md-4">
      <ng-select
        class="mb-2 flex-fill"
        style="width: 100%"
        [items]="topicOptions"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'Select topic'"
        [(ngModel)]="topic.name"
        (ngModelChange)="topicUpdated()"
      >
      </ng-select>
    </div>
    <div class="col">
      <textarea
        [(ngModel)]="topic.text"
        (ngModelChange)="topicUpdated()"
        type="text"
        aria-label="Topic notes"
        class="mb-2 form-control flex-fill"
        placeholder="Topic notes"
        rows="2"
      ></textarea>
    </div>
    <div class="col-1" style="max-width: 20px">
      <span class="float-end" (click)="remove(topic, $event)">
        <fa-icon
          [icon]="['fas', 'minus-circle']"
          class="text-primary"
        ></fa-icon>
      </span>
    </div>
  </div>
</div>
