import { Goal } from './goal';
import { ServiceUser } from './service-user';

export class Call {
  id?: number;
  serviceUser: ServiceUser;
  type: string = 'call';
  generalCallNotes: string;
  postCallNotes: string;
  startTime: Date | null;
  endTime: Date | null;
  callerMood: number | null;
  nextCall: Date | null;
  goals: Goal[];
  topics: Topic[];

  constructor(data?: any) {
    this.id = data && data.id;
    this.serviceUser = data && data.serviceUser;
    this.generalCallNotes =
      data && data.generalCallNotes ? data.generalCallNotes : '';
    this.postCallNotes = data && data.postCallNotes ? data.postCallNotes : '';
    this.startTime = data && data.startTime ? data.startTime : undefined;
    this.endTime = data && data.endTime ? data.endTime : undefined;
    this.callerMood = data && data.callerMood ? data.callerMood : null;
    this.nextCall = data && data.nextCall ? data.nextCall : undefined;
    this.goals = data && data.goals ? data.goals : [];
    this.topics = data && data.topics ? data.topics : [];
  }
}

export class Topic {
  name: TopicType;
  text: string;

  constructor(data?: any) {
    this.name = data && data.name ? data.name : undefined;
    this.text = data && data.text ? data.text : '';
  }
}

export enum TopicType {
  sleep = 'Sleep',
  diet = 'Diet',
  pacing = 'Pacing',
  fatigueManagement = 'Fatigue management',
  physicalActivity = 'Physical Activity',
  emotionalWellbeing = 'Emotional Wellbeing',
  medicationsManagement = 'Medications management',
  conditionEducataion = 'Condition Education',
  symptomManagement = 'Symptom management',
  ADLS = 'ADL’s',
  anxiety = 'Anxiety',
  stress = 'Stress',
  weight = 'Weight',
  vocational = 'Vocational',
  finances = 'Finances',
  bloodPressure = 'Blood pressure',
  hobbies = 'Hobbies',
  socialIsolation = 'Social isolation',
  smoking = 'Smoking',
  other = 'Other',
}
