<div class="card">
  <div class="card-body" style="line-height: 165%">
    <h5 [class.text-danger]="didNotPickup">
      {{ title }}
      <div class="float-end pe-2" style="margin-top: -6px">
        <span style="font-size: 1.6rem">
          <fa-icon
            [icon]="['fal', 'laugh-beam']"
            class="text-success me-1"
            *ngIf="intervention.data.callerMood == 5"
          >
          </fa-icon>

          <fa-icon
            [icon]="['fal', 'smile']"
            class="text-success me-1"
            *ngIf="intervention.data.callerMood == 4"
          >
          </fa-icon>

          <fa-icon
            [icon]="['fal', 'meh']"
            class="text-warning me-1"
            *ngIf="intervention.data.callerMood == 3"
          >
          </fa-icon>

          <fa-icon
            [icon]="['fal', 'frown']"
            class="text-danger me-1"
            *ngIf="intervention.data.callerMood == 2"
          >
          </fa-icon>

          <fa-icon
            [icon]="['fal', 'sad-tear']"
            class="text-danger me-1"
            *ngIf="intervention.data.callerMood == 1"
          >
          </fa-icon>
        </span>
      </div>

      <span class="float-end form-text pe-2"
        >{{ intervention.data.startTime | date: "shortDate" }}
        <ng-container
          *ngIf="intervention.data.startTime && intervention.data.endTime"
        >
          -
          {{
            calculateDuration(
              intervention.data.startTime,
              intervention.data.endTime
            )
          }}</ng-container
        >
        <span *ngIf="didNotPickup" class="float-end ms-3 text-danger"
          >Did not pickup</span
        >
      </span>
    </h5>
    <p
      *ngIf="intervention.data.generalCallNotes"
      [innerHtml]="intervention.data.generalCallNotes | linebreaks"
      [class.mb-0]="
        (!intervention.data.topics ||
          (intervention.data.topics &&
            intervention.data.topics.length === 0)) &&
        (!intervention.data.goals ||
          (intervention.data.goals && intervention.data.goals.length === 0)) &&
        !intervention.data.postCallNotes
      "
    ></p>

    <ng-container
      *ngIf="intervention.data.topics && intervention.data.topics.length > 0"
    >
      <h6 class="text-primary mb-0 pb-0">Topics Discussed</h6>
      <ul class="pb-0">
        <ng-container *ngFor="let topic of intervention.data.topics">
          <li *ngIf="topic.name">
            <strong>{{ topic.name }}</strong> -
            {{ topic.text }}
          </li>
        </ng-container>
      </ul>
    </ng-container>

    <ng-container *ngIf="goalList && goalList.length > 0">
      <h6 class="text-primary mb-0 pb-0">Goals</h6>
      <ul class="pb-0 pt-0">
        <ng-container *ngFor="let goal of goalList">
          <li>
            <strong>{{ goal.type }} ({{ goal.description }})</strong> -
            {{
              goal.notes && goal.notes.length > 0 && goal.notes[0].text
                ? goal.notes[0].text
                : "not discussed"
            }}
          </li>
        </ng-container>
      </ul>
    </ng-container>

    <p
      *ngIf="intervention.data.postCallNotes"
      [innerHtml]="intervention.data.postCallNotes | linebreaks"
      class="mb-0"
    ></p>
  </div>
</div>
