<ul class="list-group">
  <li
    class="list-group-item cursor"
    *ngFor="let item of items"
    (click)="select(item)"
    [class.active]="selected.includes(item)"
  >
    {{ item.title }}
    <br />
    <ng-container *ngIf="item.description">
      <small
        class="form-text"
        [innerHtml]="item.description"
      ></small
      ><br />
    </ng-container>
    <small
      ><a href="{{ item.url }}" target="_blank">{{
        item.url
      }}</a></small
    >
  </li>
</ul>