import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Intervention } from '@models/intervention';
import { InterventionResource } from '@models/intervention-resource';
import { Resource } from '@models/resource';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  constructor(private http: HttpClient) {}

  public getAll(
    type: 'adviceline' | 'selfmanagement' = 'adviceline'
  ): Observable<Resource[]> {
    return this.http.get<Resource[]>(
      environment.apiUrl + '/resources?type=' + type
    );
  }

  public create(
    intervention: Intervention<InterventionResource>
  ): Observable<any> {
    return this.http.post<Intervention<InterventionResource>>(
      environment.apiUrl + '/interventions',
      intervention
    );
  }
}
