import { Component, EventEmitter, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { InterventionService } from '@services/intervention.service';

import { ServiceUser } from '@models/service-user';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Intervention, InterventionType } from '@app/models/intervention';
import { InterventionReferOn } from '@models/intervention-refer-on';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ReferOnService } from '@app/services/refer-on.service';
import { ReferOn } from '@app/models/refer-on';

@Component({
  selector: 'app-refer-on',
  templateUrl: './refer-on.component.html',
  styleUrls: ['./refer-on.component.scss'],
})
export class ReferOnComponent implements OnInit {
  public onClose: EventEmitter<any> = new EventEmitter();

  user!: ServiceUser;
  type!: string;
  title!: string;
  referOnData!: ReferOn;

  intervention: Intervention<InterventionReferOn>;
  form!: FormGroup;

  loading = false;

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private interventionService: InterventionService,
    public bsModalRef: BsModalRef
  ) {
    this.intervention = new Intervention<InterventionReferOn>({
      type: InterventionType.ReferOn,
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      date: [this.intervention.created, [Validators.required]],
      note: [this.intervention.data.text],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  isRequiredField(field: string) {
    const form_field = this.form.get(field);
    if (!form_field?.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator.required;
  }

  submit() {
    this.loading = true;

    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.intervention.serviceUser.id = this.user.id;
      this.intervention.data.text = this.f.note.value;
      this.intervention.data.title = this.referOnData.title;
      this.intervention.author = {
        id: this.authService.currentUserValue.id,
        name: '',
      };
      this.intervention.created = this.f.date.value;
      this.intervention.updated = this.f.date.value;

      this.interventionService.create(this.intervention).subscribe(
        (response) => {
          this.loading = false;
          this.onClose.emit(true);
          this.bsModalRef.hide();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}
