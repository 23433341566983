import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@services/authentication/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  submitted = false;
  activated = false;

  credentials = {
    username: '',
    password: '',
    tfa: '',
  };
  loading = {
    active: false,
    msg: '',
  };
  returnUrl = '';
  error = {
    message: '',
  };
  workspaces: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.activated = params.activated;
    });
  }

  ngOnInit(): void {
    localStorage.removeItem('currentUser');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  submit(e: any) {
    e.preventDefault();

    this.loading.active = true;
    this.loading.msg = 'Authenticating...';

    this.authenticationService.login(this.credentials).subscribe(
      (data) => {
        this.error = {
          message: '',
        };

        this.authenticationService
          .getGetRoles(this.authenticationService.currentUserValue.id)
          .subscribe(
            (chssUser) => {
              if (chssUser && chssUser.roles) {
                console.log('CHSS User', chssUser);
                this.authenticationService.setUserRoles(chssUser.roles);
                this.loading.active = false;
                if (this.returnUrl) {
                  this.router.navigate([this.returnUrl]);
                } else {
                  this.router.navigate(['/dashboard']);
                }
              }
            },
            (error) => {
              this.error.message = 'Error getting user roles';
              this.loading.active = false;
            }
          );
      },
      (error) => {
        this.error = error.error;
        this.loading.active = false;
      }
    );
  }
}
