import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceUserService } from '@services/service-user.service';
import { ServiceUser } from '@models/service-user';
import { Router, ActivatedRoute } from '@angular/router';

import { ServiceUserStatus } from '@models/service-user';

import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { ReferOnComponent } from '../refer-on/refer-on.component';
import { CaseManagerService } from '@app/services/case-manager.service';
import { forkJoin } from 'rxjs';
import { CaseManagerSummary } from '@models/case-manager';
import { NoteComponent } from '@components/note/note.component';
import { Intervention, InterventionType } from '@models/intervention';
import {
  InterventionReferOn,
  InterventionReferOnTypes,
} from '@models/intervention-refer-on';
import { InterventionNote } from '@app/models/intervention-note';
import { InterventionService } from '@app/services/intervention.service';
import { processZuluDate } from '@app/helpers/validators';
import { InterventionSignpost } from '@models/intervention-signpost';
import { SignpostingComponent } from '../signposting/signposting.component';
import { InterventionResource } from '@models/intervention-resource';
import { ResourcesComponent } from '../resources/resources.component';
import { ResourcesSelfManagementComponent } from '../resources-self-management/resources-self-management.component';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { InterventionDischarge } from '@app/models/intervention-discharge';
import { DischargeComponent } from '../discharge/discharge.component';
import { Goal } from '@app/models/goal';
import { InterventionOnboarding } from '@app/models/intervention-onboarding';
import { OnboardingComponent } from '../onboarding/onboarding.component';
import { environment } from '@env/environment';
import { ReferOnService } from '@app/services/refer-on.service';
import { ReferOn } from '@app/models/refer-on';
import * as _ from 'lodash';

export class MenuItem {
  constructor(
    public title: string,
    public icon: [IconPrefix, IconName],
    public action: (e: any, menu: MenuItem[]) => void,
    public submenu: MenuItem[],
    public data?: any
  ) {}
}

@Component({
  selector: 'app-service-user',
  templateUrl: './service-user.component.html',
  styleUrls: ['./service-user.component.scss'],
  providers: [BsModalService],
})
export class ServiceUserComponent implements OnInit {
  @ViewChild('dropdown') dropdown!: BsDropdownDirective;

  public environment = environment;

  public loading = {
    page: true,
    details: false,
    user: false,
    content: false,
  };

  public error = {
    page: '',
    details: '',
    user: '',
    content: '',
  };

  serviceUser!: ServiceUser;

  bsModalRef!: BsModalRef;

  statuses = Object.values(ServiceUserStatus);
  caseManagers: CaseManagerSummary[] = [];
  view: string = 'timeline';
  summary: string = '';

  interventionMenu: MenuItem[] = [];

  activeMenu: MenuItem[] = this.interventionMenu;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private serviceUserService: ServiceUserService,
    private modalService: BsModalService,
    private caseManagerService: CaseManagerService,
    private referOnService: ReferOnService,
    public interventionService: InterventionService,
    public authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.getInterventions();
  }

  getInterventions() {
    this.loading.content = true;
    forkJoin({
      caseManagers: this.caseManagerService.getAll(),
      serviceUser: this.serviceUserService.get(
        this.route.snapshot.paramMap.get('id') + ''
      ),
      referOnServices: this.referOnService.getAll(),
    }).subscribe(({ caseManagers, serviceUser, referOnServices }) => {
      this.caseManagers = caseManagers;
      this.serviceUser = serviceUser;
      this.loading.page = false;
      this.loading.content = false;

      const referOnMenuItems = referOnServices.map((service) => {
        service.title = service.title.replace(
          /&#(\d+);/g,
          function (match, dec) {
            return String.fromCharCode(dec);
          }
        );
        return {
          title: service.title,
          icon: ['fal', service.icon],
          action: (e) => {
            this.openModal('refer-on', service);
          },
          data: {
            copy: service.copy,
          },
          submenu: [],
        } as MenuItem;
      });

      this.interventionMenu = [
        {
          title: 'First Contact',
          icon: ['fal', 'info-circle'],
          action: (e) => {
            this.openModal('onboarding');
          },
          submenu: [],
        },
        {
          title: 'Call',
          icon: ['fal', 'phone'],
          action: (e) => {
            this.router.navigate(['/call', this.serviceUser.id]);
          },
          submenu: [],
        },
        {
          title: 'Note',
          icon: ['fal', 'edit'],
          action: (e) => {
            this.openModal('note');
          },
          submenu: [],
        },
        {
          title: 'Refer On',
          icon: ['fal', 'sign-out-alt'],
          action: (e, menu: MenuItem[]) => {
            e.stopPropagation();
            this.setMenu(menu);
          },
          submenu: referOnMenuItems,
        },
        {
          title: 'Discharge',
          icon: ['fal', 'door-open'],
          action: (e) => {
            this.openModal('discharge');
          },
          submenu: [],
        },
        {
          title: 'Adviceline Resources',
          icon: ['fal', 'book-alt'],
          action: (e) => {
            this.openModal('resources');
          },
          submenu: [],
        },
        {
          title: 'Self Management Tools',
          icon: ['fal', 'book-alt'],
          action: (e) => {
            this.openModal('resources-self-management');
          },
          submenu: [],
        },
        {
          title: 'Signposting',
          icon: ['fal', 'map-signs'],
          action: (e) => {
            this.openModal('signposting');
          },
          submenu: [],
        },
      ];
    });
  }

  saveServiceUser = (section: 'details' | 'page' | 'user' = 'details') => {
    this.loading[section] = true;
    this.serviceUserService.update(this.serviceUser).subscribe(
      (user) => {
        this.loading.details = false;
      },
      (error) => {
        this.loading.details = false;
        this.error.details = 'Error saving service user details';
      }
    );
  };

  deleteServiceUser = (section: 'details' | 'page' | 'user' = 'details') => {
    const deleteConfirm = prompt(
      "Are you sure you want to delete this service user? If so, please type 'DELETE' in the box below and click 'OK'. Please note this can not be undone",
      ''
    );
    if (deleteConfirm === 'DELETE') {
      this.loading[section] = true;
      this.serviceUserService.delete(this.serviceUser).subscribe(
        (user) => {
          this.router.navigate(['/service-users']);
          this.loading.details = false;
        },
        (error) => {
          this.loading.details = false;
          this.error.details = 'Error deleting service user details';
        }
      );
    }
  };

  setMenu(menu: MenuItem[]) {
    this.activeMenu = menu;
  }

  resetMenu(e: any) {
    e.stopPropagation();
    this.activeMenu = this.interventionMenu;
  }

  remove(intervention: Intervention<any>) {}

  validatedDate(date: string | undefined) {
    if (date) {
      return processZuluDate(date);
    }
    return undefined;
  }

  timeSince(date: any) {
    var seconds = Math.floor((<any>new Date() - <any>new Date(date)) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + ' years';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + ' months';
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + ' days';
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + ' hours';
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes';
    }
    return Math.floor(seconds) + ' seconds';
  }

  openModal(type: string, data?: any) {
    let modalData: ModalOptions = {
      initialState: {
        user: this.serviceUser,
        type: type,
        intervention: {},
      },
      class: 'modal-lg modal-dialog-centered',
    };

    switch (type) {
      case 'note':
        modalData.initialState!.title = 'Create Note';
        modalData.initialState!.intervention =
          new Intervention<InterventionNote>({
            type: InterventionType.Note,
            data: new InterventionNote(''),
          });
        this.bsModalRef = this.modalService.show(NoteComponent, modalData);
        break;

      case 'onboarding':
        modalData.initialState!.title = 'Create First Contact';
        modalData.initialState!.intervention =
          new Intervention<InterventionOnboarding>({
            type: InterventionType.Onboarding,
            data: new InterventionOnboarding({}),
          });
        this.bsModalRef = this.modalService.show(
          OnboardingComponent,
          modalData
        );
        break;

      case 'discharge':
        modalData.initialState!.title = 'Create Discharge';
        modalData.initialState!.intervention =
          new Intervention<InterventionDischarge>({
            type: InterventionType.Discharge,
            data: new InterventionDischarge(),
          });
        this.bsModalRef = this.modalService.show(DischargeComponent, modalData);
        break;

      case 'refer-on':
        const referOnData = data as ReferOn;
        modalData.initialState!.title = referOnData.title
          ? referOnData.title
          : 'Unknown Refer On Service';
        modalData.initialState!.referOnData = referOnData;
        modalData.initialState!.intervention =
          new Intervention<InterventionReferOn>({
            type: InterventionType.ReferOn,
            data: new InterventionReferOn({
              text: '',
              service: referOnData.title,
              title: referOnData.title,
            }),
          });
        this.bsModalRef = this.modalService.show(ReferOnComponent, modalData);
        break;

      case 'signposting':
        modalData.initialState!.title = 'Signposting';
        modalData.initialState!.intervention =
          new Intervention<InterventionSignpost>({
            type: InterventionType.Signposting,
            data: new InterventionSignpost({}),
          });

        this.bsModalRef = this.modalService.show(
          SignpostingComponent,
          modalData
        );
        break;

      case 'resources':
        modalData.initialState!.title = 'Resources';
        modalData.initialState!.intervention =
          new Intervention<InterventionResource>({
            type: InterventionType.Resource,
            data: new InterventionResource({}),
          });

        this.bsModalRef = this.modalService.show(ResourcesComponent, modalData);
        break;
      case 'resources-self-management':
        console.log(
          'preparing data to go into modal for self management resource'
        );
        modalData.initialState!.title = 'Self Management Tools';
        modalData.initialState!.intervention =
          new Intervention<InterventionResource>({
            type: InterventionType.Resource,
            data: new InterventionResource({}),
          });

        this.bsModalRef = this.modalService.show(
          ResourcesSelfManagementComponent,
          modalData
        );
        break;
    }

    this.bsModalRef.content.closeBtnName = 'Close';

    this.bsModalRef.content.onClose.subscribe((success: any) => {
      if (success) {
        this.getInterventions();
      }
    });
  }

  getInterventionGoals(interventionId: number | undefined): Goal[] {
    let interventionGoals: Goal[] = [];
    if (interventionId) {
      interventionGoals = this.serviceUser.goals.filter(
        (g) => g.source === interventionId
      );
    }

    if (interventionGoals && interventionGoals.length > 0) {
      return interventionGoals;
    } else {
      return [];
    }
  }

  loadSummary() {
    this.loading.content = true;
    this.view = 'summary';
    this.serviceUserService.getSummary(this.serviceUser).subscribe(
      (summary) => {
        this.summary = summary;
        this.loading.content = false;
      },
      (error) => {
        this.error.content = 'Unable to load summary.';
        this.loading.content = false;
      }
    );
  }

  loadTimeline() {
    this.view = 'timeline';
  }
}
