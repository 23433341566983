import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { InterventionService } from '@services/intervention.service';

import { ServiceUser } from '@models/service-user';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Intervention, InterventionType } from '@app/models/intervention';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { InterventionNote } from '@models/intervention-note';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit {
  public onClose: EventEmitter<any> = new EventEmitter();

  user!: ServiceUser;
  type!: string;
  title!: string;

  intervention: Intervention<InterventionNote>;
  form!: FormGroup;

  loading = false;

  bsConfig = Object.assign(
    {},
    { containerClass: 'theme-default', dateInputFormat: 'DD/MM/YYYY' }
  );

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private interventionService: InterventionService,
    public bsModalRef: BsModalRef
  ) {
    this.intervention = new Intervention<InterventionNote>({
      type: InterventionType.Note,
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      date: [this.intervention.created, [Validators.required]],
      note: [this.intervention.data.text, [Validators.required]],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  isRequiredField(field: string) {
    const form_field = this.form.get(field);
    if (!form_field?.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator.required;
  }

  submit() {
    this.loading = true;

    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.intervention.serviceUser.id = this.user.id;
      this.intervention.created = Date.now().toString();
      this.intervention.data.text = this.f.note.value;
      this.intervention.author = {
        id: this.authService.currentUserValue.id,
        name: '',
      };
      this.intervention.created = this.f.date.value;
      this.intervention.updated = this.f.date.value;

      this.interventionService.create(this.intervention).subscribe(
        (response) => {
          this.loading = false;
          this.onClose.emit(true);
          this.bsModalRef.hide();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}
