import { Component, Input, OnInit } from '@angular/core';
import { Referrer } from '@app/models/referrer';

@Component({
  selector: 'app-summary-list-referrer',
  templateUrl: './summary-list-referrer.component.html',
  styleUrls: ['./summary-list-referrer.component.scss'],
})
export class SummaryListReferrerComponent implements OnInit {
  @Input() referrer: Referrer = new Referrer();

  constructor() {}

  ngOnInit(): void {}
}
