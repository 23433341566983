import { Signpost } from './signpost';

export class InterventionSignpost {
  ids: number[];
  text: string;
  data: Signpost[];

  constructor(data?: { ids?: number[]; text?: string; data?: Signpost[] }) {
    this.ids = data && data.ids ? data.ids : [];
    this.text = data && data.text ? data.text : '';
    this.data = data && data.data ? data.data : [];
  }
}
