export enum InterventionReferOnTypes {
  kss = 'KSS',
  community = 'Community',
  physicalActivity = 'Physical Activity',
  peerSupport = 'Peer Support',
  peerSupportCovid = 'Peer Support Long Covid',
  fundraising = 'Fundraising',
  volunteering = 'Volunteering'
}
export class InterventionReferOn {
  text: string;
  service: InterventionReferOnTypes;
  title: string;

  constructor(data?: any) {
    this.text = data && data.text ? data.text : '';
    this.service = data && data.service ? data.service : '';
    this.title = data && data.title ? data.title : '';
  }
}
