import { Validators } from '@angular/forms';
import {
  optionsYesNo,
  optionsYesNoNotAppropriate,
} from '@app/helpers/form-input-data';
import {
  QuestionCheckbox,
  QuestionDate,
  QuestionTextarea,
  Question,
  QuestionDropdown,
  QuestionHTML,
} from '@app/models/form';

export const dischargeForm: Question<any>[] = [
  new QuestionDropdown({
    key: 'consentShareDischargeInformation',
    path: 'data.consentShareDischargeInformation',
    label:
      'Consent for CHSS discharge information to be shared with referring Clinician',
    required: true,
    options: optionsYesNo,
  }),
  new QuestionDropdown({
    key: 'consentFutureEvaluation',
    path: 'data.consentFutureEvaluation',
    label: 'Consent for future evaluation',
    options: optionsYesNo,
  }),
  new QuestionDate({
    key: 'dischargeEvaluationDate',
    path: 'data.dischargeEvaluationDate',
    label: 'Discharge evaluation date',
    required: true,
    displayConditions: [
      {
        key: 'consentFutureEvaluation',
        values: ['Yes'],
      },
    ],
  }),
  new QuestionDropdown({
    key: 'consentFutureStories',
    path: 'data.consentFutureStories',
    label: 'Consent for future stories',
    options: optionsYesNoNotAppropriate,
  }),
  new QuestionTextarea({
    key: 'consentFutureEvaluationNotAppropriate',
    path: 'data.consentFutureEvaluationNotAppropriate',
    label: 'Reason for not appropriate',
    validators: [Validators.maxLength(200)],
    displayConditions: [
      {
        key: 'consentFutureStories',
        values: ['Not Appropriate'],
      },
    ],
  }),
  new QuestionHTML({
    key: 'dischargeLetterLink',
    label:
      '<a href="https://chsscloud.sharepoint.com/:w:/r/sites/Services/AdviceLine/Advice%20Line%20Nurses/H2H%20REFERRALS/FINAL%20Letter%20Templates/Service%20User%20-%20Discharge%20Letter%20v2.docx?d=w50eee12fb3c44abd8414ac28fb9ebbec&csf=1&web=1&e=QmC0ZG" target="_blank">Discharge letter template</a>',
    payloadIgnore: true,
  }),
  new QuestionDropdown({
    key: 'dischargeLetterSent',
    path: 'data.dischargeLetterSent',
    label: 'Discharge letter sent',
    options: optionsYesNo,
  }),

  new QuestionTextarea({
    key: 'dischargeSummary',
    path: 'data.dischargeSummary',
    label: 'Discharge summary',
    validators: [Validators.maxLength(200)],
  }),
];
