import { Component, Input, OnInit } from '@angular/core';
import { Goal } from '@app/models/goal';
import { Intervention } from '@app/models/intervention';
import { ReferralLongCovid } from '@app/models/intervention-referral-long-covid';

@Component({
  selector: 'app-summary-referral-long-covid',
  templateUrl: './summary-referral-long-covid.component.html',
  styleUrls: ['./summary-referral-long-covid.component.scss'],
})
export class SummaryReferralLongCovidComponent implements OnInit {
  @Input() intervention!: Intervention<ReferralLongCovid>;
  @Input() goals: Goal[] = [];

  constructor() {}

  ngOnInit(): void {}
}
