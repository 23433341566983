export const patterns = {
  phone: '[- +()0-9]+',
  number: '^[0-9]*$',
  bloodPressure: '^[0-9]{1,3}\\/[0-9]{1,3}$',
  email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$',
};

export const processZuluDate = (date: string): string => {
  return date.replace('+00:00', '');
};
