import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxLocalStorageModule } from 'ngx-localstorage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Auth
import { AuthenticationService } from '@services/authentication/authentication.service';
import { JwtInterceptor } from '@auth/jwt.interceptor';

// Library components
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ComboDatepickerModule } from 'ngx-combo-datepicker';
import { ClipboardModule } from 'ngx-clipboard';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

// Font Awesome
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faPlus,
  faCaretUp,
  faExclamationCircle,
  faUser,
  faCircle,
  faTimesCircle,
  faSquare,
  faCheckSquare,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faExclamationTriangle,
  faStopwatch,
  faCalendarAlt,
  faEdit,
  faComment,
  faHeart,
  faPhone,
  faAddressCard,
  faSmile,
  faMeh,
  faLaughBeam,
  faFrown,
  faSadTear,
  faMapSigns,
  faDoorOpen,
  faBookAlt,
  faLungsVirus,
  faLungs,
  faPoundSign,
  faNewspaper,
  faHandHoldingHeart,
  faUsers,
  faRunning,
  faUserFriends,
  faClock,
} from '@fortawesome/pro-light-svg-icons';
import {
  faListUl,
  faSignOutAlt,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons';

// Pipes
import { LinebreaksPipe } from '@pipes/linesbreaks.pipe';

// Components
import { LoginComponent } from '@components/login/login.component';
import { LogoutComponent } from '@components/logout/logout.component';
import { HeaderComponent } from '@components/header/header.component';
import { GoalsComponent } from '@components/goals/goals.component';
import { MultiSelectComponent } from '@components/multi-select/multi-select.component';
import { ServiceUserComponent } from '@app/components/service-user/service-user.component';
import { ServiceUsersComponent } from '@app/components/service-users/service-users.component';
import { FilterComponent } from '@components/filter/filter.component';
import { FilterSelectComponent } from '@components/filter-select/filter-select.component';
import { FilterGroupComponent } from '@components/filter-group/filter-group.component';
import { FormNavigationComponent } from '@components/form-navigation/form-navigation.component';
import { ReferOnComponent } from '@components/refer-on/refer-on.component';
import { FormPersonComponent } from '@components/form-person/form-person.component';
import { CallComponent } from '@components/call/call.component';
import { NoteComponent } from '@components/note/note.component';
import { SummaryReferOnComponent } from '@components/summary-refer-on/summary-refer-on.component';
import { SummaryNoteComponent } from '@components/summary-note/summary-note.component';
import { SummaryReferralCardiacComponent } from '@components/summary-referral-cardiac/summary-referral-cardiac.component';
import { RatingComponent } from '@components/rating/rating.component';
import { FormDisplayComponent } from './components/form-display/form-display.component';
import { ButtonSelectComponent } from '@components/button-select/button-select.component';
import { FormLongCovidComponent } from '@components/form-long-covid/form-long-covid.component';
import { FormProgressComponent } from '@components/form-progress/form-progress.component';
import { ReferralsComponent } from '@components/referrals/referrals.component';
import { SignpostingComponent } from '@components/signposting/signposting.component';
import { FormRespiratoryComponent } from './components/form-respiratory/form-respiratory.component';
import { FormCardiacComponent } from './components/form-cardiac/form-cardiac.component';
import { DobComponent } from '@components/dob/dob.component';
import { TimeSelectComponent } from '@components/time-select/time-select.component';
import { MoodSelectComponent } from '@components/mood-select/mood-select.component';
import { SummarySignpostingComponent } from '@components/summary-signposting/summary-signposting.component';
import { FormServiceUserComponent } from '@components/form-service-user/form-service-user.component';
import { SummaryCallComponent } from '@components/summary-call/summary-call.component';
import { ReferOnSelectComponent } from '@components/refer-on-select/refer-on-select.component';
import { ResourcesComponent } from '@components/resources/resources.component';
import { SummaryReferralRespiratoryComponent } from './components/summary-referral-respiratory/summary-referral-respiratory.component';
import { SummaryListReferrerComponent } from './components/summary-list-referrer/summary-list-referrer.component';
import { SummaryListHistoryComponent } from './components/summary-list-history/summary-list-history.component';
import { SummaryListReferralAdditionalComponent } from './components/summary-list-referral-additional/summary-list-referral-additional.component';
import { SummaryReferralLongCovidComponent } from './components/summary-referral-long-covid/summary-referral-long-covid.component';
import { ResourcesSelfManagementComponent } from './components/resources-self-management/resources-self-management.component';
import { UnauthorisedComponent } from './components/unauthorised/unauthorised.component';
import { DischargeComponent } from './components/discharge/discharge.component';
import { FormComponent } from './components/form/form.component';
import { SummaryDischargeComponent } from './components/summary-discharge/summary-discharge.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { SummaryOnboardingComponent } from './components/summary-onboarding/summary-onboarding.component';
import { FormIcuComponent } from './components/form-icu/form-icu.component';
import { SummaryReferralIcuComponent } from './components/summary-referral-icu/summary-referral-icu.component';
import { TopicsComponent } from './components/topics/topics.component';
import { ReferralWelcomeComponent } from './components/referral-welcome/referral-welcome.component';
import { ReferralThankYouComponent } from './components/referral-thank-you/referral-thank-you.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    HeaderComponent,
    GoalsComponent,
    MultiSelectComponent,
    ServiceUsersComponent,
    FilterComponent,
    FilterSelectComponent,
    FilterGroupComponent,
    FormNavigationComponent,
    ServiceUserComponent,
    ReferOnComponent,
    FormPersonComponent,
    CallComponent,
    NoteComponent,
    SummaryReferOnComponent,
    SummaryNoteComponent,
    SummaryReferralCardiacComponent,
    RatingComponent,
    ButtonSelectComponent,
    FormLongCovidComponent,
    FormProgressComponent,
    ReferralsComponent,
    FormDisplayComponent,
    SignpostingComponent,
    FormRespiratoryComponent,
    FormCardiacComponent,
    DobComponent,
    TimeSelectComponent,
    MoodSelectComponent,
    SummarySignpostingComponent,
    FormServiceUserComponent,
    LinebreaksPipe,
    SummaryCallComponent,
    ReferOnSelectComponent,
    ResourcesComponent,
    SummaryReferralRespiratoryComponent,
    SummaryListReferrerComponent,
    SummaryListHistoryComponent,
    SummaryListReferralAdditionalComponent,
    SummaryReferralLongCovidComponent,
    ResourcesSelfManagementComponent,
    UnauthorisedComponent,
    DischargeComponent,
    FormComponent,
    SummaryDischargeComponent,
    OnboardingComponent,
    SummaryOnboardingComponent,
    FormIcuComponent,
    SummaryReferralIcuComponent,
    TopicsComponent,
    ReferralWelcomeComponent,
    ReferralThankYouComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    ButtonsModule.forRoot(),
    ProgressbarModule.forRoot(),
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    ComboDatepickerModule,
    ClipboardModule,
    NgxLocalStorageModule.forRoot(),
    TimepickerModule.forRoot(),
  ],
  providers: [
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(
      faCheck,
      faChevronLeft,
      faChevronRight,
      faPlus,
      faUser,
      faCircle,
      faSearch,
      faTimesCircle,
      faHeart,
      faPhone,
      faEdit,
      faComment,
      faSignOutAlt,
      faAddressCard,
      faSmile,
      faMeh,
      faLaughBeam,
      faFrown,
      faSadTear,
      faExclamationTriangle,
      faListUl,
      faStopwatch,
      faCaretUp,
      faExclamationCircle,
      faCalendarAlt,
      faMapSigns,
      faDoorOpen,
      faSquare,
      faCheckSquare,
      faBookAlt,
      faLungsVirus,
      faLungs,
      faPoundSign,
      faNewspaper,
      faHandHoldingHeart,
      faUsers,
      faUserFriends,
      faRunning,
      faClock,
      faInfoCircle,
      faMinusCircle
    );
  }
}
