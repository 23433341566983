import { Component, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { otherValidator } from '@validators/other.validator';

import { PersonMultiAddress } from '@app/models/person';
import { AuthenticationService } from '@app/services/authentication/authentication.service';

import {
  titleOptions,
  genderOptions,
  maritalStatusOptions,
  livesWithOptions,
  employmentStatusOptions,
  sexualityOptions,
  ethnicityOptions
} from '@app/helpers/form-input-data';

@Component({
  selector: 'app-form-person',
  templateUrl: './form-person.component.html',
  styleUrls: ['./form-person.component.scss'],
})
export class FormPersonComponent implements OnInit {
  @Input() person!: PersonMultiAddress;
  @Input() loading: boolean = false;
  @Input() error: string = '';
  @Input() save!: () => void;

  personForm!: FormGroup;

  bsConfig = Object.assign(
    {},
    { containerClass: 'theme-default', dateInputFormat: 'DD/MM/YYYY' }
  );

  titleOptions = titleOptions;
  genderOptions = genderOptions;
  maritalStatusOptions = maritalStatusOptions;
  livesWithOptions = livesWithOptions;
  employmentStatusOptions = employmentStatusOptions;
  sexualityOptions = sexualityOptions;
  ethnicityOptions = ethnicityOptions;

  displayTitleOther = false;

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.personForm = this.formBuilder.group({
      title: [this.person.title, [Validators.required]],
      titleOther: [
        this.person.titleOther,
        [otherValidator(() => this.personForm.controls['title'].value)],
      ],
      firstName: [this.person.firstName, [Validators.required]],
      lastName: [this.person.lastName, [Validators.required]],
      addressNumber: [this.person.address[0].address1, [Validators.required]],
      addressStreet: [this.person.address[0].address2, [Validators.required]],
      addressTownCity: [this.person.address[0].city, [Validators.required]],
      addressPostcode: [this.person.address[0].postcode, [Validators.required]],
      telephone: [this.person.phone, [Validators.required]],
      telephone2: [this.person.mobile],
      email: [this.person.email, [Validators.pattern(this.emailPattern)]],
      dob: [new Date(this.person.dob), [Validators.required]],
      gender: [this.person.gender, [Validators.required]],
      maritalStatus: [this.person.maritalStatus],
      livesWith: [this.person.livesWith, [Validators.required]],
      employmentStatus: [this.person.employmentStatus],
      sexuality: [this.person.sexuality],
      ethnicity: [this.person.ethnicity],
      ethnicityOther: [this.person.ethnicityOther]
    });

    this.personForm.controls['title'].valueChanges.subscribe((value) => {
      this.person.title = value;
      if (value.toLowerCase().includes('other')) {
        this.displayTitleOther = true;
      } else {
        this.displayTitleOther = false;
      }
      this.personForm.controls['titleOther'].updateValueAndValidity();
    });

    if (this.person.title === 'Other') this.displayTitleOther = true;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.personForm.controls;
  }

  isRequiredField(field: string) {
    const form_field = this.personForm.get(field);
    if (!form_field?.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator.required;
  }

  submit() {
    this.person.title = this.personForm.controls.title.value;
    this.person.titleOther = this.personForm.controls.titleOther.value;
    this.person.firstName = this.personForm.controls.firstName.value;
    this.person.lastName = this.personForm.controls.lastName.value;
    this.person.address[this.person.address.length - 1].address1 =
      this.personForm.controls.addressNumber.value;
    this.person.address[this.person.address.length - 1].address2 =
      this.personForm.controls.addressStreet.value;
    this.person.address[this.person.address.length - 1].city =
      this.personForm.controls.addressTownCity.value;
    this.person.address[this.person.address.length - 1].postcode =
      this.personForm.controls.addressPostcode.value;
    this.person.mobile = this.personForm.controls.telephone.value;
    this.person.phone = this.personForm.controls.telephone2.value;
    this.person.email = this.personForm.controls.email.value;
    this.person.dob = this.personForm.controls.dob.value;
    this.person.gender = this.personForm.controls.gender.value;
    this.person.maritalStatus = this.personForm.controls.maritalStatus.value;
    this.person.livesWith = this.personForm.controls.livesWith.value;
    this.person.employmentStatus = this.personForm.controls.employmentStatus.value;
    this.person.sexuality = this.personForm.controls.sexuality.value;
    this.person.ethnicity = this.personForm.controls.ethnicity.value;
    this.person.ethnicityOther = this.personForm.controls.ethnicityOther.value;
    this.save();
  }
}
