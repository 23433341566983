import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-button-select',
  templateUrl: './button-select.component.html',
  styleUrls: ['./button-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ButtonSelectComponent,
    },
  ],
})
export class ButtonSelectComponent implements ControlValueAccessor {
  @Input() options!: any[];
  @Input() classes: string = '';

  selected: string = '';
  onChange = (selected: any) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor() {}

  update() {
    this.markAsTouched();
    this.onChange(this.selected);
  }

  writeValue(selected: string) {
    this.selected = selected;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
