import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public filter: {
    key: string,
    data: any[]
  }[] = [];

  constructor() { }

  updateFilter(filter: any) {
    if(this.filter[filter.key]) {
      this.filter[filter.key] = filter.data;
    } else {
      this.filter.push({
        key: filter.key,
        data: filter.data
      })
    }
  }

  resetFilter() {
    this.filter = [];
  }
}
