import {
  Component,
  TemplateRef,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GoalService } from '@app/services/goal.service';
import { Goal, GoalNote, GoalStatus, GoalType } from '@models/goal';
import { NgSelectComponent } from '@ng-select/ng-select';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';
@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GoalsComponent,
    },
  ],
})
export class GoalsComponent implements ControlValueAccessor {
  @ViewChild('goalSelect') goalSelect!: NgSelectComponent;
  @ViewChild('noteEditor') noteEditor!: ElementRef;

  @Input() userId: number | undefined;
  @Input() source!: number;
  @Input() goals: Goal[] = [];
  @Input() showStatus: boolean = true;
  @Input() showTitle: boolean = true;

  @Output() goalsChange = new EventEmitter();

  goalForm!: FormGroup;

  note: GoalNote = new GoalNote();
  goalOptions = Object.values(GoalType);
  statusOptions = Object.values(GoalStatus);

  goal!: Goal;

  loading = {
    goals: true,
    createGoal: false,
  };

  edit: boolean = false;

  onChange = (date: any) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  modalRef?: BsModalRef;

  modalData: ModalOptions = {
    class: 'modal-dialog-centered',
  };

  constructor(
    private goalService: GoalService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading.goals = false;
    this.update();
    this.goal = new Goal();
    this.goalForm = this.formBuilder.group({
      type: [this.goal.type, [Validators.required]],
      description: [this.goal.description, [Validators.required]],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.goalForm.controls;
  }

  isRequiredField(field: string) {
    const form_field = this.goalForm.get(field);
    if (!form_field?.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator.required;
  }

  newGoal(template: TemplateRef<any>) {
    this.goalForm.reset();
    this.edit = false;
    this.modalRef = this.modalService.show(template, this.modalData);
  }

  editGoal(goal: any, e: any, template: TemplateRef<any>) {
    e.preventDefault();
    this.edit = true;
    this.goal = goal;
    this.goalForm.patchValue({
      type: goal.type,
      description: goal.description,
    });
    this.modalRef = this.modalService.show(template, this.modalData);
  }

  removeGoal(goal: any, e: any) {
    e.preventDefault();

    _.remove(this.goals, goal);

    this.update();
  }

  setStatus(goal: any, status: string, e: any) {
    e.preventDefault();
    goal.status = status;
  }

  update() {
    this.onChange(this.goals);
    this.goalsChange.emit(this.goals);
  }

  submit() {
    this.goalForm.markAllAsTouched();

    if (this.goalForm.valid) {
      const goalData = this.goalForm.value;

      if (this.edit) {
        this.goal.type = goalData.type;
        this.goal.description = goalData.description;
      } else {
        const goal = new Goal({
          created: new Date(),
          authorId: 0,
          type: goalData.type,
          description: goalData.description,
          notes: [
            new GoalNote({
              created: new Date(),
              authorId: 0,
              text: '',
              callId: 0,
            }),
          ],
        });

        if (this.source) {
          goal.source = this.source;
        }

        this.goals.push(goal);
      }
      this.modalRef?.hide();
      this.update();
    }
  }

  toggle(goal: Goal, toggle: boolean = true) {
    if (toggle) goal.isCollapsed = !goal.isCollapsed;
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  autoGrow(e: any) {
    e.target.style.height = '0px';
    e.target.style.height = e.target.scrollHeight + 25 + 'px';
  }

  writeValue(goals: any) {
    this.goals = goals;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  goalNoteChanging() {
    this.update();
  }
}
