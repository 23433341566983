<app-header></app-header>

<section style="padding-bottom: 20px">
  <div class="container-fluid" style="max-width: 1440px">
    <div
      *ngIf="loading.page"
      class="spinner-border text-primary m-4"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="row" *ngIf="!loading.page">
      <div class="col-md-4 col-sm-12 pe-sm-0 pe-md-4">
        <tabset #staticTabs>
          <tab heading="Overview">
            <div class="card w-100 mb-4" style="border-top: none">
              <div class="card-body p-4">
                <div class="mb-3">
                  <label for="status" class="form-label w-100"> Status </label>
                  <ng-select
                    id="status"
                    [(ngModel)]="serviceUser.status"
                    [items]="statuses"
                    [searchable]="false"
                    [clearable]="false"
                    [placeholder]="'Status'"
                  >
                  </ng-select>
                </div>

                <div class="mb-3">
                  <label for="caseManager" class="form-label w-100">
                    Case Manager
                  </label>
                  <ng-select
                    id="caseManager"
                    [(ngModel)]="serviceUser.caseManager!.id"
                    [items]="caseManagers"
                    [searchable]="false"
                    [clearable]="false"
                    [placeholder]="'Case manager not set'"
                    bindLabel="name"
                    bindValue="id"
                  >
                  </ng-select>
                </div>

                <div class="mt-4">
                  <button
                    [disabled]="!authService.userHasPermission('edit')"
                    class="btn btn-primary me-2"
                    (click)="saveServiceUser('details')"
                  >
                    Save
                  </button>
                  <button
                    *ngIf="authService.userHasPermission('assign_case_manager')"
                    class="btn btn-outline-danger"
                    (click)="deleteServiceUser('details')"
                  >
                    Delete
                  </button>
                  <div
                    *ngIf="loading.details"
                    class="spinner-border text-primary float-end mt-1"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div
                    *ngIf="error.details"
                    class="text-warning float-end mt-1"
                    role="status"
                  >
                    <span class="visually-hidden">{{ error.details }}</span>
                  </div>
                </div>

                <h6 class="text-primary mt-5">Call stats</h6>
                <div class="key-pair">
                  <span class="key">Calls made:</span>
                  {{
                    interventionService.interventionCallCount(
                      serviceUser.interventions
                    )
                  }}
                </div>
                <div class="key-pair">
                  <span class="key">Unanswered:</span>
                  {{
                    interventionService.interventionUnansweredCallCount(
                      serviceUser.interventions
                    )
                  }}
                </div>
                <div class="key-pair">
                  <span class="key">Consecutively Unanswered:</span>
                  {{
                    interventionService.interventionUnansweredConsecutiveCallRecentCount(
                      serviceUser.interventions
                    )
                  }}

                  <div
                    *ngIf="
                      interventionService.interventionUnansweredConsecutiveCallRecentCount(
                        serviceUser.interventions
                      ) > 2
                    "
                  >
                    <a
                      href="https://chsscloud.sharepoint.com/:w:/r/sites/Services/AdviceLine/Advice%20Line%20Nurses/H2H%20REFERRALS/FINAL%20Letter%20Templates/Service%20User%20-%20New%20Failed%20Contact%20Letter%20.docx?d=w0624402d191142c38cab5dc08b79ed9e&csf=1&web=1&e=DrIDVw"
                      target="_blank"
                      >Failed contact letter template</a
                    >
                  </div>
                </div>
                <div class="key-pair">
                  <span class="key">Next call date:</span>
                  <span [ngClass]="serviceUser.nextCallClass">{{ serviceUser.nextCall == 'N/A' ? 'N/A' : serviceUser.nextCall | date }}</span>
                </div>
              </div>
            </div>
          </tab>

          <tab heading="Details">
            <div class="card w-100 mb-4" style="border-top: none">
              <div class="card-body p-4">
                <app-form-person
                  [person]="serviceUser.personalDetails"
                  [save]="saveServiceUser"
                  [loading]="this.loading.details"
                  [error]="this.error.details"
                ></app-form-person>
              </div>
            </div>
          </tab>
        </tabset>
      </div>
      <div class="col-md-8 col-sm-12">
        <h3 class="mb-4">
          {{ serviceUser.personalDetails.firstName }}
          {{ serviceUser.personalDetails.lastName }}

          <div
            class="btn-group float-end"
            [autoClose]="true"
            [insideClick]="false"
            dropdown
            *ngIf="authService.userHasPermission('edit')"
          >
            <button
              id="button-basic"
              dropdownToggle
              type="button"
              class="btn btn-primary dropdown-toggle"
              aria-controls="dropdown-basic"
              (click)="this.resetMenu($event)"
              [disabled]="view === 'summary'"
            >
              <fa-icon
                [icon]="['fas', 'plus']"
                class="text-white me-2"
              ></fa-icon
              >New Intervention
            </button>
            <ul
              id="dropdown-basic"
              *dropdownMenu
              class="py-0 dropdown-menu dropdown-menu-right dropdown-menu-end"
              role="menu"
              aria-labelledby="button-basic"
              style="width: 350px"
            >
              <li class="menuitem" role="menuitem">
                <div class="row m-0 w-100">
                  <ng-container *ngFor="let m of activeMenu">
                    <div class="col-4" (click)="m.action($event, m.submenu)">
                      <div class="text-center">
                        <fa-icon
                          [icon]="[m.icon[0], m.icon[1]]"
                          class="text-primary"
                        ></fa-icon>
                        <p class="form-text m-0">{{ m.title }}</p>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <button
                  *ngIf="activeMenu !== interventionMenu"
                  class="btn btn-light text-primary btn-sm w-100 py-3"
                  style="
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-top: 1px solid rgba(0, 0, 0, 0.125);
                  "
                  (click)="this.resetMenu($event)"
                >
                  Back
                </button>
              </li>
            </ul>
          </div>
          <div class="btn-group float-end me-3" role="group">
            <button
              type="button"
              class="btn btn-outline-primary"
              [class.btn-primary]="view === 'timeline'"
              [class.text-white]="view === 'timeline'"
              (click)="loadTimeline()"
            >
              Timeline
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              [class.btn-primary]="view === 'summary'"
              [class.text-white]="view === 'summary'"
              (click)="loadSummary()"
            >
              Summary
            </button>
          </div>
        </h3>

        <div
          *ngIf="loading.page"
          class="spinner-border text-primary m-4"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>

        <ng-container *ngIf="view === 'summary'">
          <div
            *ngIf="loading.content"
            class="spinner-border text-primary m-4"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <div *ngIf="!loading.content" [innerHTML]="summary"></div>
        </ng-container>

        <ng-container *ngIf="!loading.page && view === 'timeline'">
          <p
            class="alert alert-warning"
            *ngIf="!authService.userHasPermission('edit')"
          >
            You do not have permission to edit a service user. You are limited
            to viewing the service user only. To add interventions or save
            service user details, please request 'edit' privileges.
          </p>
          <div
            *ngIf="loading.content"
            class="d-flex justify-content-center mb-3 mt-2 text-muted"
          >
            <div
              class="spinner-border text-muted me-2"
              role="status"
              style="height: 20px; width: 20px"
            >
              <span class="visually-hidden">Refreshing timeline</span>
            </div>
            <div>Refreshing timeline</div>
          </div>
          <p
            class="text-center mt-5 text-muted"
            *ngIf="
              !serviceUser.interventions ||
              serviceUser.interventions.length === 0
            "
          >
            No timeline data yet. Click 'add intervention' to get started.
          </p>

          <ng-container *ngFor="let intervention of serviceUser.interventions">
            <ng-container [ngSwitch]="intervention.type">
              <app-summary-note
                *ngSwitchCase="'Note'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
              ></app-summary-note>

              <app-summary-call
                *ngSwitchCase="'Call'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
              >
              </app-summary-call>

              <app-summary-onboarding
                *ngSwitchCase="'Onboarding'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
              >
              </app-summary-onboarding>

              <app-summary-refer-on
                *ngSwitchCase="'ReferOn'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
              ></app-summary-refer-on>

              <app-summary-signposting
                *ngSwitchCase="'Resource'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
                title="Resources"
              ></app-summary-signposting>

              <app-summary-signposting
                *ngSwitchCase="'Signposting'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
              ></app-summary-signposting>

              <app-summary-referral-cardiac
                *ngSwitchCase="'ReferralCardiac'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
                [goals]="getInterventionGoals(intervention.id)"
              ></app-summary-referral-cardiac>

              <app-summary-referral-respiratory
                *ngSwitchCase="'ReferralRespiratory'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
                [goals]="getInterventionGoals(intervention.id)"
              ></app-summary-referral-respiratory>

              <app-summary-referral-icu
                *ngSwitchCase="'ReferralIcu'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
                [goals]="getInterventionGoals(intervention.id)"
              ></app-summary-referral-icu>

              <app-summary-referral-icu
                *ngSwitchCase="'ReferralICU'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
                [goals]="getInterventionGoals(intervention.id)"
              ></app-summary-referral-icu>

              <app-summary-referral-long-covid
                *ngSwitchCase="'ReferralLongCovid'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
                [goals]="getInterventionGoals(intervention.id)"
              ></app-summary-referral-long-covid>

              <app-summary-discharge
                *ngSwitchCase="'Discharge'"
                [intervention]="
                  interventionService.getTypedIntervention(intervention)
                "
              ></app-summary-discharge>

              <ng-container *ngSwitchDefault>
                <div class="card">
                  <div class="card-body p-4">
                    Could not determine type: {{ intervention.type }}
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div class="form-text px-2 mt-2 mb-4 fw-lighter">
              <ng-container
                *ngIf="intervention.author && intervention.author.name"
                >Author: {{ intervention.author.name }}
              </ng-container>

              <span class="float-end ms-4"
                >{{ validatedDate(intervention.updated) | date }} -
                {{ timeSince(intervention.updated) }}
              </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</section>
