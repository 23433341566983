import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@auth/auth.guard';

import { LoginComponent } from '@components/login/login.component';
import { LogoutComponent } from '@components/logout/logout.component';
import { ServiceUsersComponent } from '@app/components/service-users/service-users.component';
import { ServiceUserComponent } from '@app/components/service-user/service-user.component';
import { CallComponent } from '@components/call/call.component';
import { FormLongCovidComponent } from '@components/form-long-covid/form-long-covid.component';
import { ReferralsComponent } from '@components/referrals/referrals.component';
import { FormRespiratoryComponent } from './components/form-respiratory/form-respiratory.component';
import { FormCardiacComponent } from './components/form-cardiac/form-cardiac.component';
import { FormServiceUserComponent } from './components/form-service-user/form-service-user.component';
import { UserRoles } from './models/user';
import { UnauthorisedComponent } from './components/unauthorised/unauthorised.component';
import { FormIcuComponent } from './components/form-icu/form-icu.component';
import { ReferralWelcomeComponent } from './components/referral-welcome/referral-welcome.component';
import { ReferralThankYouComponent } from './components/referral-thank-you/referral-thank-you.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'unauthorised',
    component: UnauthorisedComponent,
  },
  {
    path: 'service-users',
    component: ServiceUsersComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [
        UserRoles.case_manager,
        UserRoles.case_manager_manager,
        UserRoles.case_viewer,
      ],
    },
  },
  {
    path: 'service-users/new',
    component: FormServiceUserComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [
        UserRoles.case_manager,
        UserRoles.case_manager_manager,
        UserRoles.case_viewer,
      ],
    },
  },
  {
    path: 'service-users/:id',
    component: ServiceUserComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [
        UserRoles.case_manager,
        UserRoles.case_manager_manager,
        UserRoles.case_viewer,
      ],
    },
  },
  {
    path: 'call/:id',
    component: CallComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [
        UserRoles.case_manager,
        UserRoles.case_manager_manager,
        UserRoles.case_viewer,
      ],
    },
  },
  {
    path: 'referrals',
    component: ReferralsComponent,

    children: [
      {
        path: '',
        component: ReferralWelcomeComponent,
      },

      {
        path: 'covid',
        component: FormLongCovidComponent,
      },
      {
        path: 'cardiac',
        component: FormCardiacComponent,
      },
      {
        path: 'respiratory',
        component: FormRespiratoryComponent,
      },
      {
        path: 'icu',
        component: FormIcuComponent,
      },
      {
        path: 'thank-you',
        component: ReferralThankYouComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'service-users' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
