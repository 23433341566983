import { Component, Input, OnInit } from '@angular/core';
import { Intervention } from '@app/models/intervention';
import { InterventionNote } from '@app/models/intervention-note';

@Component({
  selector: 'app-summary-note',
  templateUrl: './summary-note.component.html',
  styleUrls: ['./summary-note.component.scss'],
})
export class SummaryNoteComponent implements OnInit {
  @Input() intervention!: Intervention<InterventionNote>;

  constructor() {}

  ngOnInit(): void {}
}
