import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceUserSummary, ServiceUser } from '@models/service-user';
import { PaginatedResponse } from '@models/paginatedResponse';
import { environment } from '@env/environment';
import { InterventionService } from './intervention.service';
import { Filter } from '@app/models/filter';

interface ServiceUserSummaryText {
  html: string;
}

@Injectable({
  providedIn: 'root',
})
export class ServiceUserService {
  constructor(
    private http: HttpClient,
    private interventionService: InterventionService
  ) {}

  public getAll(
    pageSize: number,
    pageNumber: number,
    filters: Filter[],
    searchTerm: string
  ): Observable<PaginatedResponse<ServiceUserSummary>> {
    const filter = filters.map((f) => {
      let filterString = '';
      if (f.type === 'multi' && f.selectedIds.length > 0) {
        f.selectedIds.map((id) => {
          filterString += '&' + f.name + '[]=' + id + '';
        });
      } else if (f.type === 'single' && f.selectedIds) {
        filterString +=
          '&' + f.name + '=' + encodeURIComponent(String(f.selectedIds));
      } else {
        filterString =
          f.selectedIds && f.selectedIds.length > 0
            ? '&' + f.name + '=' + f.selectedIds + ''
            : '';
      }
      return filterString;
    });

    let searchString = '';
    if (searchTerm && searchTerm !== '') {
      searchString =
        filter.length === 0 ? '?text=' + searchTerm : '&text=' + searchTerm;
    }

    return this.http
      .get<PaginatedResponse<ServiceUserSummary>>(
        environment.apiUrl +
          '/service-users' +
          '?pageSize=' +
          pageSize +
          '&pageIndex=' +
          pageNumber +
          filter.join('') +
          searchString
      )
      .pipe(
        // Adapt each item in the raw data array
        map((data: any) => {
          data.result = data.result.map(
            (user: ServiceUserSummary) => new ServiceUserSummary(user)
          );

          return data;
        })
      );
  }

  public get(id: string): Observable<ServiceUser> {
    return this.http
      .get<ServiceUser>(environment.apiUrl + '/service-users/' + id)
      .pipe(
        map((data: ServiceUser) => {
          if (!data.interventions) {
            data.interventions = [];
          }

          data.interventions.map((intervention) => {
            intervention.isCollapsed = true;
            return this.interventionService.getTypedIntervention(intervention);
          });

          return data;
        })
      );
  }

  public getSummary(serviceUser: ServiceUser): Observable<string> {
    return this.http
      .get<ServiceUserSummaryText>(
        environment.apiUrl + '/service-users/summary/' + serviceUser.id
      )
      .pipe(
        map((summary) => {
          return summary.html;
        })
      );
  }

  public update(serviceUser: ServiceUser): Observable<ServiceUser> {
    return this.http.put<ServiceUser>(
      environment.apiUrl + '/service-users/' + serviceUser.id,
      serviceUser
    );
  }

  public create(serviceUser: ServiceUser): Observable<ServiceUser> {
    return this.http.post<ServiceUser>(
      environment.apiUrl + '/service-users/',
      serviceUser
    );
  }

  public delete(serviceUser: ServiceUser): Observable<any> {
    //return of(serviceUserSeed).pipe(delay(500));

    return this.http.delete<any>(
      environment.apiUrl + '/service-users/' + serviceUser.id
    );
  }
}
