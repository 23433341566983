<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{ title }}</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    (click)="bsModalRef.hide()"
    aria-label="Close"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>

<div class="modal-body">
  <ng-container *ngIf="!loading.page">
    <p class="alert alert-danger" *ngIf="error.page">{{ error.page }}</p>

    <app-refer-on-select
      [items]="resources"
      (update)="update($event)"
    ></app-refer-on-select>

    <div class="input-group mt-3">
      <textarea
        id="note"
        [(ngModel)]="intervention.data.text"
        placeholder="Optional note"
        class="form-control"
        rows="5"
      ></textarea>
    </div>
  </ng-container>
  <div
    *ngIf="loading.page"
    class="spinner-border text-primary me-2"
    role="status"
  >
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div class="modal-footer">
  <div
    *ngIf="loading.create"
    class="spinner-border text-primary me-2"
    role="status"
  >
    <span class="visually-hidden">Loading...</span>
  </div>
  <div *ngIf="error.create" class="text-danger me-2" role="status">
    {{ error.create }}
  </div>
  <button
    class="btn btn-primary"
    (click)="submit()"
    [disabled]="selected.length === 0"
  >
    Add to user timeline
  </button>
</div>
