import { Component, TemplateRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimeSelectComponent,
    },
  ],
})
export class TimeSelectComponent implements ControlValueAccessor {
  @Input() modalTitle: string = 'Set time';
  @Input() buttonTitle: string = 'Set time';
  @Input() editDate: boolean = false;

  time!: Date;

  onChange = (time: any) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  modalRef?: BsModalRef;

  modalData: ModalOptions = {
    class: 'modal-dialog-centered',
  };

  constructor(private modalService: BsModalService) {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalData);
  }

  setTime(value?: any) {
    if (value !== undefined) {
      this.time = value;
    } else {
      this.time = new Date();
    }
    this.update();
  }

  update() {
    this.markAsTouched();
    this.onChange(this.time);
  }

  writeValue(time: Date) {
    this.time = time;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
