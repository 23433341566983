import { Component, Input, OnInit } from '@angular/core';
import { ReferralAdditionalInformation } from '@app/models/intervention-referral-respiratory';

@Component({
  selector: 'app-summary-list-referral-additional',
  templateUrl: './summary-list-referral-additional.component.html',
  styleUrls: ['./summary-list-referral-additional.component.scss'],
})
export class SummaryListReferralAdditionalComponent implements OnInit {
  @Input() info: ReferralAdditionalInformation =
    new ReferralAdditionalInformation();

  constructor() {}

  ngOnInit(): void {}
}
