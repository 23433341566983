import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StagedForm } from '@app/models/form';
import { ServiceUserStatus } from '@app/models/service-user';
import { serviceUserForm } from '@app/forms/staged-form-service-user';
import { InterventionService } from '@app/services/intervention.service';
import { ServiceUserService } from '@app/services/service-user.service';
import { StageService } from '@app/services/stage.service';
import { UserService } from '@app/services/user/user.service';

@Component({
  selector: 'app-form-service-user',
  templateUrl: './form-service-user.component.html',
  styleUrls: ['./form-service-user.component.scss'],
})
export class FormServiceUserComponent implements OnInit {
  formData: StagedForm = serviceUserForm;

  constructor(
    public stageService: StageService,
    private serviceUserService: ServiceUserService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  handleSubmit(data: any) {
    data['status'] = ServiceUserStatus.triage;
    data['admissionDate'] = new Date();
    data['personalDetails'] = data.serviceUser.personalDetails;
    data['personalDetails']['address'] = [
      data.serviceUser.personalDetails.address,
    ];

    this.serviceUserService.create(data).subscribe(
      (response) => {
        this.router.navigate(['users']);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
