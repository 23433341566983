import { Resource } from '@models/resource';

export class InterventionResource {
  ids: number[];
  text: string;
  data: Resource[];

  constructor(data?: { ids?: number[]; text?: string; data?: Resource[] }) {
    this.ids = data && data.ids ? data.ids : [];
    this.text = data && data.text ? data.text : '';
    this.data = data && data.data ? data.data : [];
  }
}
