import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ServiceUserService } from '@services/service-user.service';
import { CallService } from '@services/call.service';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ServiceUser } from '@models/service-user';
import { Call, Topic, TopicType } from '@app/models/call';
import { Intervention } from '@app/models/intervention';
import { GoalService } from '@app/services/goal.service';
import { Goal, GoalNote } from '@app/models/goal';
import * as _ from 'lodash';
@Component({
  selector: 'app-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss'],
})
export class CallComponent implements OnInit {
  loading = {
    page: true,
    saving: false,
  };

  callForm!: FormGroup;
  topicOptions = Object.values(TopicType);

  innerHeight!: number;

  calls: Intervention<any>[] = [];
  call!: Intervention<Call>;

  serviceUser!: ServiceUser;

  error: string = '';

  bsConfig = Object.assign(
    {},
    { containerClass: 'theme-default', dateInputFormat: 'DD/MM/YYYY' }
  );

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private serviceUserService: ServiceUserService,
    private callService: CallService,
    private goalService: GoalService,
    private formBuilder: FormBuilder
  ) {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerHeight = window.innerHeight;
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id') + '';
    this.serviceUserService.get(id).subscribe(
      (user: ServiceUser) => {
        this.serviceUser = user;

        if (
          this.serviceUser.interventions &&
          this.serviceUser.interventions.length > 0
        ) {
          this.calls = this.serviceUser.interventions.filter(
            (i: any) => i.type === 'Call'
          );
        }

        this.callRestore();
        let goals: Goal[] = [];
        if (this.serviceUser.goals.length > 0) {
          goals = this.serviceUser.goals.map((g: Goal) => {
            const goal = new Goal(g);
            goal.notes = [
              new GoalNote({
                created: new Date(),
                authorId: 0,
                text: this.getGoalNote(g.id),
                callId: 0,
              }),
            ];

            const storedGoal = this.call.data.goals?.filter(
              (sg) => sg.id === g.id
            );
            if (storedGoal && storedGoal.length > 0) {
              goal.notes[0].text = storedGoal[0].notes[0]?.text;
            }
            return goal;
          });
        }

        this.call.data.goals?.forEach((g) => {
          if (!g.id) {
            goals.push(g);
          }
        });

        this.call.data.goals = goals;

        this.callForm = this.formBuilder.group({
          generalCallNotes: [
            this.call.data.generalCallNotes,
            [Validators.required],
          ],
          postCallNotes: [this.call.data.postCallNotes, [Validators.required]],
          startTime: [this.call.data.startTime, [Validators.required]],
          endTime: [this.call.data.endTime, [Validators.required]],
          callerMood: [this.call.data.callerMood, [Validators.required]],
          nextCall: [this.call.data.nextCall],
        });

        if (!this.call.data.topics) {
          this.call.data.topics = [];
        }

        this.calls.push(this.call);

        this.callForm.valueChanges // subscribe to all changes
          .subscribe((event) => {
            this.callStore();
          });

        this.loading.page = false;
      },
      (error) => {
        this.error = 'Error loading goals';
        this.loading.page = false;
      }
    );
  }

  getGoalNote(goalIdToFind: number): string {
    let noteText = '';
    this.call.goals?.forEach((g) => {
      if (g.id === goalIdToFind) {
        noteText = g.notes && g.notes.length > 0 ? g.notes[0].text : '';
      }
    });
    return noteText;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.callForm.controls;
  }

  isRequiredField(field: string) {
    const form_field = this.callForm.get(field);
    if (!form_field?.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator.required;
  }

  handleGoalsChanged() {
    this.callStore();
  }

  handleTopicsChanged() {
    this.callStore();
  }

  cancel() {
    this.router.navigate(['/service-users/' + this.serviceUser.id]);
  }

  submit() {
    this.callForm.markAllAsTouched();

    this.loading.saving = true;

    const payload = {
      type: 'Call',
      serviceUser: {
        id: this.serviceUser.id,
      },
      data: this.call.data,
    };

    payload.data.goals = this.call.data.goals;

    this.callService.create(payload).subscribe(
      (res: any) => {
        this.callClear();
        this.loading.saving = false;
        this.router.navigate(['/service-users/' + this.serviceUser.id]);
      },
      (error) => {
        this.error = 'Could not create the call. Please try again.';
        this.loading.saving = false;
      }
    );
  }

  didNotPickup() {
    if (
      confirm('Are you sure you want to mark this call as unanswered?') === true
    ) {
      this.call.data.generalCallNotes =
        'USER DID NOT PICKUP. ' +
        (this.call.data.generalCallNotes
          ? this.call.data.generalCallNotes
          : '');
      const payload = {
        type: 'Call',
        serviceUser: {
          id: this.serviceUser.id,
        },
        data: this.call.data,
      };

      payload.data.goals = this.call.data.goals;

      this.callService.create(payload).subscribe(
        (res: any) => {
          this.callClear();
          this.loading.saving = false;
          this.router.navigate(['/service-users/' + this.serviceUser.id]);
        },
        (error) => {
          this.error = 'Could not create the call. Please try again.';
          this.loading.saving = false;
        }
      );
    }
  }

  callStore() {
    this.call.data.generalCallNotes =
      this.callForm.controls.generalCallNotes.value;
    this.call.data.postCallNotes = this.callForm.controls.postCallNotes.value;
    this.call.data.startTime = this.callForm.controls.startTime.value;
    this.call.data.endTime = this.callForm.controls.endTime.value;
    this.call.data.callerMood = this.callForm.controls.callerMood.value;
    this.call.data.nextCall = this.callForm.controls.nextCall.value;

    localStorage.setItem(
      'call' + this.serviceUser.id,
      JSON.stringify(this.call)
    );
  }

  callRestore() {
    this.call = new Intervention<Call>(
      JSON.parse(localStorage.getItem('call' + this.serviceUser.id) || '{}')
    );
    this.call.data.topics = this.call.data.topics?.filter((topic) => {
      if (!topic.name && !topic.text) {
        return false;
      } else {
        return true;
      }
    });
  }

  callClear() {
    localStorage.removeItem('call' + this.serviceUser.id);
  }
}
