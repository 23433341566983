<div class="card w-100 mb-2 cursor">
  <div class="card-body">
    <h5>
      Long Covid Referral
      <span class="text-muted float-end">{{
        intervention.data.referrer.healthBoard
      }}</span>
    </h5>
    <div *ngIf="intervention.data.referralDate">
      {{intervention.data.referralDate | date}}
    </div>
    <div *ngIf="!intervention.data.referralDate && intervention.data.referralDateNa">
      Referral date not available
    </div>

    <div class="row mt-4">
      <div class="col-sm-12 col-md-4">
        <h6 class="text-primary">Referrer</h6>
        <app-summary-list-referrer
          [referrer]="intervention.data.referrer"
        ></app-summary-list-referrer>
      </div>

      <div class="col-sm-12 col-md-4">
        <h6 class="text-primary">Patient History</h6>
        <app-summary-list-history
          [history]="intervention.data.history"
        ></app-summary-list-history>
      </div>

      <div class="col-sm-12 col-md-4">
        <h6 class="text-primary">Additional Information</h6>
        <app-summary-list-referral-additional
          [info]="intervention.data.additionalInformation"
        ></app-summary-list-referral-additional>
      </div>
    </div>

    <div *ngIf="goals && goals.length > 0" class="pt-4">
      <h6 class="text-primary">Goals</h6>
      <ul>
        <li *ngFor="let goal of goals" class="key-pair">
          <span class="key">{{ goal.type }}</span> {{ goal.description }}
        </li>
      </ul>
    </div>
  </div>
</div>
