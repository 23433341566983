import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent implements OnInit {

  @Input() label: string = '';
  @Input() data: {}[] = [];
  @Input() key: string = '';
  @Input() multi: boolean = false;
  @Input() placeholder: string = 'Please select';

  @Output() update = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  set(option: any) {
    this.update.emit({
      'key': this.key,
      'data': option
    })
  }

}
