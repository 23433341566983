import { Component, Input, OnInit } from '@angular/core';
import { Intervention } from '@app/models/intervention';
import { InterventionReferOn } from '@app/models/intervention-refer-on';

@Component({
  selector: 'app-summary-refer-on',
  templateUrl: './summary-refer-on.component.html',
  styleUrls: ['./summary-refer-on.component.scss'],
})
export class SummaryReferOnComponent implements OnInit {
  @Input() intervention!: Intervention<InterventionReferOn>;

  constructor() {}

  ngOnInit(): void {}
}
