import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-dob',
  templateUrl: './dob.component.html',
  styleUrls: ['./dob.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DobComponent,
    },
  ],
})
export class DobComponent implements ControlValueAccessor {
  form!: FormGroup;
  date!: any;
  age!: number;

  onChange = (date: any) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor() {}

  ngOnInit(): void {
    if (!(this.date instanceof Date)) {
      this.date = Date.parse(this.date);
    }

    this.form = new FormGroup({
      date: new FormControl(this.date),
    });

    this.form.get('date')!.valueChanges.subscribe((value) => {
      this.date = value;
      this.age = this.calculateAge(value);
      this.onChange(value);
    });
  }

  calculateAge(date: string) {
    let age = 0;
    if (date) {
      var timeDiff = Math.abs(Date.now() - +date);
      age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    }
    return age;
  }

  writeValue(date: any) {
    this.date = date;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
