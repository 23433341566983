<div class="key-pair" *ngIf="history?.primaryCondition">
  <span class="key">Primary Condition:</span>
  {{ history?.primaryCondition }}
</div>

<div class="key-pair" *ngIf="history?.primaryConditionOther">
  <span class="key">Primary Condition Other:</span>
  {{ history?.primaryConditionOther }}
</div>
<div class="key-pair" *ngIf="history?.diagnosisConfirmed">
  <span class="key">Diagnosis Confirmed:</span>
  {{ history?.diagnosisConfirmed ? "Yes" : "No" }}
</div>
<div
  class="key-pair"
  *ngIf="history?.peIhdMalignancyDifferentialDiagnosisExcluded"
>
  <span class="key">PE, IHD Malignancy & differential diagnosis excluded:</span>
  {{ history?.peIhdMalignancyDifferentialDiagnosisExcluded ? "Yes" : "No" }}
</div>

<div class="key-pair" *ngIf="history?.spo2">
  <span class="key">SpO2:</span>
  {{ history?.spo2 }}
</div>
<div class="key-pair" *ngIf="history?.respirationRate">
  <span class="key">Respiration Rate:</span>
  {{ history?.respirationRate }}
</div>
<div class="key-pair" *ngIf="history?.heartRate">
  <span class="key">Heart Rate:</span>
  {{ history?.heartRate }}
</div>
<div class="key-pair" *ngIf="history?.bloodPressure">
  <span class="key">Blood Pressure:</span>
  {{ history?.bloodPressure }}
</div>
<div class="key-pair" *ngIf="history?.physiologyNotes">
  <span class="key">Physiology Notes:</span>
  {{ history?.physiologyNotes }}
</div>
<div class="key-pair" *ngIf="history?.bloodTests">
  <span class="key">Blood Tests:</span>
  {{ history?.bloodTests }}
</div>
<div class="key-pair" *ngIf="history?.bloodTestNotes">
  <span class="key">Blood Test Notes:</span>
  {{ history?.bloodTestNotes }}
</div>

<div class="key-pair" *ngIf="history?.investigations">
  <span class="key">Investigations:</span>
  {{ history?.investigations }}
</div>
<div class="key-pair" *ngIf="history?.investigationsNotes">
  <span class="key">Investigation Notes:</span>
  {{ history?.investigationsNotes }}
</div>

<div class="key-pair" *ngIf="history?.interventions">
  <span class="key">Interventions:</span>
  {{ history?.interventions }}
</div>
<div class="key-pair" *ngIf="history?.otherRelevantConditions">
  <span class="key">Other Relevant Conditions:</span>
  {{ history?.otherRelevantConditions }}
</div>
<div class="key-pair" *ngIf="history?.otherRelevantConditionsOther">
  <span class="key">Other Relevant Conditions (other):</span>
  {{ history?.otherRelevantConditionsOther }}
</div>
<div class="key-pair" *ngIf="history?.riskFactors">
  <span class="key">Risk Factors:</span>
  {{ history?.riskFactors }}
</div>
<div class="key-pair" *ngIf="history?.impairments">
  <span class="key">Impairments:</span>
  {{ history?.impairments }}
</div>
<div class="key-pair" *ngIf="history?.impairmentsDetail">
  <span class="key">Impairments Detail:</span>
  {{ history?.impairmentsDetail }}
</div>
