import { Component, Input, OnInit } from '@angular/core';
import { Intervention } from '@app/models/intervention';
import { InterventionSignpost } from '@app/models/intervention-signpost';

@Component({
  selector: 'app-summary-signposting',
  templateUrl: './summary-signposting.component.html',
  styleUrls: ['./summary-signposting.component.scss'],
})
export class SummarySignpostingComponent implements OnInit {
  @Input() intervention!: Intervention<InterventionSignpost>;
  @Input() title: string = 'Signposting';

  constructor() {}

  ngOnInit(): void {}
}
