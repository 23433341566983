import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-mood-select',
  templateUrl: './mood-select.component.html',
  styleUrls: ['./mood-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MoodSelectComponent,
    },
  ],
})
export class MoodSelectComponent implements ControlValueAccessor {

  mood!: number;

  onChange = (mood: any) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  constructor() { }

  ngOnInit(): void {
  }

  setMood(mood: number) {
    this.mood = mood;
    this.onChange(mood);
  }

  writeValue(mood: number) {
    this.mood = mood;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
