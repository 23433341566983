import { Component, Input, OnInit } from '@angular/core';
import { Intervention } from '@app/models/intervention';
import { Call } from '@models/call';
import { Goal } from '@models/goal';

@Component({
  selector: 'app-summary-call',
  templateUrl: './summary-call.component.html',
  styleUrls: ['./summary-call.component.scss'],
})
export class SummaryCallComponent implements OnInit {
  @Input() intervention!: Intervention<Call>;
  @Input() goals!: Goal[];
  @Input() title: string = 'Call';

  didNotPickup = false;

  goalList: Goal[] = [];

  constructor() {}

  ngOnInit(): void {
    if (this.intervention.data && this.intervention.data.generalCallNotes) {
      if (
        this.intervention.data.generalCallNotes.startsWith(
          'USER DID NOT PICKUP'
        )
      ) {
        this.didNotPickup = true;
      }
    }

    if (this.goals) {
      this.goalList = this.goals;
    } else {
      this.goalList =
        this.intervention.data.goals && this.intervention.data.goals.length > 0
          ? this.intervention.data.goals
          : this.intervention.goals;
    }
  }

  calculateDuration(date1: any, date2: any): string {
    var diff = Math.abs(new Date(date1).getTime() - new Date(date2).getTime());
    return Math.round(diff / 60000) + ' mins';
  }
}
