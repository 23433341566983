<div class="btn-group" role="group" aria-label="Basic example">
  <button type="button" class="btn btn-light" [class.selected]="selected==5"
    style="border: 1px solid rgba(0, 0, 0, 0.125)" (click)="selected=5">
    <fa-icon [icon]="['fal', 'smile']" class="text-success me-1" *ngIf="show == 'all' || rating == 5"></fa-icon>
  </button>
  <button type="button" class="btn btn-light" [class.selected]="selected==4"
    style="border: 1px solid rgba(0, 0, 0, 0.125)" (click)="selected=4">
    <fa-icon [icon]="['fal', 'laugh-beam']" class="text-success me-1" *ngIf="show == 'all' || rating == 4"></fa-icon>
  </button>
  <button type="button" class="btn btn-light" [class.selected]="selected==3" (click)="selected=3"
    style="border: 1px solid rgba(0, 0, 0, 0.125)">
    <fa-icon [icon]="['fal', 'meh']" class="text-warning me-1" *ngIf=" show=='all' || rating==3">
    </fa-icon>
  </button>
  <button type="button" class="btn btn-light" [class.selected]="selected==2" (click)="selected=2"
    style="border: 1px solid rgba(0, 0, 0, 0.125)">
    <fa-icon [icon]="['fal', 'frown']" class="text-danger me-1" *ngIf="show == 'all' || rating == 2"></fa-icon>
  </button>
  <button type="button" class="btn btn-light" [class.selected]="selected==1" (click)="selected=1"
    style="border: 1px solid rgba(0, 0, 0, 0.125)">
    <fa-icon [icon]="['fal', 'sad-tear']" class="text-danger me-1" *ngIf="show == 'all' || rating == 1"></fa-icon>
  </button>
</div>
