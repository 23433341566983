import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export function otherValidator(predicate: any) {
    return ((formControl: AbstractControl) => {
        if (!formControl.parent) {
            return null;
        }

        if (predicate() ) {

            if (Array.isArray(predicate())) {

                for (const e of predicate()) {
                    if (e.toLowerCase().includes('other')) {
                        return Validators.required(formControl);
                    }
                }

            } else {
                if (predicate().toLowerCase().includes('other')) {
                    return Validators.required(formControl);
                }
            }
        }

        return null;
    });
}