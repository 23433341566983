import { Component, OnInit } from '@angular/core';
import { referrerRegionsWithOptions } from '@app/helpers/form-input-data';

@Component({
  selector: 'app-referral-welcome',
  templateUrl: './referral-welcome.component.html',
  styleUrls: ['./referral-welcome.component.scss'],
})
export class ReferralWelcomeComponent implements OnInit {
  boardOptions = referrerRegionsWithOptions;
  boardSelected:
    | {
        name: string;
        label: string;
        referrals: string[];
      }
    | undefined;

  constructor() {}

  ngOnInit(): void {}

  boardHasReferral(referralType: string): boolean {
    if (this.boardSelected && this.boardSelected.referrals) {
      return this.boardSelected.referrals.includes(referralType);
    }
    return false;
  }
}
