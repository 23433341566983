import { Referrer } from './referrer';
import { History } from './history';
import { ReferralAdditionalInformation } from './intervention-referral-respiratory';

export class ReferralCardiac {
  referralDate: string;
  referralDateNa: boolean;
  currentSupport: string[];
  history: History;
  referrer: Referrer;
  additionalInformation: ReferralAdditionalInformation;

  constructor(data?: any) {
    this.referralDate =
      data && data.referralDate ? data.referralDate : undefined;
    this.referralDateNa =
      data && data.referralDateNa ? data.referralDateNa : false;

    this.currentSupport =
      data && data.currentSupport ? data.currentSupport : undefined;
    this.history = data && data.history ? data.history : new History();
    this.referrer = data && data.referrer ? data.referrer : new Referrer();
    this.additionalInformation =
      data && data.additionalInformation
        ? data.additionalInformation
        : new ReferralAdditionalInformation();
  }
}
