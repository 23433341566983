import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaseManagerSummary } from '@app/models/case-manager';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CaseManagerService {
  constructor(private http: HttpClient) {}

  public getAll(): Observable<CaseManagerSummary[]> {
    return this.http
      .get<CaseManagerSummary[]>(
        environment.wpApiUrl + '/users?roles=case_manager&per_page=99'
      )
      .pipe(map((data) => data.map((user) => new CaseManagerSummary(user))));
  }
}
