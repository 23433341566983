import { Component, Input, OnInit } from '@angular/core';
import { Intervention } from '@app/models/intervention';
import { InterventionDischarge } from '@app/models/intervention-discharge';

@Component({
  selector: 'app-summary-discharge',
  templateUrl: './summary-discharge.component.html',
  styleUrls: ['./summary-discharge.component.scss'],
})
export class SummaryDischargeComponent implements OnInit {
  @Input() intervention!: Intervention<InterventionDischarge>;

  constructor() {}

  ngOnInit(): void {}
}
