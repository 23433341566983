<div class="position-relative">
  <div class="input-group mb-3">
    <form novalidate [formGroup]="form">
      <ngx-combo-datepicker formControlName="date" [placeholder]="'Year, Month, Day'" [attrsDate]=" {
      class: 'form-control float-start me-2' , style: 'width:60px' }" [attrsMonth]="{
                        class: 'form-control float-start me-2',
                        style: 'width:80px'
                      }" [attrsYear]="{
                        class: 'form-control float-start me-2',
                        style: 'width:60px'
                      }">
      </ngx-combo-datepicker>
    </form>
    <span *ngIf="age" class="d-inline-block ms-2 mt-2 form-text">({{ age }} years old)</span>
  </div>
</div>
