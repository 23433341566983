import { QuestionDropdown, QuestionTextbox, Question } from '@app/models/form';

import {
  titleOptions,
  referrerRegionOptions,
  referrerRelationshipOptions,
} from '@app/helpers/form-input-data';
import { patterns } from '@app/helpers/validators';
import { Validators } from '@angular/forms';

export const formReferrer: Question<any>[] = [
  new QuestionDropdown({
    key: 'healthBoard',
    path: 'data.referrer.healthBoard',
    label: 'Referrer Region',
    required: true,
    options: referrerRegionOptions,
  }),
  new QuestionDropdown({
    key: 'referrerTitle',
    path: 'data.referrer.personalDetails.title',
    label: 'Title',
    required: true,
    options: titleOptions,
  }),
  new QuestionTextbox({
    key: 'referrerFirstName',
    path: 'data.referrer.personalDetails.firstName',
    label: 'First name',
    validators: [Validators.maxLength(40)],
    required: true,
  }),
  new QuestionTextbox({
    key: 'referrerLastName',
    path: 'data.referrer.personalDetails.lastName',
    label: 'Last name',
    validators: [Validators.maxLength(40)],
    required: true,
  }),
  new QuestionDropdown({
    key: 'referrerRelationship',
    path: 'data.referrer.relationship',
    label: 'Job Title',
    required: true,
    options: referrerRelationshipOptions,
  }),
  new QuestionTextbox({
    key: 'referrerRelationshipOther',
    path: 'data.referrer.referrerRelationshipOther',
    label: 'Relationship Other',
    validators: [Validators.maxLength(75)],
    required: true,
    displayConditions: [
      {
        key: 'referrerRelationship',
        values: ['Other'],
      },
    ],
  }),
  new QuestionTextbox({
    key: 'referrerAddress',
    path: 'data.referrer.personalDetails.address.address',
    label: 'Address',
    validators: [Validators.maxLength(40)],
    required: true,
  }),
  new QuestionTextbox({
    key: 'referrerStreet',
    path: 'data.referrer.personalDetails.address.address2',
    label: 'Street',
    validators: [Validators.maxLength(40)],
  }),
  new QuestionTextbox({
    key: 'referrerCity',
    path: 'data.referrer.personalDetails.address.city',
    label: 'Town/City',
    validators: [Validators.maxLength(40)],
    required: true,
  }),
  new QuestionTextbox({
    key: 'referrerPostcode',
    path: 'data.referrer.personalDetails.address.postcode',
    label: 'Postcode',
    validators: [Validators.maxLength(10)],
    required: true,
  }),
  new QuestionTextbox({
    key: 'referrerPhone',
    path: 'data.referrer.personalDetails.phone',
    label: 'Telephone contact 1',
    required: true,
    validators: [
      Validators.pattern(patterns.phone),
      Validators.minLength(7),
      Validators.maxLength(15),
    ],
  }),
  new QuestionTextbox({
    key: 'referrerPhone2',
    path: 'data.referrer.personalDetails.mobile',
    label: 'Telephone contact 2',
    validators: [
      Validators.pattern(patterns.phone),
      Validators.minLength(7),
      Validators.maxLength(15),
    ],
  }),
  new QuestionTextbox({
    key: 'referrerEmail',
    path: 'data.referrer.personalDetails.email',
    label: 'Primary Email',
    required: true,
    validators: [Validators.pattern(patterns.email), Validators.maxLength(100)],
  }),
  new QuestionTextbox({
    key: 'referrerEmailSecondary',
    path: 'data.referrer.personalDetails.emailSecondary',
    label: 'Secondary Email',
    required: false,
    validators: [Validators.pattern(patterns.email), Validators.maxLength(100)],
  }),

];
