export class User {
  id: number;
  username?: string;
  email?: string;
  password?: string;
  firstName?: string;
  surname?: string;
  telephone?: string;
  permissions?: any[];
  admin?: boolean;
  activated?: boolean;
  suspend?: boolean;
  approved?: boolean;
  roles?: UserRoles[];
  token?: string;
  fullName?: string;

  constructor() {
    this.id = 0;
  }
}

export enum UserRoles {
  // Can assign case managers to service users
  case_manager_manager = 'case_manager_manager',
  // Can update cases
  case_manager = 'case_manager',
  // Can only view cases
  case_viewer = 'case_viewer',
}
