import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Goal, GoalNote } from '@models/goal';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { AcfOptions } from '@app/models/acfOptions';

@Injectable({
  providedIn: 'root',
})
export class AcfOptionsService {
  constructor(private http: HttpClient) {}

  public get(): Observable<AcfOptions> {
    return this.http.get<AcfOptions>(environment.apiUrl + '/options/');
  }
}
