export class Address {
  address1: string;
  address2?: string;
  city: string;
  postcode: string;

  constructor(data?: any) {
    this.address1 = data && data.address1 ? data.address1 : '';
    this.address2 = data && data.address2 ? data.address2 : '';
    this.city = data && data.city ? data.city : '';
    this.postcode = data && data.postcode ? data.postcode : '';
  }
}
