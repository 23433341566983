import { Validators } from '@angular/forms';
import { patterns } from '@app/helpers/validators';
import {
  contact5WorkingDaysNo,
  declinedReasons,
  optionsYesNo,
  preferredCallDayOptions,
  preferredCallFrequencyOptions,
  preferredCallTimeOptions,
} from '@app/helpers/form-input-data';
import {
  QuestionCheckbox,
  QuestionDate,
  QuestionTextarea,
  Question,
  QuestionDropdown,
  QuestionHTML,
  QuestionTextbox,
} from '@app/models/form';

export const onboardingForm: Question<any>[] = [
  new QuestionDropdown({
    key: 'contact5WorkingDays',
    path: 'data.contact5WorkingDays',
    label: 'Contact attempted within 5 working days',
    options: optionsYesNo,
  }),
  new QuestionDropdown({
    key: 'contact5WorkingDaysNo',
    path: 'data.contact5WorkingDaysNo',
    label: 'Contact delay reason',
    options: contact5WorkingDaysNo,
    displayConditions: [
      {
        key: 'contact5WorkingDays',
        values: ['No'],
      },
    ],
  }),

  new QuestionHTML({
    key: 'telephoneSupportExplainedText',
    label:
      '<h6 class="text-black">Telephone support details</h6><p>The service offers pre-arranged telephone calls from a Health Professional to support  you in the management of your health Condition. We can discuss any questions you may have explore any goals you want to achieve and wotk with you to achieve them.',
    payloadIgnore: true,
  }),
  new QuestionCheckbox({
    key: 'telephoneSupportExplained',
    path: 'data.telephoneSupportExplained',
    label: 'Telephone support service explained',
  }),
  new QuestionHTML({
    key: 'gdprNotice',
    label:
      '<h6 class="text-black">GDPR Notice</h6><p>As part of the CHSS Adviceline Team we need to ask for your consent to store any information about you and the care we have provided. This information is stored securely in paper and in electronic form in notes and letters and on our database.This information is only shared with authorised staff within CHSS and would only be shared with a third party with your permission (for example your GP or your carer).It will not be utilised for any other purpose without your permission. If you have any questions or concerns, you should either talk to your Adviceline Team member or email data@chss.org.uk</p>',
    payloadIgnore: true,
  }),
  new QuestionCheckbox({
    key: 'gdprServiceUserConsent',
    path: 'data.gdprServiceUserConsent',
    label: 'Do you consent to CHSS holding and processing this information?',
    required: true,
  }),

  new QuestionHTML({
    key: 'telephoneSupportExplainedText',
    label:
      '<h6 class="text-black">Adviceline contact information</h6><p>Tel: 0808 801 0899 Email: Adviceline@chss.org.uk',
    payloadIgnore: true,
  }),
  new QuestionCheckbox({
    key: 'adviceLineInformationProvided',
    path: 'data.adviceLineInformationProvided',
    label: 'Adviceline contact information provided',
  }),

  new QuestionDropdown({
    key: 'serviceDeclined',
    path: 'data.serviceDeclined',
    label: 'Service declined',
    options: declinedReasons,
  }),

  new QuestionDropdown({
    key: 'preferredCallFrequency',
    path: 'data.preferredCallFrequency',
    label: 'Preferred frequency of calls',
    options: preferredCallFrequencyOptions,
  }),

  new QuestionDropdown({
    key: 'preferredCallDays',
    path: 'data.preferredCallDays',
    label: 'Preferred day of the week',
    options: preferredCallDayOptions,
  }),

  new QuestionDropdown({
    key: 'preferredCallTimes',
    path: 'data.preferredCallTimes',
    label: 'Preferred call time',
    options: preferredCallTimeOptions,
  }),
  new QuestionTextbox({
    key: 'preferredCallTimesOther',
    path: 'data.preferredCallTimesOther',
    label: 'Other call time',
    displayConditions: [
      {
        key: 'preferredCallTimes',
        values: ['Other'],
      },
    ],
  }),

  new QuestionTextarea({
    key: 'text',
    path: 'data.text',
    label: 'Notes',
    validators: [Validators.maxLength(250)],
  }),

  new QuestionDate({
    key: 'nextCall',
    path: 'data.nextCall',
    label: 'Next call date',
  }),
  new QuestionHTML({
    key: 'admissionLetterLink',
    label:
      '<a href="https://chsscloud.sharepoint.com/:w:/r/sites/Services/AdviceLine/Advice%20Line%20Nurses/H2H%20REFERRALS/FINAL%20Letter%20Templates/Service%20User%20-%20Admission%20Letter%20v2.docx?d=wf07eecc4195a4b2692421c5e5490b151&csf=1&web=1&e=Zpyt5F" target="_blank">Admission letter template</a>',
    payloadIgnore: true,
  }),
  new QuestionDropdown({
    key: 'admissionLetterSent',
    path: 'data.admissionLetterSent',
    label: 'Admission letter sent?',
    options: optionsYesNo,
  }),
];
