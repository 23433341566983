import { Component, Input, OnInit } from '@angular/core';
import { History } from '@app/models/history';

@Component({
  selector: 'app-summary-list-history',
  templateUrl: './summary-list-history.component.html',
  styleUrls: ['./summary-list-history.component.scss'],
})
export class SummaryListHistoryComponent implements OnInit {
  @Input() history: History = new History();
  constructor() {}

  ngOnInit(): void {}
}
