import { FormGroup, ValidatorFn } from '@angular/forms';
import { environment } from '@env/environment';
import { Goal } from './goal';

export class StagedForm {
  type: string;
  stages: Stage[];

  constructor(options: { type?: string; stages?: Stage[] }) {
    this.type = options.type || '';
    this.stages = options.stages || [];
  }
}

const isTestEnvironment = environment.siteUrl === 'http://localhost:4200';

export class Question<T> {
  value: T | undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  options: string[];
  disabled: boolean;
  displayConditions: QuestionDisplayCondition[];
  path?: string;
  validators: ValidatorFn[];
  placeholder: string;
  payloadIgnore: boolean;
  unit: string;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      options?: string[];
      disabled?: boolean;
      displayConditions?: QuestionDisplayCondition[];
      path?: string;
      validators?: ValidatorFn[];
      placeholder?: string;
      payloadIgnore?: boolean;
      unit?: string;
    } = {}
  ) {
    this.value = options.value ? options.value : undefined;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = isTestEnvironment ? false : !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
    this.disabled = options.disabled || false;
    this.displayConditions = options.displayConditions || [];
    this.path = options.path || '';
    this.validators = options.validators || [];
    this.placeholder = options.placeholder || '';
    this.payloadIgnore = options.payloadIgnore || false;
    this.unit = options.unit || '';
  }
}

export interface QuestionDisplayCondition {
  key: string;
  values: any[];
}

export class QuestionMultiSelect extends Question<string[]> {
  controlType = 'multiSelect';
  type = 'multiSelect';

  constructor(data: any) {
    super(data);
    this.value = data && data.value ? data.value : [];
  }
}

export class QuestionCheckbox extends Question<boolean> {
  controlType = 'checkbox';
  type = 'checkbox';

  constructor(data: any) {
    super(data);
    this.value = data && data.value ? data.value : false;
  }
}

export class QuestionTextbox extends Question<string> {
  controlType = 'textbox';
  type = 'textbox';
}

export class QuestionTextarea extends Question<string> {
  controlType = 'textarea';
  type = 'textarea';
}

export class QuestionDropdown extends Question<string> {
  controlType = 'dropdown';
  type = 'dropdown';
}

export class QuestionRadio extends Question<string> {
  controlType = 'radio';
  type = 'radio';
}

export class QuestionDate extends Question<string> {
  controlType = 'date';
  type = 'date';
}

export class QuestionDateOfBirth extends Question<string> {
  controlType = 'dateOfBirth';
  type = 'dateOfBirth';
}

export class QuestionHTML extends Question<string> {
  controlType = 'html';
  type = 'html';
}

export class QuestionGoals extends Question<Goal[]> {
  controlType = 'goal';
  type = 'goal';

  constructor(data: any) {
    super(data);
    this.value = data && data.value ? data.value : [];
  }
}

export class Form {
  id: string;
  title: string;
  type: string;
  questions: Question<any>[];
  formGroup?: FormGroup;
  description?: string;

  constructor(
    id: string = '',
    title: string = '',
    questions: Question<any>[] = [],
    description: string = '',
    formGroup?: FormGroup,
    type: string = 'form'
  ) {
    this.id = id;
    this.title = title;
    this.questions = questions;
    this.description = description;
    this.formGroup = formGroup;
    this.type = type;
  }
}

export class Stage {
  id: string;
  title: string;
  type: string;
  validate?: any[];
  questions: Question<any>[];
  description?: string;
  formGroup?: FormGroup;

  constructor(
    id: string = '',
    title: string = '',
    validate: any[] = [],
    type: 'splash' | 'form' = 'form',
    questions: Question<any>[] = [],
    description: string = '',
    formGroup?: FormGroup
  ) {
    this.id = id;
    this.title = title;
    this.type = type;
    this.validate = validate;
    this.questions = questions;
    this.description = description;
    this.formGroup = formGroup;
  }
}
