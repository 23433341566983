<app-header></app-header>

<section>
  <div class="container-fluid px-5">
    <div class="row">
      <div class="col">
        <div class="clearfix mb-3">
          <span class="h3 ms-3"
            >Service Users
            <button
              [disabled]="!authService.userHasPermission('edit')"
              class="btn btn-primary btn-small"
              (click)="router.navigate(['/service-users/new/'])"
            >
              New
            </button></span
          >
          <div class="position-relative float-end" style="width: 300px">
            <fa-icon
              [icon]="['far', 'search']"
              class="position-absolute"
              style="color: #bdbdbd; left: 13px; top: 8px; font-size: 0.95rem"
            ></fa-icon>
            <input
              style="padding-left: 35px"
              class="search form-control rounded mb-1 pl-4"
              type="text"
              placeholder="Search..."
              [(ngModel)]="searchTermValue"
              (ngModelChange)="this.searchTerm.next($event)"
            />
            <fa-icon
              *ngIf="searchTerm"
              [icon]="['fas', 'times-circle']"
              class="cursor position-absolute text-secondary"
              style="right: 0; top: 0; right: 10px; top: 6px; font-size: 1.2rem"
            ></fa-icon>
            <div
              class="spinner-border text-primary position-absolute"
              role="status"
              style="
                top: 8px;
                right: 9px;
                height: 1.3rem;
                width: 1.3rem;
                font-size: 0.7rem;
              "
              *ngIf="loading.filter"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>

        <div class="card w-100 p-4 pt-3" style="_border: 0">
          <div class="card-body p-0">
            <div
              *ngIf="loading.page"
              class="spinner-border text-primary m-4"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>

            <ng-container *ngIf="!loading.page && casesPaged">
              <div
                class="rounded bg-white"
                style="_border: 1px solid #e0e0e0; _border-bottom: 0"
              >
                <table
                  mat-table
                  [dataSource]="sortedData"
                  class="w-100 mat-elevation-z8"
                >
                  <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.title" class="me-2">{{
                        element.title
                      }}</span>
                      <span *ngIf="element.firstName" class="me-2">{{
                        element.firstName | titlecase
                      }}</span>
                      <span *ngIf="element.lastName" class="me-2">{{
                        element.lastName | titlecase
                      }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="region">
                    <th mat-header-cell *matHeaderCellDef>Region</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.region || 'N/A' }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="admissionDate">
                    <th mat-header-cell *matHeaderCellDef>Referral</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.admissionDate == 'N/A' ? 'N/A' : element.admissionDate | date }}
                      <div *ngIf="element.admissionType" class="me-2 form-text">
                        {{ element.admissionType }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="interventionCount">
                    <th mat-header-cell *matHeaderCellDef>Calls</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.interventionCount }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="lastInterventionDate">
                    <th mat-header-cell *matHeaderCellDef>Last</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.lastInterventionDate == 'N/A' ? 'N/A' : element.lastInterventionDate | date }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="nextCall">
                    <th mat-header-cell *matHeaderCellDef>Next</th>
                    <td mat-cell *matCellDef="let element">
                      <span [className]="element.nextCallClass ? element.nextCallClass : ''">
                        {{ element.nextCall == 'N/A' ? 'N/A' : element.nextCall | date }}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="caseManager">
                    <th mat-header-cell *matHeaderCellDef>Case manager</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.caseManager.name }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.status }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    (click)="loadServiceUser(row.id)"
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </ng-container>
          </div>
        </div>

        <mat-paginator
          *ngIf="casesPaged"
          class="d-inline-block mt-2 float-end"
          (page)="getServiceUsers(pageSize, $event.pageIndex + 1)"
          [hidePageSize]="true"
          [length]="casesPaged.count"
          [pageIndex]="0"
          [pageSize]="pageSize"
          showFirstLastButtons
          aria-label="Select page of cases"
        >
        </mat-paginator>
      </div>
      <div style="width: 300px">
        <div class="card w-100">
          <div class="card-body">
            <app-filter
              [filters]="filters"
              [update]="getServiceUsers"
            ></app-filter>
            <button class="btn btn-primary me-2" (click)="filtersReset()">
              Reset
            </button>
            <button class="btn btn-outline" (click)="filtersMyCaseLoad()">
              My Case Load
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
