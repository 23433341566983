<div class="row row-cols-2 row-cols-md-{{ cols }} g-2">
  <div class="col" *ngFor="let option of options">
    <div
      class="cursor card py-2 px-3 text-center h-100"
      [ngClass]="
        selected.includes(option) ? 'bg-primary text-white' : 'bg-light'
      "
      (click)="select(option)"
      [innerHtml]="option"
    ></div>
  </div>
</div>
