import { Component, Input, OnInit } from '@angular/core';
import { Goal } from '@app/models/goal';
import { Intervention } from '@app/models/intervention';
import { ReferralCardiac } from '@app/models/intervention-referral-cardiac';

@Component({
  selector: 'app-summary-referral-cardiac',
  templateUrl: './summary-referral-cardiac.component.html',
  styleUrls: ['./summary-referral-cardiac.component.scss'],
})
export class SummaryReferralCardiacComponent implements OnInit {
  @Input() intervention!: Intervention<ReferralCardiac>;
  @Input() goals: Goal[] = [];

  constructor() {}

  ngOnInit(): void {}
}
