import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReferOnService } from '@app/services/refer-on.service';

import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-refer-on-select',
  templateUrl: './refer-on-select.component.html',
  styleUrls: ['./refer-on-select.component.scss'],
})
export class ReferOnSelectComponent implements OnInit {
  @Input() items!: any[];
  @Output() update = new EventEmitter();

  selected: any = [];

  constructor(private clipboardService: ClipboardService) {}

  ngOnInit(): void {}

  select(item: any) {
    if (this.selected.includes(item)) {
      this.selected = this.selected.filter((obj: any) => obj !== item);
    } else {
      this.selected.push(item);
    }
    this.update.emit(this.selected);
  }

  copy(e: any, item: any) {
    e.stopPropagation();

    let text = item.title + '\n';
    if (item.description) {
      text = text + item.description.replace(/<br\s*[\/]?>/gi, '\n') + '\n';
    }
    text = text + item.url;

    this.clipboardService.copy(text);
  }
}
