import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Form, Question, Stage } from '@app/models/form';
import { set } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class StageService {
  constructor() {}

  toFormGroup = (questions: Question<any>[]): FormGroup => {
    const group: any = {};

    questions.forEach((question) => {
      group[question.key] = question.required
        ? new FormControl(question.value ? question.value : null, [
            Validators.required,
            ...question.validators,
          ])
        : new FormControl(question.value || null, question.validators);
    });
    return new FormGroup(group);
  };

  questionDisplayConditionsMet = (
    question: Question<any>,
    form?: FormGroup
  ): boolean => {
    const conditionCount = question.displayConditions.length;
    if (conditionCount > 0) {
      for (let i = 0; i < conditionCount; i++) {
        const condition = question.displayConditions[i];

        if (condition.values.length === 1 && condition.values[0] === 'any') {
          // Login to handle "any" condition being met
          if (Array.isArray(form?.controls[condition.key]?.value)) {
            // Check if form value is an array
            if (form?.controls[condition.key]?.value.length > 0) {
              // Met is the value arrays contains at least one element
              return true;
            }
          } else if (
            // Assumes the form value is a none array
            form?.controls[condition.key]?.value !== undefined &&
            form?.controls[condition.key]?.value !== null &&
            form?.controls[condition.key]?.value !== ''
          ) {
            return true;
          }
        } else if (typeof form?.controls[condition.key]?.value == 'boolean') {
          return form?.controls[condition.key]?.value ? true : false;
        } else if (
          form?.controls[condition.key]?.value &&
          condition.values.filter((x) =>
            form?.controls[condition.key]?.value.includes(x)
          ).length > 0
        ) {
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  };

  validate = (stage: Stage) => {
    let stageValid = true;

    if (stage.questions!.length > 0) {
      stage.questions.forEach((question) => {
        if (
          !stage.formGroup?.controls[question.key].valid &&
          this.questionDisplayConditionsMet(question, stage.formGroup)
        ) {
          stageValid = false;
          stage.formGroup?.controls[question.key].markAsTouched();
        }
      });
    }

    return stageValid;
  };

  validate2 = (formGroup: FormGroup, questions: Question<any>[]) => {
    let valid = true;

    if (questions!.length > 0) {
      questions.forEach((question) => {
        if (
          !formGroup?.controls[question.key].valid &&
          this.questionDisplayConditionsMet(question, formGroup)
        ) {
          valid = false;
          formGroup?.controls[question.key].markAsTouched();
        }
      });
    }

    return valid;
  };

  toServerPayload = (stages: Stage[]) => {
    const object = {};
    stages.forEach((stage) => {
      stage.questions.forEach((question) => {
        if (question.payloadIgnore !== true) {
          set(
            object,
            question.path ? question.path : question.key,
            stage.formGroup?.controls[question.key].value
          );
        }
      });
    });
    return object;
  };

  toServerPayload2 = (formGroup: FormGroup, questions: Question<any>[]) => {
    const object = {};

    questions.forEach((question) => {
      if (question.payloadIgnore !== true) {
        set(
          object,
          question.path ? question.path : question.key,
          formGroup?.controls[question.key].value
        );
      }
    });

    return object;
  };
}
