<div class="key-pair" *ngIf="info?.facilitation">
  <span class="key">Facilitation:</span>
  {{ info?.facilitation }}
</div>
<div class="key-pair" *ngIf="info?.facilitationOther">
  <span class="key">Facilitation Other:</span>
  {{ info?.facilitationOther }}
</div>
<div class="key-pair" *ngIf="info?.facilitationTailoredTalks">
  <span class="key">Tailored Talks Number:</span>
  {{ info?.facilitationTailoredTalks }}
</div>
<div class="key-pair" *ngIf="info?.currentSupport">
  <span class="key">Current Support:</span>
  {{ info?.currentSupport }}
</div>
<div class="key-pair" *ngIf="info?.currentSupportOther">
  <span class="key">Current Support Other:</span>
  {{ info?.currentSupportOther }}
</div>
<div class="key-pair" *ngIf="info?.otherComments">
  <span class="key">Other Comments:</span>
  {{ info?.otherComments }}
</div>
<div class="key-pair" *ngIf="info?.clinicalReviewDate">
  <span class="key">Clinical Review Date:</span>
  {{ info?.clinicalReviewDate }}
</div>
<div class="key-pair" *ngIf="info?.gdprConsentGiven">
  <span class="key">GDPR Consent to Share:</span>
  {{ info?.gdprConsentGiven ? "Yes" : "No" }}
</div>
<div class="key-pair" *ngIf="info?.dateOfDischarge">
  <span class="key">Date of discharge:</span>
  {{ info?.dateOfDischarge | date }}
</div>
