import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-form-navigation',
  templateUrl: './form-navigation.component.html',
  styleUrls: ['./form-navigation.component.scss'],
})
export class FormNavigationComponent implements OnInit {
  @Input() previousActive: boolean = false;
  @Input() nextActive: boolean = false;
  @Input() submittable: boolean = false;
  @Input() loading: boolean = false;
  @Input() error: string = '';
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() submit = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  handleNavigate(direction: string) {
    if (direction === 'next') {
      this.next.emit();
    } else {
      this.previous.emit();
    }
  }

  handleSubmit() {
    this.submit.emit();
  }
}
