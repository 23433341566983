export class InterventionDischarge {
  consentShareDischargeInformation: string;
  consentFutureEvaluation: string;
  consentFutureStories: string;
  consentFutureStoriesNotAppropriate: string;
  dischargeLetterSent: string;
  dischargeEvaluationDate: string;
  dischargeSummary: string;

  constructor(data?: {
    consentShareDischargeInformation?: string;
    consentFutureEvaluation?: string;
    consentFutureStories?: string;
    consentFutureStoriesNotAppropriate?: string;
    dischargeLetterSent?: string;
    dischargeEvaluationDate?: string;
    dischargeSummary?: string;
  }) {
    this.consentShareDischargeInformation =
      data && data.consentShareDischargeInformation
        ? data.consentShareDischargeInformation
        : '';
    this.consentFutureEvaluation =
      data && data.consentFutureEvaluation
        ? data.consentFutureEvaluation
        : '';
    this.consentFutureStories =
      data && data.consentFutureStories
        ? data.consentFutureStories
        : '';
    this.consentFutureStoriesNotAppropriate =
      data && data.consentFutureStoriesNotAppropriate
        ? data.consentFutureStoriesNotAppropriate
        : ''
    this.dischargeLetterSent =
      data && data.dischargeLetterSent
        ? data.dischargeLetterSent
        : '';
    this.dischargeEvaluationDate =
      data && data.dischargeEvaluationDate
        ? data.dischargeEvaluationDate
        : '';
    this.dischargeSummary =
      data && data.dischargeSummary
        ? data.dischargeSummary
        : '';
  }
}
