<div class="filter-container">
  <div class="mb-3" *ngFor="let filter of filters">
    <label class="form-text">{{ filter.label }}</label>
    <ng-select
      [clearable]="true"
      [multiple]="filter.type == 'multi' ? true : false"
      [searchable]="false"
      [placeholder]="filter.placeholder"
      (change)="set($event, filter)"
      [(ngModel)]="filter.selectedIds"
    >
      <ng-option *ngFor="let option of filter.options" [value]="option.id">{{
        option.name
      }}</ng-option>
    </ng-select>
  </div>
</div>
