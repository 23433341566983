<div class="btn-group" role="group" aria-label="Basic example">
  <button type="button" class="btn btn-light" [class.selected]="mood==5" (click)="setMood(5)">
    <fa-icon [icon]="['fal', 'laugh-beam']" class="text-success me-1"></fa-icon>
  </button>
  <button type="button" class="btn btn-light" [class.selected]="mood==4" (click)="setMood(4)">
    <fa-icon [icon]="['fal', 'smile']" class="text-success me-1"></fa-icon>
  </button>
  <button type="button" class="btn btn-light" [class.selected]="mood==3" (click)="setMood(3)">
    <fa-icon [icon]="['fal', 'meh']" class="text-warning me-1"></fa-icon>
  </button>
  <button type="button" class="btn btn-light" [class.selected]="mood==2" (click)="setMood(2)">
    <fa-icon [icon]="['fal', 'frown']" class="text-danger me-1"></fa-icon>
  </button>
  <button type="button" class="btn btn-light" [class.selected]="mood==1" (click)="setMood(1)">
    <fa-icon [icon]="['fal', 'sad-tear']" class="text-danger me-1"></fa-icon>
  </button>
</div>
