import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnInit {
  
  @Input() label: string = '';
  @Input() data: {}[] = [];
  @Input() key: string = '';
  @Input() multi: boolean = false;

  @Output() update = new EventEmitter();

  selected: any[] = [];

  constructor() { }

  ngOnInit(): void {

  }

  toggle(option: any) {

    if(this.multi) {
      if(this.selected.includes(option)) {
        this.selected = this.selected.filter(o => o !== option);
      } else {
        this.selected.push(option);
      }
    } else {
      this.selected = [];
      this.selected.push(option);
    }

    this.update.emit({
      'key': this.key,
      'data': this.selected
    });
  }

}
