import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { dischargeForm } from '@app/forms/form-discharge';
import { Intervention, InterventionType } from '@app/models/intervention';
import { InterventionDischarge } from '@app/models/intervention-discharge';
import { Form, Question } from '@app/models/form';
import { ServiceUser } from '@app/models/service-user';
import { InterventionService } from '@app/services/intervention.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-discharge',
  templateUrl: './discharge.component.html',
  styleUrls: ['./discharge.component.scss'],
})
export class DischargeComponent implements OnInit {
  public onClose: EventEmitter<any> = new EventEmitter();

  formData: Question<any>[] = dischargeForm;

  user!: ServiceUser;
  intervention: Intervention<InterventionDischarge>;
  title!: string;

  loading = false;
  error = '';

  bsConfig = Object.assign({}, { containerClass: 'theme-default' });

  constructor(
    private router: Router,
    private interventionService: InterventionService,
    public bsModalRef: BsModalRef
  ) {
    this.intervention = new Intervention<InterventionDischarge>({
      type: InterventionType.Discharge,
    });
  }

  ngOnInit(): void {}

  handleSubmit(form: any) {
    form['type'] = 'Discharge';
    form['serviceUser'] = this.user;
    this.loading = true;
    this.interventionService.create(form).subscribe(
      (response) => {
        this.onClose.emit(true);
        this.bsModalRef.hide();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.error = 'Error creating the discharge. Please try again.';
      }
    );
  }
}
