<div class="mx-auto position-relative option-container">
  <progressbar
    class="bar"
    [style.width]="width"
    [style.left]="left"
    [value]="value"
  ></progressbar>
  <div class="row">
    <ng-container *ngFor="let s of stages; let i = index">
      <div
        *ngIf="s.type !== 'splash'"
        class="col position-relative"
        (click)="setStage(s, true)"
      >
        <span
          class="option"
          [class.cursor]="stages.indexOf(active) > stages.indexOf(s)"
          [class.option-primary]="stages.indexOf(active) > i"
        ></span>
        <p>{{ s.title | titlecase }}</p>
      </div>
    </ng-container>
  </div>
</div>
