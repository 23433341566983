<div class="card w-100 mb-2 cursor">
  <div class="card-body">

    <h5>Discharge</h5>

    <p class="fw-lighter mb-0" *ngIf="intervention.data.dischargeSummary">
      {{ intervention.data.dischargeSummary }}
    </p>

    <div class="key-pair" *ngIf="intervention.data.dischargeEvaluationDate">
      <span class="key">Consent to share with clinician</span>
      {{ intervention.data.consentShareDischargeInformation }}
    </div>

    <div class="key-pair">
      <span class="key">Consent to future evaluation</span>
      {{ intervention.data.consentFutureEvaluation }}
    </div>
    <div class="key-pair" *ngIf="intervention.data.dischargeEvaluationDate">
      <span class="key">Discharge evaluation date</span>
      {{ intervention.data.dischargeEvaluationDate | date }}
    </div>

    <div class="key-pair">
      <span class="key">Consent to future stories</span>
      {{ intervention.data.consentFutureStories }}
    </div>
    <div class="key-pair" *ngIf="intervention.data.consentFutureStoriesNotAppropriate">
      <span class="key">Reason for not appropriate</span>
      {{ intervention.data.consentFutureStoriesNotAppropriate }}
    </div>

    <div class="key-pair">
      <span class="key">Discharge letter sent</span>
      {{ intervention.data.dischargeLetterSent }}
    </div>

  </div>
</div>
