<div class="card cursor">
  <div class="card-body p-4 ">
    <h5 [class.mb-0]="!intervention.data.text">
      Refer On - {{ intervention.data.title | titlecase }}
    </h5>
    <p *ngIf="intervention.data.text" class="fw-lighter mb-0">
      {{ intervention.data.text }}
    </p>
  </div>
</div>
