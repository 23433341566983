import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { onboardingForm } from '@app/forms/form-onboarding';
import { Question } from '@app/models/form';
import { Intervention, InterventionType } from '@app/models/intervention';
import { InterventionOnboarding } from '@app/models/intervention-onboarding';
import { ServiceUser } from '@app/models/service-user';
import { InterventionService } from '@app/services/intervention.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  public onClose: EventEmitter<any> = new EventEmitter();

  formData: Question<any>[] = onboardingForm;

  title!: string;
  user!: ServiceUser;
  intervention: Intervention<InterventionOnboarding>;

  loading = false;
  error = '';

  bsConfig = Object.assign({}, { containerClass: 'theme-default' });

  constructor(
    private router: Router,
    private interventionService: InterventionService,
    public bsModalRef: BsModalRef
  ) {
    this.intervention = new Intervention<InterventionOnboarding>({
      type: InterventionType.Onboarding,
    });
  }

  ngOnInit(): void {}

  handleSubmit(form: any) {
    form['type'] = 'Onboarding';
    form['serviceUser'] = this.user;
    this.loading = true;
    this.interventionService.create(form).subscribe(
      (response) => {
        this.onClose.emit(true);
        this.bsModalRef.hide();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.error = 'Error while saving the form. Please try again.';
      }
    );
  }

  didNotPickup() {
    if (
      confirm('Are you sure you want to mark this call as unanswered?') === true
    ) {
      this.intervention.data.text = 'USER DID NOT PICKUP.';
      this.intervention.serviceUser = this.user;

      this.loading = true;
      this.interventionService.create(this.intervention).subscribe(
        (response) => {
          this.onClose.emit(true);
          this.bsModalRef.hide();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.error = 'Error while saving the form. Please try again.';
        }
      );
    }
  }
}
