export class History {
  primaryCondition: string[];
  primaryConditionOther: string;
  interventions: string[];
  otherRelevantConditions: string[];
  otherRelevantConditionsOther: string;
  riskFactors: string[];
  impairments: string[];
  impairmentsDetail: string;
  diagnosisConfirmed?: boolean;
  peIhdMalignancyDifferentialDiagnosisExcluded?: boolean;
  longCovidSymptoms: string[];
  longCovidSymptomDetails: string;
  spo2?: number;
  respirationRate?: number;
  heartRate?: number;
  bloodPressure: string;
  physiologyNotes: string;
  bloodTests: string[];
  bloodTestNotes: string;
  investigations: string[];
  investigationsNotes: string;

  constructor(data?: any) {
    this.primaryCondition =
      data && data.primaryCondition ? data.primaryCondition : [];
    this.primaryConditionOther =
      data && data.primaryConditionOther ? data.primaryConditionOther : '';
    this.interventions = data && data.interventions ? data.interventions : [];
    this.otherRelevantConditions =
      data && data.otherRelevantConditions ? data.otherRelevantConditions : [];
    this.otherRelevantConditionsOther =
      data && data.otherRelevantConditionsOther
        ? data.otherRelevantConditionsOther
        : '';
    this.riskFactors = data && data.riskFactors ? data.riskFactors : [];
    this.impairments = data && data.impairments ? data.impairments : [];
    this.impairmentsDetail =
      data && data.impairmentsDetail ? data.impairmentsDetail : '';

    this.diagnosisConfirmed =
      data && data.diagnosisConfirmed ? data.diagnosisConfirmed : undefined;
    this.peIhdMalignancyDifferentialDiagnosisExcluded =
      data && data.peIhdMalignancyDifferentialDiagnosisExcluded
        ? data.peIhdMalignancyDifferentialDiagnosisExcluded
        : undefined;
    this.longCovidSymptoms =
      data && data.longCovidSymptoms ? data.longCovidSymptoms : [];
    this.longCovidSymptomDetails =
      data && data.longCovidSymptomDetails ? data.longCovidSymptomDetails : '';

    this.spo2 = data && data.spo2 ? data.spo2 : undefined;
    this.respirationRate =
      data && data.respirationRate ? data.respirationRate : undefined;
    this.heartRate = data && data.heartRate ? data.heartRate : undefined;
    this.bloodPressure =
      data && data.bloodPressure ? data.bloodPressure : undefined;

    this.physiologyNotes =
      data && data.physiologyNotes ? data.physiologyNotes : '';
    this.bloodTests = data && data.bloodTests ? data.bloodTests : [];
    this.bloodTestNotes =
      data && data.bloodTestNotes ? data.bloodTestNotes : '';
    this.investigations =
      data && data.investigations ? data.investigations : [];
    this.investigationsNotes =
      data && data.investigationsNotes ? data.investigationsNotes : '';
  }
}
