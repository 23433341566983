<div class="card">
  <div class="card-body">
    <h5 [class.text-danger]="didNotPickup" [class.mb-0]="didNotPickup">
      First Contact

      <span class="float-end text-form text-danger pe-2" *ngIf="didNotPickup">
        Did not pickup
      </span>
    </h5>
    <div class="row" *ngIf="!didNotPickup">
      <div class="col" [class.col-6]="intervention.data.text">
        <div class="key-pair">
          <span class="key">Contact within 5 working days</span>
          {{ intervention.data.contact5WorkingDays }}
        </div>
        <div
          class="key-pair"
          *ngIf="intervention.data.contact5WorkingDays == 'No'"
        >
          <span class="key">Contact delay reason</span>
          {{ intervention.data.contact5WorkingDaysNo }}
        </div>
        <div class="key-pair" *ngIf="intervention.data.attemptNumber">
          <span class="key">Attempt number</span>
          {{ intervention.data.attemptNumber }}
        </div>

        <div class="key-pair">
          <span class="key">Telephone support service explained</span>
          {{ intervention.data.telephoneSupportExplained ? "Yes" : "No" }}
        </div>
        <div class="key-pair">
          <span class="key">GDPR consent given</span>
          {{ intervention.data.gdprServiceUserConsent ? "Yes" : "No" }}
        </div>

        <div class="key-pair">
          <span class="key">Adviceline contact information provided</span>
          {{ intervention.data.adviceLineInformationProvided ? "Yes" : "No" }}
        </div>
        <div class="key-pair" *ngIf="intervention.data.serviceDeclined">
          <span class="key">Service Declined</span>
          {{ intervention.data.serviceDeclined }}
        </div>
        <div class="key-pair" *ngIf="intervention.data.preferredCallFrequency">
          <span class="key">Preferred Frequency of calls</span>
          {{ intervention.data.preferredCallFrequency }}
        </div>
        <div class="key-pair" *ngIf="intervention.data.preferredCallDays">
          <span class="key">Preferred day of the week</span>
          {{ intervention.data.preferredCallDays }}
        </div>

        <div
          class="key-pair"
          *ngIf="intervention.data.preferredCallTimes != 'Other'"
        >
          <span class="key">Preferred call time</span>
          {{ intervention.data.preferredCallTimes }}
        </div>
        <div
          class="key-pair"
          *ngIf="intervention.data.preferredCallTimes == 'Other'"
        >
          <span class="key">Preferred call time</span>
          {{ intervention.data.preferredCallTimesOther }}
        </div>

        <div class="key-pair" *ngIf="intervention.data.nextCall">
          <span class="key">Next call date</span>
          {{ intervention.data.nextCall | date }}
        </div>

        <div class="key-pair">
          <span class="key">Admission letter sent</span>
          {{ intervention.data.admissionLetterSent ? "Yes" : "No" }}
        </div>
      </div>
      <div class="col-6" *ngIf="intervention.data.text">
        {{ intervention.data.text }}
      </div>
    </div>
  </div>
</div>
