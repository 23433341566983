import { Component, Input, OnInit } from '@angular/core';
import { Intervention } from '@app/models/intervention';
import { InterventionOnboarding } from '@app/models/intervention-onboarding';

@Component({
  selector: 'app-summary-onboarding',
  templateUrl: './summary-onboarding.component.html',
  styleUrls: ['./summary-onboarding.component.scss'],
})
export class SummaryOnboardingComponent implements OnInit {
  @Input() intervention!: Intervention<InterventionOnboarding>;
  didNotPickup = false;

  constructor() {}

  ngOnInit(): void {
    if (this.intervention.data.text.startsWith('USER DID NOT PICKUP')) {
      this.didNotPickup = true;
    }
  }
}
