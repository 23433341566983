<div class="spinner-border text-primary" role="status" *ngIf="loading.goals">
  <span class="visually-hidden">Loading...</span>
</div>
<ng-container *ngIf="!loading.goals">
  <ng-template #modal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <ng-container *ngIf="edit">Edit Goal</ng-container>
        <ng-container *ngIf="!edit">Create Goal</ng-container>
      </h4>
      <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="goalForm" class="form">
        <div class="mb-3">
          <label for="endTime" class="form-label w-100">
            Type
            <span class="text-primary" *ngIf="isRequiredField('type')">*</span>
            <span
              class="float-end text-danger"
              *ngIf="!f.type?.valid && (f.type?.dirty || f.type?.touched)"
            >
              <span [hidden]="!f.type.errors?.required"> Required </span>
            </span>
          </label>
          <ng-select
            #goalSelect
            class="mb-2"
            style="width: 100%"
            formControlName="type"
            [items]="goalOptions"
            [clearable]="false"
            [searchable]="false"
            [placeholder]="'Select goal area...'"
            [class.is-invalid]="f.type?.touched && f.type?.errors"
          >
          </ng-select>
        </div>
        <div class="mb-3">
          <label for="endTime" class="form-label w-100">
            Description
            <span class="text-primary" *ngIf="isRequiredField('description')"
              >*</span
            >
            <span
              class="float-end text-danger"
              *ngIf="
                !f.description?.valid &&
                (f.description?.dirty || f.description?.touched)
              "
            >
              <span [hidden]="!f.description.errors?.required"> Required </span>
            </span>
          </label>

          <input
            formControlName="description"
            type="text"
            aria-label="Goal description"
            class="mb-2 form-control"
            [class.is-invalid]="f.description?.touched && f.description?.errors"
            placeholder="Be able to walk to the shops daily"
          />
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-primary float-start me-2"
        (click)="submit()"
        [disabled]=""
      >
        <ng-container *ngIf="edit">Save</ng-container>
        <ng-container *ngIf="!edit">Create Goal</ng-container>
      </button>
      <button
        type="button"
        class="btn btn-secondary pull-right"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        Cancel
      </button>
    </div>
  </ng-template>

  <h4>
    <span *ngIf="showTitle">Goals ({{ goals.length }})</span>
    <span
      class="btn btn-primary btn-sm"
      [class.float-end]="showTitle"
      (click)="newGoal(modal)"
    >
      + Add Goal
    </span>
  </h4>

  <div class="w-100 mt-4 clearfix">
    <ul class="list-group mb-4">
      <li
        (click)="toggle(goal, showStatus)"
        class="cursor list-group-item"
        *ngFor="let goal of goals"
      >
        <span
          class="cursor h5 d-inline-block"
          style="padding-top: 8px; margin-bottom: 0"
        >
          <span class="badge bg-secondary" *ngIf="!goal.id && showStatus"
            >Draft
          </span>
          {{ goal.type }}:
          <span class="form-text">{{ goal.description }}</span></span
        >
        <div
          class="btn-group float-end"
          (click)="stopPropagation($event)"
          *ngIf="showStatus"
        >
          <button
            type="button"
            class="btn dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            [ngClass]="{
              'alert-secondary': goal.status === 'Ongoing',
              'alert-warning': goal.status === 'Partially Complete',
              'alert-success': goal.status === 'Complete',
              'alert-danger': goal.status === 'Closed'
            }"
          >
            {{ goal.status }}
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li *ngFor="let status of statusOptions">
              <a
                class="dropdown-item"
                href="#"
                (click)="setStatus(goal, status, $event)"
              >
                {{ status }}
              </a>
            </li>
            <ng-container *ngIf="!goal.id">
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="#"
                  (click)="editGoal(goal, $event, modal)"
                  >Edit</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item text-danger"
                  href="#"
                  (click)="removeGoal(goal, $event)"
                  >Remove</a
                >
              </li>
            </ng-container>
            <ng-container *ngIf="goal.id">
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <span class="dropdown-item text-muted"
                  >Published goals can't be edited</span
                >
              </li>
            </ng-container>
          </ul>
        </div>

        <div (click)="stopPropagation($event)">
          <div
            *ngIf="goal.loading.goal"
            class="spinner-border spinner-sm text-secondary float-end"
            role="status"
            style="margin-top: 7px; margin-right: 10px"
          >
            <span class="visually-hidden">Loading...</span>
          </div>

          <div [collapse]="goal.isCollapsed" [isAnimated]="true" class="w-100">
            <div class="p-3">
              <textarea
                [(ngModel)]="goal.notes[goal.notes.length - 1].text"
                #noteEditor
                rows="5"
                class="form-control mt-3 mb-3"
                placeholder="Add a note..."
                (keyup)="autoGrow($event)"
                (ngModelChange)="goalNoteChanging()"
              ></textarea>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-container>
