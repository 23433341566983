import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Goal, GoalNote } from '@models/goal';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoalService {
  constructor(
    private http: HttpClient
  ) { }

  public get(id: number): Observable<Goal[]> {
    return this.http.get<Goal[]>(
      environment.apiUrl + '/goals/' + id
    );
  }

  public create(
    userId: number,
    goal: Goal
  ): Observable<Goal> {
    return this.http.post<Goal>(
      environment.apiUrl + '/goals/' + userId,
      goal
    );
  }

  public update(serviceUserId: number, goal: Goal): Observable<Goal> {
    return this.http.put<Goal>(
      environment.apiUrl + '/goals/' + serviceUserId + '/' + goal.id,
      goal
    );
  }

  public delete(id: number): Observable<any> {
    return this.http.delete<any>(
      environment.apiUrl + '/goals/' + IDBCursor
    );
  }

  public createNote(goalId: number, note: GoalNote): Observable<Goal> {
    return this.http.post<Goal>(
      environment.apiUrl + '/goals/notes/' + goalId,
      note
    );
  }
}
