import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Call } from '@app/models/call';
import { Intervention, InterventionType } from '@app/models/intervention';
import { InterventionDischarge } from '@app/models/intervention-discharge';
import { InterventionNote } from '@app/models/intervention-note';
import { InterventionReferOn } from '@app/models/intervention-refer-on';
import { ReferralCardiac } from '@app/models/intervention-referral-cardiac';
import { ReferralIcu } from '@app/models/intervention-referral-icu';
import { ReferralLongCovid } from '@app/models/intervention-referral-long-covid';
import { ReferralRespiratory } from '@app/models/intervention-referral-respiratory';
import { InterventionSignpost } from '@app/models/intervention-signpost';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InterventionService {
  constructor(private http: HttpClient) {}

  public get(id: number): Observable<Intervention<any>> {
    //return of(serviceUserSeed).pipe(delay(500));

    return this.http.get<Intervention<any>>(
      environment.apiUrl + '/interventions/' + id
    );
  }

  public create(
    intervention: Intervention<any>
  ): Observable<Intervention<any>> {
    //return of(serviceUserSeed).pipe(delay(500));

    return this.http.post<Intervention<any>>(
      environment.apiUrl + '/interventions/',
      intervention
    );
  }

  public put(intervention: Intervention<any>): Observable<Intervention<any>> {
    //return of(serviceUserSeed).pipe(delay(500));

    return this.http.post<Intervention<any>>(
      environment.apiUrl + '/interventions/' + intervention.id,
      intervention
    );
  }

  public delete(id: number): Observable<any> {
    //return of(serviceUserSeed).pipe(delay(500));

    return this.http.delete<any>(environment.apiUrl + '/interventions/' + id);
  }

  public getTypedIntervention(
    intervention: Intervention<any>
  ): Intervention<any> {
    switch (intervention.type) {
      case InterventionType.Note:
        return new Intervention<InterventionNote>(intervention);
      case InterventionType.Discharge:
        return new Intervention<InterventionDischarge>(intervention);
      case InterventionType.ReferOn:
        return new Intervention<InterventionReferOn>(intervention);
      case InterventionType.ReferralCardiac:
        return new Intervention<ReferralCardiac>(intervention);
      case InterventionType.ReferralRespiratory:
        return new Intervention<ReferralRespiratory>(intervention);
      case InterventionType.ReferralLongCovid:
        return new Intervention<ReferralLongCovid>(intervention);
      case InterventionType.ReferralIcu:
        return new Intervention<ReferralIcu>(intervention);
      case InterventionType.Signposting:
        return new Intervention<InterventionSignpost>(intervention);
      case InterventionType.Call:
        return new Intervention<Call>(intervention);
      default:
        return intervention;
    }
  }

  public interventionCallCount(interventions?: Intervention<any>[]): number {
    if (interventions && interventions.length > 0) {
      return interventions.filter(
        (i) =>
          i.type === InterventionType.Call ||
          i.type === InterventionType.Onboarding
      ).length;
    }
    return 0;
  }

  public interventionUnansweredCallCount(
    interventions?: Intervention<any>[]
  ): number {
    if (interventions && interventions.length > 0) {
      return interventions.filter(
        (i) =>
          (i.type === InterventionType.Call &&
            i.data.generalCallNotes.startsWith('USER DID NOT PICKUP.')) ||
          (i.type === InterventionType.Onboarding &&
            i.data.text.startsWith('USER DID NOT PICKUP.'))
      ).length;
    }
    return 0;
  }

  public interventionUnansweredConsecutiveCallCount(
    interventions?: Intervention<any>[]
  ): number {
    let maxUnanswered = 0;
    let unansweredRun = 0;
    if (interventions && interventions.length > 0) {
      const callInterventions = interventions.filter(
        (i) =>
          i.type === InterventionType.Call ||
          i.type === InterventionType.Onboarding
      );

      callInterventions.map((call) => {
        if (
          (call.type === InterventionType.Call &&
            call.data.generalCallNotes.startsWith('USER DID NOT PICKUP.')) ||
          (call.type === InterventionType.Onboarding &&
            call.data.text.startsWith('USER DID NOT PICKUP.'))
        ) {
          unansweredRun++;
          if (unansweredRun > maxUnanswered) {
            maxUnanswered = unansweredRun;
          }
        } else {
          unansweredRun = 0;
        }
      });
    }
    return maxUnanswered;
  }

  public interventionUnansweredConsecutiveCallRecentCount(
    interventions?: Intervention<any>[]
  ): number {
    let unansweredRun = 0;
    let foundAnsweredCall = false;
    if (interventions && interventions.length > 0) {
      const callInterventions = interventions.filter(
        (i) =>
          i.type === InterventionType.Call ||
          i.type === InterventionType.Onboarding
      );

      callInterventions.map((call) => {
        if (
          foundAnsweredCall === false &&
          ((call.type === InterventionType.Call &&
            call.data.generalCallNotes.startsWith('USER DID NOT PICKUP.')) ||
            (call.type === InterventionType.Onboarding &&
              call.data.text.startsWith('USER DID NOT PICKUP.')))
        ) {
          unansweredRun++;
        } else {
          foundAnsweredCall = true;
        }
      });
    }
    return unansweredRun;
  }
}
