import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Topic, TopicType } from '@app/models/call';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as _ from 'lodash';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss'],
})
export class TopicsComponent implements ControlValueAccessor {
  @ViewChild('topicSelect') goalSelect!: NgSelectComponent;
  @ViewChild('note') noteEditor!: ElementRef;
  @Input() topics: Topic[] = [];
  @Output() topicsChange = new EventEmitter();

  onChange = (data: any) => {};
  onTouched = () => {};

  form!: FormGroup;
  topicOptions = Object.values(TopicType);
  topic = new Topic();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [this.topic.name, [Validators.required]],
      text: [this.topic.text, [Validators.required]],
    });
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  isRequiredField(field: string) {
    const form_field = this.form.get(field);
    if (!form_field?.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator.required;
  }

  remove(topic: Topic, e: any) {
    if (confirm('Are you sure to remote topic: ' + topic.name)) {
      e.preventDefault();

      _.remove(this.topics, topic);

      this.update();
    }
  }

  new() {
    this.topics.push(new Topic());
  }

  update() {
    this.onChange(this.topics);
    this.topicsChange.emit(this.topics);
  }

  writeValue(topics: Topic[]) {
    this.topics = topics;
  }

  topicUpdated() {
    this.update();
  }
}
