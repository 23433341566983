import { Stage, StagedForm } from '@app/models/form';

import { formServiceUser } from './form-service-user';

const serviceUserFormStages: Stage[] = [
  {
    id: 'patient-details',
    title: 'Personal details',
    description:
      "Please include as much information as you can about the patient's contact details. Please note this is a telephone support service and calls may be shown on mobiles as coming from a withheld number.",
    type: 'form',
    questions: formServiceUser,
  },
];

export const serviceUserForm: StagedForm = {
  type: 'serviceUser',
  stages: serviceUserFormStages,
};
