import {
  QuestionCheckbox,
  QuestionMultiSelect,
  QuestionDate,
  QuestionDateOfBirth,
  QuestionDropdown,
  QuestionRadio,
  QuestionTextarea,
  QuestionTextbox,
  Stage,
  StagedForm,
  QuestionGoals,
  QuestionHTML,
} from '@app/models/form';

import {
  currentSupportOptions,
  primaryConditionCovidOptions,
  longCovidSymptomOptions,
  bloodTestOptions,
  investigationOptions,
  riskFactorsOptions,
  impairmentsOptions,
  otherRelevantHealthConditionsCovidOptions,
  facilitationIcuOptions,
} from '@app/helpers/form-input-data';
import { patterns } from '@app/helpers/validators';
import { Validators } from '@angular/forms';
import { formReferrer } from './form-referrer';
import { formServiceUser } from './form-service-user';

const formStages: Stage[] = [
  {
    id: 'introduction',
    title: 'CHSS Long Covid Referral',
    description:
      'Before completing the referral please read the service description to ensure your patient is eligible.',
    type: 'form',
    questions: [
      new QuestionHTML({
        key: 'intro',
        label: `
        <h6 class="text-primary">Service Details and Eligibility</strong></h6>
        <p>This service aims to improve the quality of life for those diagnosed with Long Covid by providing an enhanced pathway for those who have experienced a period of time under the care of Pulmonary Rehabilitation or General Practice.  The intervention will be delivered through a course of telephone calls for a period of 6 weeks extending to 12 weeks as required. A person-centred approach will be implemented, offering a flexible service delivery model titrated to the patients need.</p>
        <p class="text-italic">The care is delivered through a series of telephone consultations focusing on the physiological recovery, psychological and social adjustment to the reality of Long COVID.</p>
        <p>Your NHS Board is working in partnership with CHSS and has the appropriate information governance arrangements in place to enable you to refer people within the agree inclusion/exclusion criteria for support via our team of Nurses and Allied Health Professionals on the charity’s Advice Line via this referral process.</p>
        <p>The intervention will be guided by the following referral process;</p>
        <h6 class="text-primary">Roles and responsibilities of the NHS</h6>
        <ul>
          <li>Identify patients with a diagnosis of long-Covid</li>
          <li>Ensure patient adheres to agreed inclusion / exclusion criteria</li>
          <li>Refer patient using the appropriate process detailed in this link</li>
        </ul>
        <h6 class="text-primary">Roles and responsibilities of Chest Heart & Stroke Scotland</h6>
        <ul>
          <li>Contact patient within 5 working days</li>
          <li>Provide an overview of the service aims, identify personal goals and document support plan</li>
          <li>Tailor intervention to the individuals need</li>
          <li>Undertake calls, reviewing and documenting the patient’s progress</li>
          <li>Implement a service discharge plan, titrating the  intervention as required prior to service cessation</li>
          <li>Agree and undertake the final review, completing the outcomes record</li>
          <li>Secure data and store for 12 months following discharge. Following that time, the data will be anonymised and retained for a period of 6 years.</li>
        </ul>
        <p>The following inclusion and exclusion criteria should be implemented to protect the patient and manage expectations.</p>
        <h6 class="text-primary">Inclusion</h6>
        <ul>
          <li>Confirmed diagnosis of Long Covid</li>
          <li>Discharged from hospital/or diagnosis confirmed in the community</li>
          <li>Requiring physiological, psychological, and/ or social support</li>
        </ul>
        <h6 class="text-primary">Exclusion</h6>
        <ul>
          <li>Requiring end of life care</li>
          <li>Decreased cognition with significant decline in short-term memory</li>
          <li>Significant psychological/ psychiatric disorders requiring specialist support</li>
        </ul>
        <p class="alert alert-warning">If the referral is out with the remit of this service and does not adhere to the above criteria, then the referral may not be accepted. If the individual is deemed inappropriate for this service, they may be triaged on to other CHSS support which would better support their needs.</p>
        `,
        payloadIgnore: true,
      }),
    ],
  },

  {
    id: 'patient-details',
    title: 'Patient Details',
    description:
      "Please include as much information as you can about the patient's contact details. Please note this is a telephone support service and calls may be shown on mobiles as coming from a withheld number.",
    type: 'form',
    questions: formServiceUser,
  },
  {
    id: 'patient-condition',
    title: 'Medical Condition',
    description:
      'Please include as much information as you can about the patient’s primary condition for referral) and include any relevant past medical data.history.',
    type: 'form',
    questions: [
      new QuestionMultiSelect({
        key: 'primaryCondition',
        path: 'data.history.primaryCondition',
        label: 'Primary Condition',
        required: true,
        options: primaryConditionCovidOptions,
      }),
      new QuestionTextarea({
        key: 'primaryConditionOther',
        path: 'data.history.primaryConditionOther',
        label: 'Other Primary Condition',
        required: true,
        displayConditions: [
          {
            key: 'primaryCondition',
            values: ['Other'],
          },
        ],
      }),
      new QuestionCheckbox({
        key: 'peIhdMalignancyDifferentialDiagnosisExcluded',
        path: 'data.history.peIhdMalignancyDifferentialDiagnosisExcluded',
        label: 'PE, IHD Malignancy & differential diagnosis excluded',
        required: true,
      }),
      new QuestionCheckbox({
        key: 'diagnosisConfirmedByClinician',
        path: 'data.history.diagnosisConfirmedByClinician',
        label: 'Diagnosis/presumed Long Covid confirmed by Clinician',
        required: true,
      }),
      new QuestionMultiSelect({
        key: 'longCovidSymptoms',
        path: 'data.history.longCovidSymptoms',
        label: 'Long-Covid Support Requested',
        required: true,
        options: longCovidSymptomOptions,
      }),
      new QuestionTextarea({
        key: 'longCovidSymptomDetails',
        path: 'data.history.longCovidSymptomDetails',
        label:
          'Other symptom details (please refer to CHSS inclusion & Exclusion Criteria to ensure appropriate referral)',

        required: true,
        displayConditions: [
          {
            key: 'longCovidSymptoms',
            values: ['Other'],
          },
        ],
      }),
      new QuestionHTML({
        key: 'physiologyTitle',
        label:
          '<h6 class="text-primary">Investigations since ongoing symptoms to date</h6>',
        payloadIgnore: true,
      }),
      new QuestionTextbox({
        key: 'spO2',
        path: 'data.history.spo2',
        label: 'SpO2 on air',
        placeholder: 'Percentage 0-100',
        validators: [
          Validators.pattern(patterns.number),
          Validators.min(0),
          Validators.max(100),
        ],
        unit: '%',
      }),
      new QuestionTextbox({
        key: 'respirationRate',
        path: 'data.history.respirationRate',
        label: 'Respiration Rate',
        placeholder: 'Breaths per minute 0-50',
        validators: [
          Validators.pattern(patterns.number),
          Validators.min(0),
          Validators.max(50),
        ],
        unit: 'breaths per minute',
      }),
      new QuestionTextbox({
        key: 'heartRate',
        path: 'data.history.heartRate',
        label: 'Heart Rate',
        placeholder: 'Beats per minute 0-200',
        validators: [
          Validators.pattern(patterns.number),
          Validators.min(0),
          Validators.max(200),
        ],
        unit: 'beats per minute',
      }),
      new QuestionTextbox({
        key: 'bloodPressure',
        path: 'data.history.bloodPressure',
        label: 'Blood Pressure',
        placeholder: 'systolic/diastolic e.g 140/70',
        validators: [Validators.pattern(patterns.bloodPressure)],
        unit: 'mmhg',
      }),
      new QuestionTextarea({
        key: 'physiologyNotes',
        path: 'data.history.physiologyNotes',
        label: 'Physiology notes (Please provides details of patient normals)',
        displayConditions: [
          {
            key: 'bloodPressure',
            values: ['any'],
          },
          {
            key: 'heartRate',
            values: ['any'],
          },
          {
            key: 'respirationRate',
            values: ['any'],
          },
          {
            key: 'spo2',
            values: ['any'],
          },
        ],
      }),
      new QuestionMultiSelect({
        key: 'bloodTests',
        path: 'data.history.bloodTests',
        label: 'Blood tests performed',
        options: bloodTestOptions,
      }),
      new QuestionTextarea({
        key: 'bloodTestNodes',
        path: 'data.history.bloodTestNodes',
        label: 'Blood test notes',
        placeholder: 'Please provide details of any abnormal results',
        validators: [Validators.maxLength(500)],
        displayConditions: [
          {
            key: 'bloodTests',
            values: ['any'],
          },
        ],
      }),
      new QuestionMultiSelect({
        key: 'investigations',
        path: 'data.history.investigations',
        label: 'Other investigations',
        options: investigationOptions,
      }),
      new QuestionTextarea({
        key: 'investigationsNotes',
        path: 'data.history.investigationsNotes',
        label: 'Investigation notes',
        validators: [Validators.maxLength(500)],
        placeholder: 'Please provide details of any abnormal results',
        displayConditions: [
          {
            key: 'investigations',
            values: ['any'],
          },
        ],
      }),
      new QuestionMultiSelect({
        key: 'otherRelevantConditions',
        path: 'data.history.otherRelevantConditions',
        label: 'Other relevant health conditions',
        options: otherRelevantHealthConditionsCovidOptions,
      }),
      new QuestionTextarea({
        key: 'otherRelevantConditionsOther',
        path: 'data.history.otherRelevantConditionsOther',
        label: 'Other Relevant Health Conditions (Other)',
        required: true,
        validators: [Validators.maxLength(200)],
        displayConditions: [
          {
            key: 'otherRelevantConditions',
            values: ['Other'],
          },
        ],
      }),
      new QuestionMultiSelect({
        key: 'riskFactors',
        path: 'data.history.riskFactors',
        label: 'Risk factors',
        options: riskFactorsOptions,
      }),
      new QuestionMultiSelect({
        key: 'impairments',
        path: 'data.history.impairments',
        label: 'Impairments',
        options: impairmentsOptions,
        required: true,
      }),
      new QuestionHTML({
        key: 'impairmentsExclusionsWarning',
        label: '<span class="text-primary">Please note:</span>',
        payloadIgnore: true,
        displayConditions: [
          {
            key: 'impairments',
            values: ['Hearing', 'Cognitive'],
          },
        ],
      }),
      new QuestionHTML({
        key: 'impairmentsExclusions',
        label:
          '<li>Decreased cognition with significant decline in short-term memory is an exclusion for this service</li>',
        payloadIgnore: true,
        displayConditions: [
          {
            key: 'impairments',
            values: ['Cognitive'],
          },
        ],
      }),
      new QuestionHTML({
        key: 'impairmentsExclusions',
        label: '<li>The service is delivered by telephone</li>',
        payloadIgnore: true,
        displayConditions: [
          {
            key: 'impairments',
            values: ['Hearing'],
          },
        ],
      }),
      new QuestionHTML({
        key: 'impairmentsExclusions',
        label: '<div class="mb-3"></div>',
        payloadIgnore: true,
        displayConditions: [
          {
            key: 'impairments',
            values: ['Hearing', 'Cognitive'],
          },
        ],
      }),

      new QuestionTextarea({
        key: 'impairmentsDetails',
        path: 'data.history.impairmentsDetails',
        label: 'Impairment Details',
        validators: [Validators.maxLength(500)],
        required: true,
        displayConditions: [
          {
            key: 'impairments',
            values: [
              'Sight',
              'Hearing',
              'Speech',
              'Mobility',
              'Cognitive',
              'Other',
            ],
          },
        ],
      }),
    ],
  },
  {
    id: 'referrer-details',
    title: 'Referrer Details',
    description:
      'Please complete your details as the referring clinician we may need to contact you to clarify any information or if we have any concerns about the serviceUser.',
    type: 'form',
    questions: formReferrer,
  },
  {
    id: 'goals',
    title: 'Goals',
    description:
      'We request that referrals include SMART goals – 2 or 3 to work on initially which lead on from what they have been doing with their referrer. We may eventually work on other goals as appropriate (e.g. physical activity – Aiming to resume walking the dog twice a day, for 20 minutes).',
    type: 'form',
    questions: [
      new QuestionGoals({
        key: 'goals',
        label: 'Goals',
        path: 'data.goals',
        required: true,
      }),
    ],
  },
  {
    id: 'additional-information',
    title: 'Additional Information',
    description:
      'Please include any other relevant information regarding the patients support needs and highlight any current support that is in place. Ensure that the patient is happy for their information to be shared with CHSS.',
    type: 'form',
    questions: [
      new QuestionMultiSelect({
        key: 'facilitation',
        label: 'Facilitation',
        path: 'data.additionalInformation.facilitation',
        required: true,
        options: facilitationIcuOptions,
      }),

      new QuestionTextbox({
        key: 'facilitationTailoredTalks',
        label: 'Please provide number of the Tailored Talk',
        path: 'data.additionalInformation.facilitationTailoredTalks',
        required: true,
        validators: [Validators.maxLength(100)],
        displayConditions: [
          {
            key: 'facilitation',
            values: ['Tailored Talks'],
          },
        ],
      }),
      new QuestionTextarea({
        key: 'facilitationOther',
        label: 'Any other comments',
        path: 'data.additionalInformation.facilitationOther',
        required: true,
        validators: [Validators.maxLength(500)],
        displayConditions: [
          {
            key: 'facilitation',
            values: ['Other'],
          },
        ],
      }),
      new QuestionMultiSelect({
        key: 'currentSupport',
        label: 'What support is the patient currently receiving?',
        path: 'data.additionalInformation.currentSupport',
        options: currentSupportOptions,
      }),
      new QuestionTextarea({
        key: 'currentSupportOther',
        label: 'What support is the patient currently receiving?',
        path: 'data.additionalInformation.currentSupportOther',
        validators: [Validators.maxLength(500)],
        required: true,
        displayConditions: [
          {
            key: 'currentSupport',
            values: ['Other'],
          },
        ],
      }),
      new QuestionTextarea({
        key: 'otherComments',
        label: 'Other comments',
        path: 'data.additionalInformation.otherComments',
        validators: [Validators.maxLength(500)],
      }),

      new QuestionCheckbox({
        key: 'clinicalReviewScheduled',
        label: 'Is a clinical review planned?',
        path: 'data.additionalInformation.clinicalReviewScheduled',
      }),
      new QuestionTextbox({
        key: 'clinicalReviewDate',
        path: 'data.additionalInformation.clinicalReviewDate',
        label: 'Weeks until next clinical review',
        displayConditions: [
          {
            key: 'clinicalReviewScheduled',
            values: [true],
          },
        ],
      }),

      new QuestionDate({
        key: 'dateOfDischarge',
        label: 'Date of discharge',
        path: 'data.additionalInformation.dateOfDischarge',
      }),
      new QuestionCheckbox({
        key: 'gdprConsentGiven',
        label: 'Consent to share info with CHSS given',
        path: 'data.additionalInformation.gdprConsentGiven',
      }),
    ],
  },
];

export const longCovidForm: StagedForm = {
  type: 'long-covid',
  stages: formStages,
};
