import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Form, Question } from '@app/models/form';
import { StageService } from '@app/services/stage.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  @Input() questions!: Question<any>[];
  @Input() loading: boolean = false;
  @Input() error: string = '';
  @Output() submit = new EventEmitter();

  form!: FormGroup;

  constructor(public stageService: StageService) {}

  ngOnInit(): void {
    this.form = this.stageService.toFormGroup(this.questions);
  }

  submitForm(event: any) {
    if (this.stageService.validate2(this.form, this.questions)) {
      this.submit.emit(
        this.stageService.toServerPayload2(this.form, this.questions)
      );
    }
  }
}
