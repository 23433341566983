<div class="test-site" *ngIf="environment.environment === 'test'">
  <h6 class="text-center">THIS IS THE TEST ENVIRONMENT</h6>
</div>

<header class="bg-white px-5 py-3">
  <div class="container">
    <a [routerLink]="['/']"
      ><img
        alt="Daysix"
        style="height: 60px"
        src="//www.chss.org.uk/wp-content/supportus-uploads/CHSS-logo-2018.png"
    /></a>

    <div class="inline-block float-end" *ngIf="authService.isLoggedIn()">
      <fa-stack class="float-end">
        <fa-icon
          [icon]="['fas', 'circle']"
          stackItemSize="2x"
          class="text-primary"
        ></fa-icon>
        <fa-icon
          [icon]="['fas', 'user']"
          [inverse]="true"
          stackItemSize="1x"
          class="text-white"
        ></fa-icon>
      </fa-stack>
      <div style="text-align: right; padding-right: 50px">
        {{ user.displayName }}<br /><a
          [routerLink]="['/logout']"
          class="text-primary small"
          >Logout</a
        >
      </div>
    </div>

    <div class="inline-block float-end" *ngIf="!authService.isLoggedIn()">
      <a class="btn btn-primary" [routerLink]="['/login']">Login</a>
    </div>

    <!-- <pre><small>{{ this.user | json }} </small></pre> -->
  </div>
</header>
<nav class="navbar bg-light" *ngIf="authService.isLoggedIn()">
  <div class="container">
    <ul class="list-unstyled mb-0 w-100">
      <li>
        <a [routerLink]="['/service-users']" [routerLinkActive]="'active'"
          >Service Users</a
        >
      </li>
      <li>
        <a [routerLink]="['/referrals']" [routerLinkActive]="'active'"
          >Referrals</a
        >
      </li>
      <li>
        (
        <a [routerLink]="['/referrals/cardiac']" [routerLinkActive]="'active'"
          >Cardiac</a
        >
      </li>
      <li>
        <a [routerLink]="['/referrals/covid']" [routerLinkActive]="'active'"
          >Long Covid</a
        >
      </li>
      <li>
        <a
          [routerLink]="['/referrals/respiratory']"
          [routerLinkActive]="'active'"
          >Respiratory</a
        >
      </li>
      <li>
        <a [routerLink]="['/referrals/icu']" [routerLinkActive]="'active'"
          >ICU</a
        >
        )
      </li>
    </ul>
  </div>
</nav>
<div class="bg-light"></div>
