<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{ title }}</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    (click)="bsModalRef.hide()"
    aria-label="Close"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="mb-3">
    <form [formGroup]="form" class="form">
      <div class="mb-3">
        <label for="note" class="form-label w-100">
          Note
          <span class="text-primary" *ngIf="isRequiredField('telephone')"
            >*</span
          >
          <span
            class="float-end text-danger"
            *ngIf="!f.note?.valid && (f.note?.dirty || f.note?.touched)"
          >
            <span [hidden]="!f.note.errors?.required"> Note is required </span>
          </span>
        </label>
        <div class="position-relative">
          <textarea
            id="note"
            formControlName="note"
            placeholder="Note"
            class="form-control"
            rows="5"
            [class.is-invalid]="f.note?.touched && f.note?.errors"
          ></textarea>
          <fa-icon
            *ngIf="f.note?.touched && !f.note?.errors"
            [icon]="['far', 'check']"
            class="text-primary"
          ></fa-icon>
        </div>
      </div>

      <label for="title" class="form-label w-50">
        Note <span class="text-primary">*</span>
        <span
          class="float-end text-danger"
          *ngIf="!f.date?.valid && (f.date?.dirty || f.date?.touched)"
        >
          <span [hidden]="!f.note.errors?.required">
            Referral date is required
          </span>
        </span>
      </label>

      <div class="position-relative w-50">
        <div class="input-group mb-3">
          <input
            #date="bsDatepicker"
            type="text"
            formControlName="date"
            placeholder="Please select"
            class="form-control"
            [bsConfig]="{
              containerClass: 'theme-default',
              dateInputFormat: 'DD/MM/YYYY'
            }"
            bsDatepicker
          />
          <button class="btn btn-sm btn-secondary" (click)="date.toggle()">
            <fa-icon
              [icon]="['fal', 'calendar-alt']"
              class="text-white mx-1"
            ></fa-icon>
          </button>
        </div>
        <fa-icon
          *ngIf="f.date?.touched && !f.date?.errors"
          [icon]="['far', 'check']"
          class="text-primary"
        ></fa-icon>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer">
  <div *ngIf="loading" class="spinner-border text-primary me-2" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <button class="btn btn-primary" (click)="submit()" [disabled]="!form.valid">
    Add to user timeline
  </button>
</div>
