<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{ title }}</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    (click)="bsModalRef.hide()"
    aria-label="Close"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="mb-3">
    <app-form
      [questions]="formData"
      (submit)="handleSubmit($event)"
      [loading]="loading"
      [error]="error"
    ></app-form>
  </div>
</div>
